import mixpanel from "mixpanel-browser";
import {useSelector, useStore} from "react-redux";
import {MIXPANEL_TOKEN} from "./app/common/constants/baseConstants";

export const UseMixPanel = () => {
    const store = useSelector((state) => state)
    const userStore = store.user
    mixpanel.init(MIXPANEL_TOKEN, {debug: false, ignore_dnt: true});
    const userId = getCookie('user_id')
    // console.log(MIXPANEL_TOKEN, "MIXPANEL_TOKEN")

    if (userId) {
        mixpanel.identify(userId)
    }
    const track = (event, options = null) => {
        return mixpanel.track(event, options)
    }

    const identify = (id) => {
        return mixpanel.identify(id);
    }

    const setPeople = (option) => {
        if (option) {
            return mixpanel.people.set(option);
        }
        return;
    }

    if (userStore && userStore.name) {
        setPeople({name: userStore.name})
    }

    return {track, identify, setPeople}
}