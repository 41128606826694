import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import Chart from "react-apexcharts";

const MultiBarChart = ({title, chartData, height}) => {
    let series = [];
    for(let i = 0; i < Object.keys(chartData.data).length; i++) {
        const key = Object.keys(chartData.data)[i]
        const values = Object.values(chartData.data)[i]
        series.push({
            name: key,
            type: 'bar',
            data: values
        })
    }

    const stats = {
        series: series,
        options: {
            chart: {
                toolbar: {
                    show: true,
                },
                zoom: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 900,
                },
                type: 'bar',
                height: 350,

            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '80%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: chartData.categories,
                tickPlacement: 'on'
            },
            yaxis: {
                show: true,
                showAlways: true,
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " questions"
                    }
                }
            },
            colors: ['#45A664', '#EB624B', '#767779'],
        },
    };
    
    return (
        <Card>
            {title && <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>}
            <CardBody style={{padding: '0.5rem'}}>
                <Chart
                    options={stats.options}
                    series={stats.series}
                    type="bar"
                    height={height ?? "350"}
                />
            </CardBody>
        </Card>
    )
}

export default  MultiBarChart