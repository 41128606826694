import {DefaultRoute} from "../router/routes"
import {useDispatch} from "react-redux";
import {setShowApplyBatchCodeModal} from "../app/common/reducers/state/ApplyBatchCodeSlice";
import moment from "moment";
import {
    ONGOING_TEST_QUESTION_ANSWERED, ONGOING_TEST_QUESTION_ANSWERED_IN_REVIEW,
    ONGOING_TEST_QUESTION_NOT_ANSWERED, ONGOING_TEST_QUESTION_NOT_ATTEMPTED, ONGOING_TEST_QUESTION_REVIEW,
    TEST_QUESTION_STATE_CORRECT,
    TEST_QUESTION_STATE_INCORRECT
} from "../app/customized-test/constants/CustomizedTestConstants";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = {month: "short", day: "numeric", year: "numeric"}
) => {
    if (!value) return value
    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: "short", day: "numeric"}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: "numeric", minute: "numeric"}
    }

    return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === "admin") return DefaultRoute
    if (userRole === "client") return "/access-control"
    return "/login"
}

export const isMobileDevice = () => {
    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
    ) {
        return true
    }
    return false
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: "#7367f01a", // for option hover bg-color
        primary: "#7367f0", // for selected option bg-color
        neutral10: "#7367f0", // for tags bg-color
        neutral20: "#ededed", // for input border-color
        neutral30: "#ededed" // for input hover border-color
    }
})

export const toQueryString = obj => "?".concat(Object.keys(obj).map(e => `${encodeURIComponent(e)}=${encodeURIComponent(obj[e])}`).join("&"));

export const secondsToHHMMSS = (second) => {
    second = isNaN(second) ? 0 : second
    return new Date(second * 1000).toISOString().substring(11, 19)
}

export const dateDiffInSeconds = (startDate, endDate) => {
    return (endDate.getTime() - startDate.getTime()) / 1000;
}

export const hmsToSeconds = (hms) => {
    if (hms) {
        var a = hms.split(':')
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
    }
    return 0
}

export const dhm = (ms) => {
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms = ms % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    return {
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': sec
    }
    return days + ":" + hours + ":" + minutes + ":" + sec;
}

export const secondsToDhm = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const daysms = seconds % (24 * 60 * 60);
    const hours = Math.floor(daysms / (60 * 60));
    const hoursms = seconds % (60 * 60);
    const minutes = Math.floor(hoursms / (60));
    const minutesms = seconds % (60);
    const sec = Math.floor(minutesms);
    return {
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': sec
    }
}

export const secondsToDhms = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const daysms = seconds % (24 * 60 * 60);
    const hours = Math.floor(daysms / (60 * 60));
    const hoursms = seconds % (60 * 60);
    const minutes = Math.floor(hoursms / (60));
    const minutesms = seconds % (60);
    const sec = Math.floor(minutesms);

    let str = "";
    if (days > 0) {
        str += days + "d : ";
    }
    if (hours > 0) {
        str += hours + "h : ";
    }
    if (minutes > 0) {
        str += minutes + "m : ";
    }

    return str + sec + "s";
}

export const hms = (seconds) => {
    const hours = Math.floor(seconds / (60 * 60));
    const hoursms = seconds % (60 * 60);
    const minutes = Math.floor(hoursms / (60));
    const minutesms = seconds % (60);
    const sec = Math.floor(minutesms);
    let str = "";
    if (hours > 0) {
        str += hours + "h : ";
    }
    if (minutes > 0) {
        str += minutes + "m : ";
    }
    return str + sec + "s";
}

export const randomIntFromInterval = (min, max) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const delay = (timeInMs) => {
    return new Promise(function (resolve) {
        setTimeout(resolve, timeInMs);
    });
}

export const getTwoCharTitle = (title) => {
    let newTitle = title.split(" ");
    if (newTitle[1]) {
        return (newTitle[0][0] ?? '') + (newTitle[1][0] ?? '')
    } else {
        return (newTitle[0][0] ?? '') + (newTitle[0][1] ?? '')
    }
}

export const setTitle = (title) => {
    document.title = title + ' | Quill Plus '
}

export const AddMinutesToDate = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
}

export const getReadableMinutesFromSeconds = (seconds) => {
    return (seconds / 60).toFixed(0) + " Min";
}

export const getLastDayOfWeek = () => {
    const today = new Date();
    return (new Date(today.setDate(today.getDate() - today.getDay() + 6)));
}

export const getLastDayOfMonth = () => {
    const today = new Date();
    return (new Date(today.getFullYear(), today.getMonth() + 1, 0));
}

export const getLastDayOfYear = () => {
    const today = new Date();
    return  (new Date(today.getFullYear(), 11, 31));
}

export const DateToyyyymmdd = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const d = date.getDate();
    return year + "-" + month + "-" + d;
}

export const getReadableDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const newDate = new Date(date);
    const dateNum = newDate.getDate();
    const month = monthNames[newDate.getMonth()];
    const year = newDate.getFullYear()
    let h = newDate.getHours();
    let m = newDate.getMinutes();
    let s = newDate.getSeconds();
    let meridian = h >= 12 ? "PM" : "AM";
    h = h % 12;
    h = h ? h : 12;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    return `${dateNum} ${month}, ${year} ${h}:${m}:${s} ${meridian} `;
}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
    return buildDate;
};

export const getQueryParams = (query = null) => {
    return (
        (query || window.location.search.replace("?", ""))
            .split("&")
            .map(pair => {
                let [key, val] = pair.split("=");
                return [key, decodeURIComponent(val || "")];
            })
            .reduce((result, [key, val]) => {
                result[key] = val;
                return result;
            }, {})
    );
};

export const getCustomizedQuestionClass = (question, time_taken = 0) => {
    if((question.option_id || question.nvt_answer) && question.was_reviewed) {
        return "switcher-review-and-answered"
    } else if(!question.option_id && !question.nvt_answer && question.was_reviewed) {
        return "switcher-review"
    } else if(question.option_id || question.nvt_answer || question.is_correct) {
        return "switcher-answered"
    } else if(question.is_correct === 0) {
        return "switcher-incorrect";
    } else if(!question.option_id && !question.nvt_answer && time_taken) {
        return "switcher-not-answered"
    } else if(!question.option_id && !question.nvt_answer && !time_taken) {
        return "switcher-not-visited"
    }
    return "switcher-not-visited"
}

export const getCustomizedPastTestQuestionClass = (state) => {
    if(state === TEST_QUESTION_STATE_CORRECT) {
        return "switcher-answered"
    } else if(state === TEST_QUESTION_STATE_INCORRECT) {
        return "switcher-incorrect";
    }
    // for TEST_QUESTION_STATE_SKIPPED
    return "switcher-not-answered"
}

export const processCustomizedQuestionState = (question, time_taken) => {
    if((question.option_id || question.nvt_answer) && question.was_reviewed) {
        return ONGOING_TEST_QUESTION_ANSWERED_IN_REVIEW
    } else if(!question.option_id && !question.nvt_answer && question.was_reviewed) {
        return ONGOING_TEST_QUESTION_REVIEW
    } else if(question.option_id || question.nvt_answer) {
        return ONGOING_TEST_QUESTION_ANSWERED
    } else if(!question.option_id && !question.nvt_answer && time_taken) {
        return ONGOING_TEST_QUESTION_NOT_ANSWERED
    } else if(!question.option_id && !question.nvt_answer && !time_taken) {
        return ONGOING_TEST_QUESTION_NOT_ATTEMPTED
    }
    return ONGOING_TEST_QUESTION_NOT_ATTEMPTED
}