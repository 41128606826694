// ** React Imports
import { Navigate } from "react-router-dom"
import { useContext, Suspense } from "react"

// ** Context Imports
import { getCookie } from "@src/app/common/helpers/CookieHelper"

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  const userToken = getCookie("token")
  if (route) {
    // let action = null
    // let resource = null
    let restrictedRoute = false

    if (route.meta) {
      // action = route.meta.action
      // resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    if (!userToken) {
      return <Navigate to="/login" />
    }
    if (userToken && restrictedRoute) {
      return <Navigate to="/" />
    }
    if (userToken && restrictedRoute && userToken.role === "client") {
      return <Navigate to="/access-control" />
    }
  }
  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
