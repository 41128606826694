import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {BarChart, BarChart2, Grid, Layers, PieChart, Sliders, Target} from "react-feather";
import {randomIntFromInterval} from "../../../utility/Utils";

const NoDataCard = ({title, message = "No data to show"}) => {
    const icons = [
        <BarChart></BarChart>,
        <PieChart></PieChart>,
        <BarChart2></BarChart2>,
        <Grid></Grid>,
        <Layers></Layers>,
        <Sliders></Sliders>,
        <Target></Target>
    ]

    const randomNumber = randomIntFromInterval(0, (icons.length - 1))
    return (
        <Card>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>
            <CardBody>{icons[randomNumber]} {message}</CardBody>
        </Card>
    )
}

export default NoDataCard