import {createSlice} from '@reduxjs/toolkit'
import {reactLocalStorage} from 'reactjs-localstorage';
import {setCookie, getCookie} from 'src/app/common/helpers/CookieHelper';

const initialState = {
    id: null,
    email: "",
    name: "",
    phone: "",
    is_registered: null,
    role: "",
    token: "",
    fcmToken: getCookie("fcm_token") ?? "",
    image: null,
    academicInfo: {
        data: []
    },
    currentStandardMetaDataId: null,
    currentWhiteLabel: {
        data: {}
    },
    isPracticeAllowed: reactLocalStorage.get('isPracticeAllowed') || 0,
    isScheduledAllowed: reactLocalStorage.get('isScheduledAllowed') || 0,
    isCustomizedAllowed: reactLocalStorage.get('isCustomizedAllowed') || 0
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setId: (state, action) => {
            setCookie('user_id', action.payload)
            state.id = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setPhone: (state, action) => {
            state.phone = action.payload
        },
        setIsRegistered: (state, action) => {
            state.is_registered = action.payload
        },
        setRole: (state, action) => {
            state.role = action.payload
        },
        setAcademicInfo: (state, action) => {
            state.academicInfo = action.payload
        },
        setCurrentWhiteLabel: (state, action) => {
            if (action.payload?.data?.logo) {
                setCookie('whitelabel_logo', action.payload?.data?.logo)
            }
            state.currentWhiteLabel = action.payload
        },
        setImage: (state, action) => {
            state.image = action.payload
        },
        setCurrentStandardMetaDataId: (state, action) => {
            setCookie('currentStandardMetaDataId', action.payload)
            state.currentStandardMetaDataId = action.payload
        },
        setIsPracticeAllowed: (state, action) => {
            reactLocalStorage.set('isPracticeAllowed', action.payload)
            state.isPracticeAllowed = action.payload
        },
        setIsScheduledAllowed: (state, action) => {
            reactLocalStorage.set('isScheduledAllowed', action.payload)
            state.isScheduledAllowed = action.payload
        },
        setIsCustomizedAllowed: (state, action) => {
            reactLocalStorage.set('isCustomizedAllowed', action.payload)
            state.isCustomizedAllowed = action.payload
        },
        setFcmToken: (state, action) => {
            setCookie('fcm_token', action.payload)
            state.fcmToken = action.payload
        },
    }
});

export const {
    setId,
    setEmail,
    setName,
    setPhone,
    setIsRegistered,
    setRole,
    setImage,
    setAcademicInfo,
    setCurrentWhiteLabel,
    setCurrentStandardMetaDataId,
    setIsPracticeAllowed,
    setIsScheduledAllowed,
    setIsCustomizedAllowed,
    setFcmToken,
} = userSlice.actions

export default userSlice.reducer;