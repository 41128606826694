// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import login from "@src/app/auth/reducers/states/loginSlice"
import user from "@src/app/auth/reducers/states/userSlice"
import practiceTest from "@src/app/practice-test/reducers/states/practiceTestSlice"
import practiceTestAnalytics from "@src/app/practice-test/reducers/states/practiceTestAnalyticsSlice"
import scheduleTest from "src/app/schedule-test/reducers/state/scheduleTestSlice"
import scheduleTestAnalytic from "src/app/schedule-test/reducers/state/scheduleTestAnalyticsSlice"
import applyBatchCode from "@src/app/common/reducers/state/ApplyBatchCodeSlice"

const rootReducer = { navbar, layout, login, user, practiceTest, practiceTestAnalytics, scheduleTest, scheduleTestAnalytic, applyBatchCode }

export default rootReducer
