import {Fragment, useEffect, useState} from "react";
import {Button, Col, Nav, NavItem, NavLink, Row} from "reactstrap";
import {
    GET_UPCOMING_TESTS,
    TEST_TABS,
    V1_CUSTOMIZED_TEST_ENDPOINT
} from "../constants/CustomizedTestConstants";
import authAxios from "../../../configs/authAxios";
import {API_URL} from "../../common/constants/baseConstants";
import {StatusCodes} from "http-status-codes";
import CustomizedUpcomingTestCard from "./CustomizedUpcomingTestCard";
import {DateToyyyymmdd, getLastDayOfMonth, getLastDayOfWeek, getLastDayOfYear} from "../../../utility/Utils";

const CustomizedUpcomingTest = (props) => {
    const [active, setActive] = useState('today')
    const [currentStandardMetadataId, setCurrentStandardMetadataId] = useState(props.currentStandardMetaDataId)
    const tabs = TEST_TABS
    const [tabList, setTabList] = useState([]);
    const [fetchingUpcomingTests, setFetchingUpcomingTests] = useState(false);
    const [upcomingTests, setUpcomingTests] = useState([]);
    const [upcomingTestPageNumber, setUpcomingTestPageNumber] = useState(1);
    const [upcomingTestList, setUpcomingTestList] = useState([]);

    const updateUpcomingTest = (tests) => {
        setUpcomingTests(upcomingTests.concat(tests));
    }

    const toggle = evt => {
        const tab = (evt.target.getAttribute('data-name'))
        if (active !== tab) {
            setUpcomingTestPageNumber(1);
            setUpcomingTests([]);
            setActive(tab);
        }
    }

    useEffect(() => {

    }, [currentStandardMetadataId])

    const handleOnClickLoadMore = async () => {
        if(upcomingTestPageNumber) {
            const dates = getFromToDate(active)
            const url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_UPCOMING_TESTS + "?page="+upcomingTestPageNumber+"&start_date="+dates[0]+"&end_date="+dates[1]+"&standard_metadata_id="+currentStandardMetadataId
            await fetchUpcomingTests(url);
        }
    }

    const getFromToDate = (active) => {
        let today = new Date();
        switch (active) {
            case "today":
                return [ DateToyyyymmdd(today), DateToyyyymmdd(today)]
            case "week":
                const weekEndDate = getLastDayOfWeek()
                return [ DateToyyyymmdd(today), DateToyyyymmdd(weekEndDate)]
            case "month":
                const monthEndDate = getLastDayOfMonth()
                return [ DateToyyyymmdd(today), DateToyyyymmdd(monthEndDate)]
            case "year":
                const yearEndDate = getLastDayOfYear()
                return [ DateToyyyymmdd(today), DateToyyyymmdd(yearEndDate)]
            default:
                return [ DateToyyyymmdd(today), DateToyyyymmdd(today)]
        }
    }

    const fetchUpcomingTests = async (url) => {
        if(!fetchingUpcomingTests && currentStandardMetadataId) {
            setFetchingUpcomingTests(true);
            const upcomingTestResponse = await authAxios.get(url)

            if(upcomingTestResponse.status === StatusCodes.OK) {
                updateUpcomingTest(upcomingTestResponse?.data?.data?.tests?.data)
                setUpcomingTestPageNumber(upcomingTestResponse?.data?.data?.next_page !== null ? (upcomingTestResponse?.data?.data?.current_page + 1) : null)
            }
            setFetchingUpcomingTests(false);
        }
    }

    const onClickCustomizedTestCardHandler = async (testId) => {
        const isTestAllowed = await getIfTestAllowedAtThisTime(testId)
        props.handleCustomizedTestDetails(testId, isTestAllowed)
    }

    const getIfTestAllowedAtThisTime = async (testId) => {
        let isTestAllowed = false;
        for(let i = 0; i < upcomingTests.length; i++) {
            const test = upcomingTests[i];
            if(test.id === testId) {
                if(new Date(test.current_time) >= new Date(test.starts_at)) {
                    isTestAllowed = true;
                }
                break;
            }
        }
        return isTestAllowed
    }

    // Use effect Hooks
    useEffect(() => {
        let newTabList = [];
        tabs.forEach(function(tab, index)  {
            newTabList.push(
                <NavItem key={index}>
                    <NavLink
                        active={active === tab.value}
                        onClick={toggle}
                        data-name={tab.value}
                    >
                        {tab.display_name}
                    </NavLink>
                </NavItem>
            )
        });

        (async () => {
            props.handleShowLoader(true, "Please wait while we fetch your tests.");
            const dates = getFromToDate(active)
            const url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_UPCOMING_TESTS + "?page="+upcomingTestPageNumber+"&start_date="+dates[0]+"&end_date="+dates[1]+"&standard_metadata_id="+currentStandardMetadataId
            await fetchUpcomingTests(url);
            props.handleShowLoader(false, "Please wait while we fetch your tests.");
        })();

        setTabList(newTabList)
    }, [active])

    useEffect(() => {
        let newUpcomingTestList = [];
        if(upcomingTests !== null && upcomingTests !== undefined && Object.keys(upcomingTests).length > 0) {
            for(let i = 0; i < upcomingTests.length; i++) {
                const test = upcomingTests[i];
                newUpcomingTestList.push(
                    <Col md={12} key={test.id}>
                        <CustomizedUpcomingTestCard
                            id={test.id}
                            name={test.name}
                            subjects={test.subjects}
                            chapters={test.chapters}
                            scheduled_at={test.starts_at}
                            allowed_till={test.allowed_till}
                            duration_in_seconds={test.duration}
                            onClickFunctionHandler={onClickCustomizedTestCardHandler}
                        ></CustomizedUpcomingTestCard>
                    </Col>
                )
            }
        }
        setUpcomingTestList(newUpcomingTestList)
    }, [upcomingTests])

    return (
        <Row className="mt-1">
            <Col md={12}>
                <Fragment>
                    <Nav tabs justified className="mb-0">
                        {tabList}
                    </Nav>
                </Fragment>
                <Row className={"mt-1 page_height_list mini_scroll_bar"}>
                    {upcomingTestList}
                    {upcomingTestList.length === 0 ?
                        <Col md={12} className={"text-center mt-1"}>
                            No Test scheduled
                        </Col>
                            :
                        ""}
                    {upcomingTestPageNumber && upcomingTests.length > 0 &&
                        <Col md={12} className={"text-center"}>
                            <Button onClick={handleOnClickLoadMore} disabled={fetchingUpcomingTests} color={"primary"}>
                                { fetchingUpcomingTests ?
                                    "Loading..."
                                    :
                                    "Load More"
                                }
                            </Button>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}   

export default CustomizedUpcomingTest