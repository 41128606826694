import {Navbar, NavbarBrand} from "reactstrap";
import {ArrowLeft} from "react-feather";
import {useNavigate} from "react-router-dom";

export const CustomizedPastTestHeader = ({testName}) => {
    const navigate = useNavigate()
    const navigateToCustomizedHome = () => {
        navigate('/customized-tests')
    }

    return (
        <div className="bg-dark">
            <Navbar>
                <NavbarBrand className="" tag="span">
                    <ArrowLeft onClick={navigateToCustomizedHome} className="mx-2 cursor-pointer"></ArrowLeft>
                    <span>{testName}</span>
                </NavbarBrand>
            </Navbar>
        </div>
    )
}