import axios from "axios";
import {API_URL} from "@src/app/common/constants/baseConstants";
import {Navigate} from "react-router-dom"
import {StatusCodes} from "http-status-codes";
import toast from 'react-hot-toast';

const UNKNOWN_STATUS_CODE = 0;

const baseAxios = axios.create({
    baseURL: API_URL,
    headers: {
        "device": "web",
        "Access-Control-Allow-Origin": "https://quillplus.in"
    },
});

baseAxios.interceptors.request.use((config) => {
    config.data.device = 'web';
    return config
})

baseAxios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    /** To handle rest of the common server errors */
    switch (error?.response?.status) {
        case StatusCodes.INTERNAL_SERVER_ERROR:
            toast.error('Oops, something went wrong. Please try again later.')
            break;

        case StatusCodes.GATEWAY_TIMEOUT:
            toast.error('Oops, Looks like you will have to try again.')
            break;

        case StatusCodes.SERVICE_UNAVAILABLE:
            return <Navigate to="/under-maintenance"/>
            break;
        case UNKNOWN_STATUS_CODE:
            toast.error('Oops, Looks like Something went wrong.')
            break;
        default:
            return error.response;
    }
});

export default baseAxios;
