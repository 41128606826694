import {useSkin} from "@hooks/useSkin"
import {
    Row,
    Col
} from "reactstrap"
import "@styles/react/pages/page-authentication.scss"
import BrandLogo from "src/app/common/components/theme/BrandLogo"
import {DEFAULT_DARK_LOGO} from "src/app/common/constants/baseConstants"

const UnderMaintenance = () => {
    const {skin} = useSkin()
    const illustration = skin === "dark" ? "under-maintenance.svg" : "under-maintenance.svg",
        source = require(`@src/assets/images/pages/${illustration}`).default
    return (
        <div className="auth-wrapper auth-cover">
            <Row className="auth-inner m-0">
                <BrandLogo
                ></BrandLogo>
                <div className="misc-inner p-2 p-sm-3">
                    <div className="w-100 text-center">
                        <h2 className="mb-1">Under Maintenance 🛠</h2>
                        <p className="mb-3">Sorry for the inconvenience but we're performing some maintenance at the
                            moment</p>
                        <img className="img-fluid" src={source} alt="Login Cover"/>
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default UnderMaintenance
