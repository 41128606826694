import {Col, Row} from "reactstrap";
import DonutChart from "../../../common/components/chart/DonutChart";
import NoDataCard from "../../../practice-test/components/NoDataCard";
import MultiBarChart from "../../../common/components/chart/MultiBarChart";

const CustomizedPastTestAnalytics = ({
    testDetails,
    questionWiseAnalytics,
    timeWiseAnalytics,
    averageTimePerQuestionAnalytics,
    subjectWiseAnalytics,
    chapterWiseAnalytics
}) => {
    return (
        <>
            <Row className={"p-1"}>
                <Col md={4}>
                    {questionWiseAnalytics !== null && testDetails.num_attempted_questions > 0 ?
                        <DonutChart
                            title="Question Wise Breakdown"
                            data={questionWiseAnalytics}
                            height="500"
                            showTotal={true}
                        />
                        :
                        <NoDataCard
                            title="Question Wise Breakdown"
                        />
                    }
                </Col>
                <Col md={4}>
                    {timeWiseAnalytics !== null && testDetails.num_attempted_questions > 0 ?
                        <DonutChart
                            title="Time"
                            data={timeWiseAnalytics}
                            height="500"
                            isDataInSeconds={true}
                            showTotal={true}
                        />
                        :
                        <NoDataCard
                            title="Question Wise Breakdown"
                        />
                    }
                </Col>
                <Col md={4}>
                    {averageTimePerQuestionAnalytics !== null && testDetails.num_attempted_questions > 0 ?
                        <DonutChart
                            title="Average Time Per Question Type"
                            data={averageTimePerQuestionAnalytics}
                            height="500"
                            isDataInSeconds={true}
                        />
                        :
                        <NoDataCard
                            title="Question Wise Breakdown"
                        />
                    }
                </Col>
            </Row>
            <Row className={"p-1"}>
                <Col md={12}>
                    {subjectWiseAnalytics !== null && testDetails.num_attempted_questions > 0 ?
                        <MultiBarChart
                            title={"Subject Wise Analysis"}
                            chartData={subjectWiseAnalytics}
                        />
                        :
                        <NoDataCard
                            title="Question Wise Breakdown"
                        />
                    }
                </Col>
            </Row>
            <Row className={"p-1"}>
                <Col md={12}>
                    {chapterWiseAnalytics !== null && testDetails.num_attempted_questions > 0 ?
                        <MultiBarChart
                            title={"Chapter Wise Analysis"}
                            chartData={chapterWiseAnalytics}
                        />
                        :
                        <NoDataCard
                            title="Question Wise Breakdown"
                        />
                    }
                </Col>
            </Row>
        </>
    )
}

export default CustomizedPastTestAnalytics;