import {useEffect, useState} from "react";
import {
    DEFAULT_LOADER_MESSAGE,
    END_TEST, ENDED_TEST_INFO, FORCE_END,
    GET_TEST_QUESTION,
    NORMAL_END,
    ONGOING_TEST_FILTER_STATES,
    ONGOING_TEST_QUESTION_NOT_ATTEMPTED,
    UPDATE_TEST_QUESTION,
    V1_CUSTOMIZED_TEST_ENDPOINT
} from "../../constants/CustomizedTestConstants";
import LoadingOverlay from "react-loading-overlay-ts";
import CustomizedTestNav from "./CustomizedTestNav";
import CustomizedTestBody from "./CustomizedTestBody";
import authAxios from "../../../../configs/authAxios";
import {API_URL} from "../../../common/constants/baseConstants";
import {reactLocalStorage} from "reactjs-localstorage";
import {StatusCodes} from "http-status-codes";
import {useNavigate, useParams} from "react-router-dom";
import ConfirmTestSubmissionModal from "../../../common/components/ConfirmTestSubmissionModal";
import toast from "react-hot-toast";
import ConfirmModal from "../../../common/components/ConfirmModal";
import {isMobileDevice, processCustomizedQuestionState} from "../../../../utility/Utils";
import {UseStartCustomizedTest} from "../../hooks/UseStartCustomizedTest";
import {
    EXCEPTION_TEST_ENDED_AT_SERVER,
    STARTED_CUSTOMIZED_TEST,
    SUBMITTED_CUSTOMIZED_TEST
} from "../../../common/constants/mixPanelEvents";
import {UseMixPanel} from "../../../../UseMixPanel";

const CustomizedTestPage = () => {
    const [isTestLoaderActive, setIsTestLoaderActive] = useState(false)
    const [testLoaderMessage, setTestLoaderMessage] = useState(DEFAULT_LOADER_MESSAGE);
    const navigate = useNavigate();
    const useMixPanel = UseMixPanel();
    const useStartCustomizedTest = UseStartCustomizedTest();
    const [showSubmitTestModal, setShowSubmitTestModal] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("")
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [isTestBeingSubmitted, setIsTestBeingSubmitted] = useState(false);
    const [isFetchingQuestions, setIsFetchingQuestions] = useState(false);

    // testData State
    const {testId} = useParams();
    const isReadOnlyMode = false;

    const [isTestOnMobileDevice, setIsTestOnMobileDevice] = useState(isMobileDevice());
    const [tabSwitchedTime, setTabSwitchedTime] = useState(0)
    const tabSwitchModalMessage = `You have left the test window for ${tabSwitchedTime} seconds. If you do the same for more than 30 seconds, the test will be auto submitted.`;
    const [isTabSwitchModalOpen, setIsTabSwitchModalOpen] = useState(false)
    const isVisible = () => document.visibilityState === 'visible';
    const [hasFocus, setHasFocus] = useState(isVisible());

    const testDetails = reactLocalStorage.getObject('CUSTOMIZED_TEST_'+testId+'_DETAILS')
    const numQuestions = testDetails.num_questions
    let testStartTime = reactLocalStorage.get('CUSTOMIZED_TEST_'+testId+'_START_TIME');
    if (!testStartTime) {
        testStartTime = new Date()
        reactLocalStorage.set('CUSTOMIZED_TEST_'+testId+'_START_TIME', testStartTime);
    }
    const testDuration = testDetails.duration
    const scheduleTestQuestionWiseStates = ONGOING_TEST_FILTER_STATES;
    const [questionFilteredStates, setQuestionFilteredStates] = useState([]);
    const [testQuestions, setTestQuestions] = useState(reactLocalStorage.getObject('CUSTOMIZED_TEST_'+testId+'_QUESTIONS'));
    const [activeQuestionSequence, setActiveQuestionSequence] = useState(0)
    const [activeQuestion, setActiveQuestion] = useState(testQuestions[activeQuestionSequence])
    const [perPageQuestion, setPerPageQuestion] = useState(reactLocalStorage.get('CUSTOMIZED_TEST_'+testId+'_PER_PAGE_QUESTION') ?? 5);
    const [testQuestionsTime, setTestQuestionsTime] = useState(reactLocalStorage.getObject('CUSTOMIZED_TEST_'+testId+'_QUESTIONS_TIME'));
    const [time, setTime] = useState(0)
    const TIME_SPENT_INTERVAL = 1000

    useEffect(() => {
        if(Object.keys(testDetails).length === 0) {
            redirectToCustomizedHomePage()
        }
        const interval = setInterval(() => {
            setTime((time) => time + TIME_SPENT_INTERVAL)
        }, TIME_SPENT_INTERVAL)
        return () => {
            clearInterval(interval)
        }
    }, [])

    // functions

    const trackMixPanelEventWhenUnprocessableEntity = () => {
        const mixpanelObj = {
            'id' : testDetails.id,
            'testName' : testDetails?.name
        }
        useMixPanel.track(EXCEPTION_TEST_ENDED_AT_SERVER, mixpanelObj)
    }

    const redirectToCustomizedHomePage = () => {
        navigate('/customized-tests')
    }
    const handleOpenSubmitTestModal = () => {
        setShowSubmitTestModal(true)
    }

    const handleCloseSubmitTestModal = () => {
        setShowSubmitTestModal(false)
    }

    // Tab Switch Functions
    const hideTabSwitchModal = () => {
        setIsTabSwitchModalOpen(false)
        setTabSwitchedTime(0)
    }

    const startTabSwitchTimer = () => {
        setHasFocus(false)
    }

    const stopTabSwitchTimer = () => {
        setHasFocus(true)
    }

    useEffect(() => {
        if(!isTestOnMobileDevice) {
            const onVisibilityChange = () => {
                setHasFocus(isVisible());
            };
            document.addEventListener('visibilitychange', onVisibilityChange);
            window.addEventListener('blur', startTabSwitchTimer);
            window.addEventListener('focus', stopTabSwitchTimer);
            return () => {
                window.removeEventListener('blur', startTabSwitchTimer);
                window.removeEventListener('focus', stopTabSwitchTimer);
                document.removeEventListener('visibilitychange', onVisibilityChange);
            };
        }
        setIsTestOnMobileDevice(isMobileDevice())
    }, []);

    const [intervalTime, setIntervalTime] = useState(null);

    useEffect(() => {
        if(!isTestOnMobileDevice) {
            if (!hasFocus) {
                setIsTabSwitchModalOpen(true)
                const interval = setInterval(() => {
                    setTabSwitchedTime((tabSwitchedTime) => tabSwitchedTime + 1);
                }, 1000);
                setIntervalTime(interval)
            } else {
                clearInterval(intervalTime);
            }
        }
    }, [hasFocus])

    useEffect(() => {
        if (tabSwitchedTime > 30) {
            submitTest(FORCE_END)
        }
    }, [tabSwitchedTime])

    const handleOnTestEndResponse = (data) => {
        if (data.allowed_till) {
            setConfirmModalMessage("Test Ended. Result will be out at " + data.allowed_till)
        } else {
            setConfirmModalMessage("Test Ended.")
        }
        setIsConfirmModalOpen(true);
    }

    const handleUnprocessableEntityResponse = async () => {
        trackMixPanelEventWhenUnprocessableEntity()
        let endTestInfoUrl = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + ENDED_TEST_INFO;
        endTestInfoUrl = endTestInfoUrl.replace('#testId', testId);
        const testInfo = await authAxios.get(endTestInfoUrl)
        handleOnTestEndResponse(testInfo?.data?.data)
        clearTestData();
    }

    const clearTestData = () => {
        reactLocalStorage.remove('CUSTOMIZED_TEST_'+testDetails.id+'_DETAILS')
        reactLocalStorage.remove('CUSTOMIZED_TEST_'+testDetails.id+'_QUESTIONS')
        reactLocalStorage.remove('CUSTOMIZED_TEST_'+testDetails.id+'_START_TIME')
        reactLocalStorage.remove('CUSTOMIZED_TEST_'+testDetails.id+'_QUESTIONS_TIME')
    }

    // Tab Switch Functions Ends
    const submitTest = async (endTestType = NORMAL_END) => {
        if(!isTestBeingSubmitted) {
            setIsTestBeingSubmitted(true);
            setIsTestLoaderActive(true);
            setTestLoaderMessage("Please wait while we submit your test.")
            await updatePrevQuestionTime(activeQuestionSequence)
            setShowSubmitTestModal(false)
            await updateQuestionAnswer(testQuestions[activeQuestionSequence])
            let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + END_TEST;
            url = url.replace('#testId', testId);
            const postObj = {
                end_test_type : endTestType
            }
            const response = await authAxios.post(url, postObj)
            if(response.status === StatusCodes.OK) {
                toast.success("Test Ended successfully.");
                clearTestData();
                const mixpanelObj = {
                    'id' : testDetails.id,
                    'testName' : testDetails?.name
                }
                useMixPanel.track(SUBMITTED_CUSTOMIZED_TEST, mixpanelObj)
                if(response?.data?.data?.should_show_results) {
                    navigate('/customized-tests/'+testId+'/result')
                } else {
                    setIsTestLoaderActive(false);
                    handleOnTestEndResponse(response?.data?.data)
                }
            } else if(response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
                await handleUnprocessableEntityResponse()
            } else {
                setIsTestLoaderActive(false);
                setIsTestBeingSubmitted(false);
            }
        }
    }

    const manageQuestionStateWiseFilter = (state, operation) => {
        let newStateWiseFilter = questionFilteredStates.slice();
        if (operation > 0) {
            newStateWiseFilter.push(state);
        } else {
            const index = newStateWiseFilter.indexOf(state);
            if (index > -1) {
                newStateWiseFilter.splice(index, 1);
            }
        }
        setQuestionFilteredStates(newStateWiseFilter)
    }

    const updatePrevQuestionTime = async(sequence) => {
        const newTestQuestionsTime = testQuestionsTime
        if(newTestQuestionsTime[sequence] !== undefined || newTestQuestionsTime[sequence] !== null) {
            let oldTime = parseInt(newTestQuestionsTime[sequence] ?? 0);
            oldTime = isNaN(oldTime) ? 0 : oldTime
            newTestQuestionsTime[sequence] = oldTime + time
        } else {
            newTestQuestionsTime[sequence] = time;
        }
        let newQuestions = testQuestions
        let currentQuestion = newQuestions[sequence]
        if(currentQuestion !== undefined && currentQuestion !== null) {
            currentQuestion.state = processCustomizedQuestionState(currentQuestion, newTestQuestionsTime[sequence])
            newQuestions[sequence] = currentQuestion
            setTestQuestions(newQuestions)
        }
        setTestQuestionsTime(newTestQuestionsTime)
    }

    const navigateToNextQuestion = async () => {
        if(activeQuestionSequence !== numQuestions) {
            const prevSequence = activeQuestionSequence
            await updatePrevQuestionTime(prevSequence)
            setActiveQuestionSequence(parseInt(activeQuestionSequence) + 1)
            await updateQuestionAnswer(testQuestions[prevSequence])
        }
    }

    const navigateToPreviousQuestion = async () => {
        if(activeQuestionSequence > 1) {
            const prevSequence = activeQuestionSequence
            await updatePrevQuestionTime(prevSequence)
            setActiveQuestionSequence(parseInt(activeQuestionSequence) - 1)
            await updateQuestionAnswer(testQuestions[prevSequence])
        }
    }

    const markQuestionForReview = (questionId, value) => {
        const newTestQuestions = testQuestions.map(question => {
            const questionTime = testQuestionsTime[question?.sequence]
            if(question !== null && question !== undefined && question.id === questionId) {
                question.was_reviewed = value;
                question.state = processCustomizedQuestionState(question, questionTime);
                return question;
            } else {
                return question
            }
        })
        setTestQuestions(newTestQuestions)
    }

    const clearQuestionAnswer = (questionId) => {
        const newTestQuestions = testQuestions.map(question => {
            const questionTime = testQuestionsTime[question?.sequence]
            if(question !== null && question !== undefined && question.id === questionId) {
                question.option_id = null;
                question.nvt_answer = null;
                question.state = processCustomizedQuestionState(question, questionTime);
                return question;
            } else {
                return question
            }
        })
        setTestQuestions(newTestQuestions)
    }

    const updateQuestionAnswer = async(question) => {
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + UPDATE_TEST_QUESTION
        url = url.replace('#questionId', question.id)
        url = url.replace('#testId', testId)
        const questionTime = testQuestionsTime[question.sequence]
        const postObj = {
            "time_taken": parseInt((questionTime ?? 0) / 1000),
            "was_reviewed" : question?.was_reviewed ? 1 : 0,
            "option_id" : question.option_id ?? null
        }
        const resp = await authAxios.put(url, postObj);
        if(resp.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            await handleUnprocessableEntityResponse()
        }
    }

    const updateQuestionReportedState = (questionId, reason) => {
        const newTestQuestions = testQuestions.map(question => {
            const questionTime = testQuestionsTime[question?.sequence]
            if(question !== null && question !== undefined && question.id === questionId) {
                question.was_reported = true;
                question.report_reason = reason
                question.state = processCustomizedQuestionState(question, questionTime);
                return question
            } else {
                return question
            }
        })
        setTestQuestions(newTestQuestions)
    }

    const updateQuestionMCQAnswer = (questionId, optionId) => {
        const newTestQuestions = testQuestions.map(question => {
            const questionTime = testQuestionsTime[question?.sequence]
            if(question !== null && question !== undefined && question.id === questionId) {
                question.option_id = optionId;
                question.state = processCustomizedQuestionState(question, questionTime);
                return question
            } else {
                return question
            }
        })
        setTestQuestions(newTestQuestions)
    }

    const handleQuestionPillClick = async (sequence) => {
        const prevSequence = activeQuestionSequence
        await updatePrevQuestionTime(prevSequence)
        setActiveQuestionSequence(sequence)
        await updateQuestionAnswer(testQuestions[prevSequence])
    }

    const updateQuestions = async (questions) => {
        let indexedNewQuestions = [];
        if(questions !== null && questions !== undefined && Object.keys(questions).length > 0) {
            questions.map(question => {
                indexedNewQuestions[question.sequence] = question;
            })
            let newQuestion = [];
            for(let i = 1; i <= numQuestions; i++) {
                newQuestion[i] = indexedNewQuestions[i] ? {...indexedNewQuestions[i], state: ONGOING_TEST_QUESTION_NOT_ATTEMPTED} : testQuestions[i];
            }
            setTestQuestions(newQuestion)
        }
    }

    const fetchQuestions =  async (pageNumber = 1) => {
        setIsFetchingQuestions(true);
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_TEST_QUESTION;
        url = url.replace('#testId', testId);
        const questionResponse = await authAxios.get(url + '?page='+pageNumber);
        setIsFetchingQuestions(false);
        if(questionResponse.status === StatusCodes.OK) {
            setPerPageQuestion(questionResponse?.data?.data?.per_page)
            reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testId+'_PER_PAGE_QUESTION', questionResponse?.data?.data?.per_page)
            return questionResponse?.data?.data?.paginatedQuestions?.data
        } else if(questionResponse.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            await handleUnprocessableEntityResponse()
        }
    }

    const handleOnSequenceChange = async (sequence) => {
        if (sequence > 0) {
            let pageNum = Math.ceil(sequence / perPageQuestion)
            const newQuestions = await fetchQuestions(pageNum);
            await updateQuestions(newQuestions)
        }
    }

    // useEffect hooks
    useEffect(() => {
        setActiveQuestionSequence(1);
    }, [])

    useEffect(() => {
        setActiveQuestion(testQuestions[activeQuestionSequence])
        setTime(0)
    }, [activeQuestionSequence])

    useEffect(() => {
        (async() => {
            if(activeQuestion === null || (activeQuestion !== undefined && (activeQuestion.id === null || activeQuestion.id === undefined))) {
                await handleOnSequenceChange(activeQuestionSequence);
            }
        })();
    }, [activeQuestion])

    useEffect(() => {
        setActiveQuestion(testQuestions[activeQuestionSequence])
        reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testId+'_QUESTIONS', testQuestions)
    }, [testQuestions]);

    useEffect(() => {
        reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testId+'_QUESTIONS_TIME', testQuestionsTime)
    }, [testQuestionsTime])

    return(
        <LoadingOverlay
            active={isTestLoaderActive}
            spinner
            text={testLoaderMessage}
        >
            <ConfirmModal
                message = {confirmModalMessage}
                onConfirm = {redirectToCustomizedHomePage}
                isOpen = {isConfirmModalOpen}
            />
            <ConfirmModal
                message = {tabSwitchModalMessage}
                onConfirm = {hideTabSwitchModal}
                isOpen = {isTabSwitchModalOpen}
            ></ConfirmModal>
            <ConfirmTestSubmissionModal
                showSubmitTestModal = {showSubmitTestModal}
                handleCloseSubmitTestModal ={handleCloseSubmitTestModal}
                handleOnSubmitTest = {submitTest}
            />
            <CustomizedTestNav
                testName = {testDetails.name}
                handleOnSubmitTest = {handleOpenSubmitTestModal}
                testStartTime={testStartTime}
                testDuration={testDuration}
                submitTest={submitTest}
            />
            <CustomizedTestBody
                questionStates = {scheduleTestQuestionWiseStates}
                handleQuestionPillClick = {handleQuestionPillClick}
                activeQuestion = {activeQuestion}
                testQuestions = {testQuestions}
                testQuestionsTime = {testQuestionsTime}
                fetchQuestions = {fetchQuestions}
                handleOnUpdateQuestionMCQAnswer = {updateQuestionMCQAnswer}
                handleOnSubmitTest = {handleOpenSubmitTestModal}
                totalQuestionsLength = {numQuestions}
                isReadOnlyMode={isReadOnlyMode}
                handleNavigateToNextQuestion = {navigateToNextQuestion}
                handleNavigateToPreviousQuestion = {navigateToPreviousQuestion}
                handleMarkQuestionForReview = {markQuestionForReview}
                handleClearQuestionAnswer = {clearQuestionAnswer}
                questionFilteredStates = {questionFilteredStates}
                manageQuestionStateWiseFilter = {manageQuestionStateWiseFilter}
                updateQuestionReportedState = {updateQuestionReportedState}
                isFetchingQuestions = {isFetchingQuestions}
            />
        </LoadingOverlay>
    )
}

export default CustomizedTestPage