import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Select from "react-select"
import ApexChartCard from "src/app/common/components/chart/ApexChartCard";
import {PRIMARY_COLOR} from "../../common/constants/baseConstants";
import NoDataCard from "@src/app/practice-test/components/NoDataCard"
import { secondsToDhms } from "src/utility/Utils";

const PracticeAnalytics = ({overallProgress, questionStats, questionWiseBreakdown, averageTimePerQuestion, overAllTime}) => {
    let overallProgressStat = {}
    let overallProgressSum = 0;
    if(overallProgress !== null && overallProgress !== undefined) {
        overallProgressSum = (Object.values(overallProgress).reduce((a, b) => a + b, 0));
    } else {
        overallProgressSum = 0;
    }
    if(overallProgress) {
        let maxProgress = Math.max(...Object.values(overallProgress)) * 12
        if(maxProgress > 100) {
            const maxProgress = 100
        }
        overallProgressStat = {
            options: {
                chart: {
                    id: "basic-bar",
                    width: '10%',
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    show: false,
                },
                xaxis: {
                    categories: Object.keys(overallProgress) ?? [],
                    max: 100,
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val + '%'
                    },
                    offsetX: -20,
                    offsetY: -20,
                    textAnchor: 'start',
                    style: {
                        fontSize: '10px',
                        colors: ['#000']
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        borderRadius: 8,
                        columnWidth: '20%',
                        barHeight: '40%',
                    }
                },
                colors: [PRIMARY_COLOR]
            },
            series: [
                {
                    name: "Overall Progress",
                    data: Object.values(overallProgress) ?? []
                }
            ]
        };
    }
    const questionStatsSum = (Object.values(questionStats).reduce((a, b) => a + b, 0))
    const chartQuestionStats = {
        series: Object.values(questionStats) ?? [0],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            legend: {
                show: true,
                floating: false,
                fontSize: '14px',
                position: 'bottom',
                offsetX: 0,
                offsetY: 0,
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 100
                },
                formatter: function(seriesName, opts) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
                },
                itemMargin: {
                    vertical: 10,
                    horizontal: 10
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '12px',
                        },
                        value: {
                            show: true,
                            fontSize: '12px'
                        },
                    },
                    track: {
                        show: true
                    },
                    hollow : {
                        size: '40%'
                    },
                    borderRadius: 110,
                }
            },
            labels: Object.keys(questionStats) ?? ['No Data'],
            colors : ['#45A664', '#E54D38', '#767779']
        },
    };

    const overAllTimeStatsSum = (Object.values(overAllTime).reduce((a, b) => a + b, 0))
    const overAllTimeStats = {
        series: overAllTimeStatsSum > 0 ? Object.values(overAllTime) : [0,0,0],
        options: {
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value) => secondsToDhms(value),
                },
            },
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 400
                    },
                }
            }],
            labels : Object.keys(overAllTime) ?? ['Correct', 'Incorrect', 'Skipped'],
            legend: {
                position: 'bottom',
                itemMargin: {
                    vertical: 10,
                    horizontal: 10
                }
            },
            colors : ['#3E6DE5', '#E54D38', '#F5AF19']
        },
    };

    const averageTimePerQuestionStateSum = (Object.values(averageTimePerQuestion).reduce((a, b) => a + b, 0))
    const averageTimePerQuestionState = {
        series: Object.values(averageTimePerQuestion) ?? [0,0,0],
        options: {
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value) => secondsToDhms(value),
                },
            },
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 400
                    },
                }
            }],
            labels : Object.keys(averageTimePerQuestion) ?? ['Correct', 'Incorrect', 'Skipped'],
            legend: {
                position: 'bottom',
                itemMargin: {
                    vertical: 10,
                    horizontal: 10
                }
            },
            colors : ['#3E6DE5', '#E54D38', '#F5AF19']
        },
    };

    const questionWiseBreakdownSum = (Object.values(questionWiseBreakdown).reduce((a, b) => a + b, 0))
    const questionWiseBreakdownStats = {
        series: Object.values(questionWiseBreakdown) ?? [0, 0, 0],
        options: {
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value) => secondsToDhms(value),
                },
            },
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 400
                    },
                }
            }],
            labels : Object.keys(questionWiseBreakdown) ?? ['Correct', 'Incorrect', 'Skipped'],
            legend: {
                position: 'bottom',
                itemMargin: {
                    vertical: 10,
                    horizontal: 10
                }
            },
            colors : ['#3E6DE5', '#E54D38', '#F5AF19']
        },
    };

    return (
        <div>
            <h2>Analytics</h2>
            <Row>
                { (overallProgress) &&
                    <Col md="8">
                        <Row>
                            {
                                overallProgress &&
                                <Col md="12">
                                { Object.keys(overallProgress).length > 0 && overallProgressSum > 0 ?
                                    (
                                        <ApexChartCard
                                            title="Overall Progress"
                                            options={overallProgressStat.options}
                                            series={overallProgressStat.series}
                                            type="bar"
                                            height="300"
                                        />
                                    ) :
                                    (
                                        <NoDataCard title={"Overall Progress"}></NoDataCard>
                                    )
                                }
                            </Col>
                            }
                        </Row>
                    </Col>
                }
                <Col md="4">
                    {questionStatsSum > 0 && Object.keys(questionStats).length > 0 ?
                        (
                            <ApexChartCard
                                title="Question Stats"
                                options={chartQuestionStats.options}
                                series={chartQuestionStats.series}
                                type="radialBar"
                                height="500"
                            />
                        ) :
                        (
                            <NoDataCard title={"Question Stats"}></NoDataCard>
                        )
                    }
                </Col>
                <Col md="4">
                    {overAllTimeStatsSum > 0 && Object.keys(overAllTime).length > 0 ?
                        (
                            <ApexChartCard
                                title="Overall Time"
                                options={overAllTimeStats.options}
                                series={overAllTimeStats.series}
                                type="donut"
                                height="500"
                            />
                        ) :
                        (
                            <NoDataCard title="Overall Time"></NoDataCard>
                        )
                    }
                </Col>
                <Col md="4">
                    { averageTimePerQuestionStateSum > 0 && Object.keys(averageTimePerQuestion).length > 0 ?
                        (
                            <ApexChartCard
                                title="Average time per Question"
                                options={averageTimePerQuestionState.options}
                                series={averageTimePerQuestionState.series}
                                type="donut"
                                height="500"
                            />
                        ) : (
                            <NoDataCard title={"Average time per Question"}></NoDataCard>
                        )
                    }
                </Col>
                <Col md="4">
                    { questionWiseBreakdownSum > 0 && Object.keys(questionWiseBreakdown).length > 0 ?
                        (
                            <ApexChartCard
                                title="Question Wise Breakdown"
                                options={questionWiseBreakdownStats.options}
                                series={questionWiseBreakdownStats.series}
                                type="donut"
                                height="500"
                            />
                        ) :
                        (
                            <NoDataCard title={"Question Wise Breakdown"}></NoDataCard>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
}

export default PracticeAnalytics