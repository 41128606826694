import {Modal, ModalBody} from "reactstrap";
import {useSelector} from "react-redux";
import ApplyBatchCode from "../../../views/ApplyBatchCode";

const AddBatchCode = () => {
    const store = useSelector((state) => state)
    const applyBatchCodeState = store.applyBatchCode
    const showModal = applyBatchCodeState.showApplyBatchCodeModal
    return (
        <Modal
            isOpen={showModal}
            className='modal-dialog-centered'
            modalClassName="modal-primary"
        >
            <ModalBody>
                <ApplyBatchCode
                    showIllustration = {false}
                ></ApplyBatchCode>
            </ModalBody>
        </Modal>
    )
}

export default AddBatchCode