import {initializeApp} from "firebase/app";
import {getMessaging, onMessage} from "firebase/messaging";
// Quill Plus
export const firebaseConfig = {
    apiKey: "AIzaSyAKGAfKbGD4BEPKjX_kmv7Jep1z9wwO0EA",
    authDomain: "quill-plus.firebaseapp.com",
    projectId: "quill-plus",
    storageBucket: "quill-plus.appspot.com",
    messagingSenderId: "799450562096",
    appId: "1:799450562096:web:b52c276e0ef476c3cef7f5",
    measurementId: "G-9K50F3MJY5"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () => {
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
}
