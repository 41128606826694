export const TEST_TABS = [
    {
        value: "today",
        display_name: "Today"
    },
    {
        value: "week",
        display_name: "This Week"
    },
    {
        value: "month",
        display_name: "This Month"
    },
    {
        value: "year",
        display_name: "This Year"
    }
];

export const DEFAULT_LOADER_MESSAGE = "Please wait while we fetch your details"

export const V1_CUSTOMIZED_TEST_ENDPOINT = '/api/v1/customized'

export const START_TEST = '/tests/#testId/start-test'

export const GET_UPCOMING_TESTS = '/tests/upcoming'

export const GET_TEST_DETAILS = '/tests/#testId'

export const GET_PAST_TEST = '/tests/past'

export const GET_TEST_QUESTION = '/tests/#testId/questions'

export const UPDATE_TEST_QUESTION = '/tests/#testId/questions/#questionId'

export const END_TEST = '/tests/#testId/end'

export const ENDED_TEST_INFO = '/tests/#testId/test-info'

export const GET_PAST_TEST_INFO = '/tests/#testId/user/info'

export const GET_PAST_TEST_DETAILS= '/tests/#testId/user/info/past-details'

export const GET_PAST_TEST_ANALYTICS= '/tests/#testId/user/info/analytics'

export const GET_TEST_LEADERBOARD= '/tests/#testId/leaderboard'

export const FLAG_QUESTION_URL = '/flag-question'

export const QUESTION_NOT_FETCHED = 0
export const QUESTION_FETCHED = 1

export const TEST_QUESTION_STATE_SKIPPED = 1;
export const TEST_QUESTION_STATE_CORRECT = 2;
export const TEST_QUESTION_STATE_INCORRECT = 3;
export const CUSTOMIZED_USER_TEST_ANSWER_STATE_SKIPPED = 1;
export const CUSTOMIZED_USER_TEST_ANSWER_STATE_CORRECT = 2;
export const CUSTOMIZED_USER_TEST_ANSWER_STATE_INCORRECT = 3;

// 1 - Normal End (Means either user presed end-test or back button and confirmed to end the ongoing test)
// 2 - Timedout End (Test Time got over)
// 3 - Force End (30secs out of the app, so we forcefully closed the test)
export const NORMAL_END = 1;
export const TIMEDOUT_END = 2; // When test times ends
export const FORCE_END = 3; // When user switches screen for more than 30 seconds


export const ONGOING_TEST_QUESTION_NOT_ANSWERED = 1;
export const ONGOING_TEST_QUESTION_ANSWERED = 2;
export const ONGOING_TEST_QUESTION_NOT_ATTEMPTED = 3;
export const ONGOING_TEST_QUESTION_REVIEW = 4;
export const ONGOING_TEST_QUESTION_ANSWERED_IN_REVIEW = 5;
export const ONGOING_TEST_FILTER_STATES = [
    {
        text: "Not Answered",
        class: "not-answered",
        value: 1,
        isChecked: false
    },
    {
        text: "Answered",
        class: "answered",
        value: 2,
        isChecked: false
    },
    {
        text: "Not Attempted",
        class: "not-visited",
        value: 3,
        isChecked: false
    },
    {
        text: "Review",
        class: "review",
        value: 4,
        isChecked: false
    },
    {
        text: "Answered & in Review",
        class: "review-and-answered",
        value: 5,
        isChecked: false
    }
]

export const PAST_TEST_FILTER_STATES = [
    {
        text: "Skipped",
        class: "not-answered",
        value: 1,
        isChecked: false
    },
    {
        text: "Correct",
        class: "correct",
        value: 2,
        isChecked: false
    },
    {
        text: "Incorrect",
        class: "incorrect",
        value: 3,
        isChecked: false
    }
]

export const FLAG_QUESTION_REASONS= [
    {
        text: "Wrong Question"
    },
    {
        text: "Wrong Answer"
    },
    {
        text: "Formatting Issue"
    },
    {
        text: "Other"
    },
]

export const FLAG_QUESTION_OTHER_REASON = "Other"