import PropTypes from "prop-types"
import classnames from "classnames"
import {Card, CardBody, Col, Progress, Row} from "reactstrap"
import {PRIMARY_COLOR} from "@src/app/common/constants/baseConstants"
import ReactApexChart from "react-apexcharts";
import {ReactSVG} from 'react-svg'
import {DEFAULT_SUBJECT_ICON} from "../constants/baseConstants";

const ListCard = ({
                      icon,
                      color,
                      stats,
                      renderStats,
                      statTitle,
                      className,
                      statsMargin,
                      progress,
                      subTitle
                  }) => {
    const statProgress = progress ? progress.toFixed(2) : 0;

    return (
        <Card>
            <div className="card-img-container"
                 style={{backgroundColor: '#FCEDEB'}}
            >
                {icon ?
                    <ReactSVG
                        beforeInjection={(svg) => {
                            svg.setAttribute('style', 'transform:scale(2)')
                            svg.setAttribute('fill', '#000')
                            svg.setAttribute('height', '100%')
                            svg.setAttribute('width', '100%')
                        }}
                        fill="#000" src={icon} className="card-img">
                    </ReactSVG> :
                    <img className="w-50" src={DEFAULT_SUBJECT_ICON}></img>
                }
            </div>
            <CardBody className="list-card">
                <Row className="align-items-center">
                    <Col md="7">
                        {renderStats ? (
                            renderStats
                        ) : (
                            <h6
                                className={classnames("fw-bold", {
                                    "mb-0": !statsMargin,
                                    [statsMargin]: statsMargin
                                })}
                            >
                                {stats}
                            </h6>
                        )}

                        <small className="text-dark card-text card-sub-title">{statTitle}</small>
                    </Col>
                    <Col md="5" className="text-center">
                        {
                            (progress >= 0) &&
                            <>
                                <small>{statProgress} %</small>
                                <Progress className='in-card-progress-bar progress-bar-primary' value={statProgress}/>
                            </>
                        }
                        {
                            (subTitle && subTitle != '') &&
                            <>
                                <small>{subTitle}</small>
                            </>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ListCard

// ** PropTypes
ListCard.propTypes = {
    stats: PropTypes.string,
    renderStats: PropTypes.any,
    className: PropTypes.string,
    statsMargin: PropTypes.oneOf([
        "mb-0",
        "mb-25",
        "mb-50",
        "mb-75",
        "mb-1",
        "mb-2",
        "mb-3",
        "mb-4",
        "mb-5"
    ])
}
