import {API_URL, WEB_DEVICE_TYPE} from "../../common/constants/baseConstants";
import {
    ONGOING_TEST_QUESTION_NOT_ATTEMPTED,
    START_TEST,
    V1_CUSTOMIZED_TEST_ENDPOINT
} from "../constants/CustomizedTestConstants";
import authAxios from "../../../configs/authAxios";
import {StatusCodes} from "http-status-codes";
import {useNavigate} from "react-router-dom";
import {reactLocalStorage} from "reactjs-localstorage";
import {STARTED_CUSTOMIZED_TEST} from "../../common/constants/mixPanelEvents";
import {UseMixPanel} from "../../../UseMixPanel";

export const UseStartCustomizedTest = () => {
    const navigate = useNavigate();
    const useMixPanel = UseMixPanel();

    const navigateToCustomizedHome = () => {
        navigate('/customized-tests');
    }
    const generateTest = async (testDetails) => {
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + START_TEST
        url = url.replace('#testId', testDetails.id)
        const post_obj = {
            device: WEB_DEVICE_TYPE
        }
        console.log(post_obj, WEB_DEVICE_TYPE)
        const startTestResponse = await authAxios.post(url, post_obj);
        if(startTestResponse !== undefined && startTestResponse?.status === StatusCodes.OK) {
            let testQuestions = [];
            if(startTestResponse?.data?.data?.remaining_duration) {
                testDetails.duration = startTestResponse?.data?.data?.remaining_duration;
            }
            for(let i = 1; i <= testDetails.num_questions; i++) {
                testQuestions[i] = {
                    sequence: i,
                    state: ONGOING_TEST_QUESTION_NOT_ATTEMPTED,
                };
            }
            reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testDetails.id+'_DETAILS', testDetails)
            reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testDetails.id+'_QUESTIONS', testQuestions)
            reactLocalStorage.set('CUSTOMIZED_TEST_'+testDetails.id+'_START_TIME', new Date())
            reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testDetails.id+'_QUESTIONS_TIME', [])
            const mixpanelObj = {
                'id' : testDetails.id,
                'testName' : testDetails?.name
            }
            useMixPanel.track(STARTED_CUSTOMIZED_TEST, mixpanelObj)
            navigate('/customized-tests/'+testDetails.id)
        }
    }

    return {generateTest, navigateToCustomizedHome}
}