import {Home, List, BookOpen, Book, FileText, AlertOctagon, Edit2} from "react-feather"
import ScheduleTestIcon from "../../app/common/icons/ScheduleTestIcon";
import PracticeTestIcon from "../../app/common/icons/PracticeTestIcon";
import CustomizedTestIcon from "../../app/common/icons/CustomziedTestIcon";
import ApplyBatchCodeIcon from "../../app/common/icons/ApplyBatchCodeIcon";

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/home",
    isPublic: true
  },
  {
    id: "batch-code",
    title: "Apply Code",
    icon: <ApplyBatchCodeIcon />,
    isPublic: true
  },
  {
    id: "practice-subjects",
    title: "Practice",
    icon: <PracticeTestIcon />,
    navLink: "/practice",
    isPublic: false,
    requirePerm: 'isPracticeAllowed'
  },
  {
    id: "scheduled-subjects",
    title: "Schedule",
    icon: <ScheduleTestIcon />,
    navLink: "/schedule",
    isPublic: false,
    requirePerm: 'isScheduledAllowed'
  },
  {
    id: "customized-tests",
    title: "Customized",
    icon: <CustomizedTestIcon />,
    navLink: "/customized-tests",
    isPublic: false,
    requirePerm: 'isCustomizedAllowed'
  },
]