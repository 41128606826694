import {Col, Row} from "reactstrap";
import {Book, Check, X} from "react-feather";

const CustomizedTestInstructionSubjectDetails = ({
    subjectName,
    positive_mark,
    negative_mark,
    chapters
 }) => {
    let chapterList = [];
    if(chapters !== undefined && chapters !== null && Object.keys(chapters).length > 0) {
        for(let i = 0; i < Object.keys(chapters).length; i++) {
            chapterList.push(<li key={i}>{chapters[i]}</li>)
        }
    }

    return (
        <Row>
            <Col md={12}>
                <h5>{subjectName}</h5>
                <Row className={"px-1"}>
                    <Col md={12}>
                        <p className={"m-0"}><Check></Check> Correct answers: +{positive_mark} marks</p>
                        <p className={"m-0"}><X></X> Incorrect answers: -{negative_mark} marks</p>
                    </Col>
                </Row>
                <Row className={"px-1 mt-1"}>
                    <Col md={12}>
                        <h6><Book size={18}></Book>Chapters</h6>
                    </Col>
                    <Col md={12}>
                        <ul>
                            {chapterList}
                        </ul>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default CustomizedTestInstructionSubjectDetails