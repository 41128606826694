import {SUBJECT_LIST} from "@src/app/practice-test/mock/subject-list-mock";
import {RECENT_TEST_LIST} from "@src/app/practice-test/mock/recent-test-list-mock";
import ListCard from '@src/app/common/components/ListCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Button, Card, CardBody, Col, Progress, Row} from "reactstrap";
import LoadingOverlay from 'react-loading-overlay-ts';
import {PropsWithChildren} from "react";
import {Link, useNavigate} from "react-router-dom";
import {BarChart2, Check, Play} from "react-feather";
import SmallListCard from "src/app/common/components/SmallListCard";
import authAxios from "src/configs/authAxios";
import {API_URL, DEFAULT_API_URL_PREFIX} from "@src/app/common/constants/baseConstants";
import {toQueryString} from "@src/utility/Utils";
import {useState} from "react";
import {useEffect} from "react";
import PracticeAnalytics from "./PracticeAnalytics";
import {StatusCodes} from "http-status-codes";
import toast from "react-hot-toast";
import {reactLocalStorage} from "reactjs-localstorage";
import {setCookie} from "src/app/common/helpers/CookieHelper";
import {useDispatch, useSelector} from "react-redux";
import {PRIMARY_COLOR} from "src/app/common/constants/baseConstants";
import {
    setTestId,
    setTestName,
    setTotalMarks,
    setTotalScore,
    setAnalytics
} from "@src/app/practice-test/reducers/states/practiceTestAnalyticsSlice"
import {hmsToSeconds, setTitle} from "src/utility/Utils";

const PracticeTest = () => {
    setTitle("Practice Test");

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const store = useSelector((state) => state)
    const userStore = store.user

    const GET_ALL_PAUSED_TEST = API_URL + DEFAULT_API_URL_PREFIX + 'practice/paused-tests'
    const GET_ALL_RECENT_TEST = API_URL + DEFAULT_API_URL_PREFIX + 'practice/previous-tests'
    const GET_SUBJECT_URL = API_URL + DEFAULT_API_URL_PREFIX + 'practice/subjects';
    const [currentWhiteLabelId, setCurrentWhiteLableId] = useState(null)
    const [currentStandardId, setCurrentStandardId] = useState(null)
    useEffect(() => {
        if (userStore?.currentWhiteLabel?.data?.id) {
            setCurrentWhiteLableId(userStore?.currentWhiteLabel?.data?.id)
        }
        if (userStore.currentStandardMetaDataId) {
            setCurrentStandardId(userStore.currentStandardMetaDataId)
        }
    })
    const [fetchedAllPausedTest, setFetchedAllPausedTest] = useState(false);
    const [fetchedAllRecentTests, setFetchedAllRecentTests] = useState(false);
    const [subjectListComponent, setSubjectListComponent] = useState([])
    const [subjectList, setSubjectList] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [loaderIsActive, setLoaderIsActive] = useState(false);
    const [allPausedTestListComponent, setAllPausedTestListComponent] = useState([])
    const [allRecentTestListComponent, setAllRecentTestListComponent] = useState([])
    const [allPausedTest, setAllPausedTest] = useState([]);
    const [allRecentTest, setAllRecentTest] = useState([]);
    const [subjectListSkeleton, setSubjectListSkeleton] = useState([]);
    const [pausedTestSkeleton, setPausedTestSkeleton] = useState([]);
    const [questionStats, setQuestionStats] = useState({});
    const [overallProgress, setOverallProgress] = useState({});
    const [questionWiseBreakdown, setQuestionWiseBreakdown] = useState({})
    const [averageTimePerQuestion, setAverageTimePerQuestion] = useState({})
    const [overAllTime, setOverAllTime] = useState({})

    function Box({children}: PropsWithChildren<unknown>) {
        return (
            <div
                style={{
                    background: 'white',
                    className: 'collapsable-card',
                    display: 'block',
                    borderRadius: '0.428rem',
                    marginBottom: '1rem',
                    padding: '1rem',
                }}
            >
                <div className="card-img-container">
                    {children}
                </div>
                <div className="list-card">
                    <Row>
                        <Col md="8">
                            {children}
                        </Col>
                        <Col md="4">
                            {children}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    function RecentTestSkeletonBox({children}: PropsWithChildren<unknown>) {
        return (
            <div
                style={{
                    background: 'white',
                    className: 'list-card',
                    display: 'block',
                    borderRadius: '0.428rem',
                    marginTop: '1rem',
                    padding: '1rem',
                    marginBottom: '0.5rem',
                }}
            >
                <div className="" style={{marginLeft: '4rem'}}>
                    {children}
                </div>
                <div className="" style={{marginLeft: '4rem', marginTop: '1rem'}}>
                    <Row>
                        <Col md="4">
                            {children}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    const processAnalyticsData = (data) => {
        const newQuestionStats = {};
        let total = parseFloat((data?.num_correct ?? 0) + (data?.num_incorrect ?? 0) + (data?.num_skipped ?? 0))
        total = isNaN(total) ? 0 : total;
        // newQuestionStats['Total'] = total / total * 100;
        // newQuestionStats['accuracy'] = newQuestionStats['total'] > 0 ? ((data?.num_correct / newQuestionStats['total']) * 100).toFixed(0) : 0;
        // let attempted = parseFloat(((data?.num_correct ?? 0) + (data?.num_incorrect ?? 0) / total * 100).toFixed(2))
        // attempted = isNaN(attempted) ? 0 : attempted;
        // newQuestionStats['Attempted'] = attempted;
        let correct = parseFloat(((data?.num_correct ?? 0) / total * 100).toFixed(2))
        correct = isNaN(correct) ? 0 : correct;
        newQuestionStats['Correct'] = correct;
        let inCorrect = parseFloat(((data?.num_incorrect ?? 0) / total * 100).toFixed(2))
        inCorrect = isNaN(inCorrect) ? 0 : inCorrect;
        newQuestionStats['Incorrect'] = inCorrect;
        let skipped = parseFloat(((data?.num_skipped ?? 0) / total * 100).toFixed(2))
        skipped = isNaN(skipped) ? 0 : skipped;
        newQuestionStats['Skipped'] = skipped;
        setQuestionStats(newQuestionStats);

        const newQuestionWiseBreakdown = {};
        newQuestionWiseBreakdown['Correct'] = (data?.num_correct ?? 0);
        newQuestionWiseBreakdown['Incorrect'] = (data?.num_incorrect ?? 0);
        newQuestionWiseBreakdown['Skipped'] = (data?.num_skipped ?? 0);
        setQuestionWiseBreakdown(newQuestionWiseBreakdown);

        const newAverageTimePerQuestion = {};
        newAverageTimePerQuestion['Correct'] = hmsToSeconds(data?.avg_time_correct);
        newAverageTimePerQuestion['Incorrect'] = hmsToSeconds(data?.avg_time_incorrect);
        newAverageTimePerQuestion['Skipped'] = hmsToSeconds(data?.avg_time_skipped);
        setAverageTimePerQuestion(newAverageTimePerQuestion);

        const newOverAllTime = {};
        newOverAllTime['Correct'] = hmsToSeconds(data?.avg_time_correct) * (data?.num_correct ?? 0);
        newOverAllTime['Incorrect'] = hmsToSeconds(data?.avg_time_incorrect) * (data?.num_incorrect ?? 0);
        newOverAllTime['Skipped'] = hmsToSeconds(data?.avg_time_skipped) * (data?.num_skipped ?? 0);
        setOverAllTime(newOverAllTime);
    }

    useEffect(() => {
        //set all states to initial value
        setSubjectListComponent([])
        setSubjectList([])
        setFetchedAllPausedTest(false)
        setFetchedAllRecentTests(false)
        setAllPausedTestListComponent([])
        setAllPausedTest([])
        setAllRecentTest([])
        setAllRecentTestListComponent([])
        //ends
        if (currentWhiteLabelId && currentStandardId) {
            const postObj = {
                'standard_id': currentStandardId,
                'whitelabel_id': currentWhiteLabelId,
                'device': 'web'
            }

            const getOverallReports = async () => {
                const getReportAnalyticsURL = API_URL + DEFAULT_API_URL_PREFIX + 'practice/over-all/reports/breakdown'
                const reportAnalytics = await authAxios.get(getReportAnalyticsURL + toQueryString(postObj))
                processAnalyticsData(reportAnalytics?.data?.data)
            }

            const getSubjects = async () => {
                const newSubjects = await authAxios.get(GET_SUBJECT_URL + toQueryString(postObj))
                setSubjects(newSubjects)
            }

            const getAllPausedTest = async () => {
                const allPausedTestResponse = await authAxios.get(GET_ALL_PAUSED_TEST + toQueryString(postObj))
                if (allPausedTestResponse?.status == StatusCodes.OK) {
                    setAllPausedTest(allPausedTestResponse?.data?.data)
                    setFetchedAllPausedTest(true)
                } else {
                    toast.error('Unable to fetch recent tests');
                }
            }

            const getAllRecentTest = async () => {
                const allRecentTestResponse = await authAxios.get(GET_ALL_RECENT_TEST + toQueryString(postObj))
                if (allRecentTestResponse?.status == StatusCodes.OK) {
                    setAllRecentTest(allRecentTestResponse?.data?.data)
                    setFetchedAllRecentTests(true)
                } else {
                    toast.error('Unable to fetch recent tests');
                }
            }

            getSubjects();
            getAllPausedTest();
            getAllRecentTest();
            getOverallReports();

            const newSubjectSkeleton = [];
            for (let i = 0; i < 3; i++) {
                newSubjectSkeleton.push(<Col key={i} md="4"><Skeleton wrapper={Box}/></Col>)
            }
            setSubjectListSkeleton(newSubjectSkeleton)

            const newRecentTestSkeleton = []
            for (let i = 0; i < 2; i++) {
                newRecentTestSkeleton.push(<Col key={i} md="12"><Skeleton
                    wrapper={RecentTestSkeletonBox}></Skeleton></Col>)
            }
            setPausedTestSkeleton(newRecentTestSkeleton)
        }
    }, [currentWhiteLabelId, currentStandardId])

    useEffect(() => {
        const newSubjectList = subjects?.data?.data;
        setSubjectList(newSubjectList)
    }, [subjects]);

    useEffect(() => {
        let newOverallProgress = {};
        if (subjectList != null && subjectList.length > 0) {
            const newSubjectListComponent = subjectList.map(subject => {
                if(subject.progress > 0) {
                    newOverallProgress[subject.name] = subject.progress
                }
                return <Col key={subject.id} md="4" lg="4" sm="12">
                    <Link to={`/practice/${subject.id}/syllabus`}>
                        <ListCard
                            key={subject.id}
                            className="collapsable-card"
                            icon={subject.icon}
                            color={subject.color}
                            stats={subject.name}
                            statTitle={subject.subtitle}
                            progress={subject.progress}
                        />
                    </Link>
                </Col>
            })
            setSubjectListComponent(newSubjectListComponent)
            setOverallProgress(newOverallProgress)
        }
    }, [subjectList]);

    const getTestDetails = async (testId) => {
        let existingChapters = reactLocalStorage.getObject('practiceChapters') ?? {};
        existingChapters = Object.entries(existingChapters)
        let testName = allRecentTest.filter((recent) => {
            if (recent.id == testId) {
                return recent
            }
        })
        testName = (testName[0].subject ?? 'Test') + " - Practice Test";

        setLoaderIsActive(true)
        const getTestQuestionsURL = API_URL + DEFAULT_API_URL_PREFIX + 'practice/test/' + testId + '/details'
        const res = await authAxios.get(getTestQuestionsURL + toQueryString({device: 'web'}))
        let analyticsChapterWiseCorrectness = [];
        if (res?.status === StatusCodes.OK) {
            const responseData = res?.data?.data
            const questions = responseData?.testQuestions?.data
            const testAnswers = responseData?.testAnswers?.data
            let newQuestions = [];
            let testSummary = [];
            let newTestAnswers = [];
            let newQuestionState = [];
            let totalTimeSpent = 0;
            if (questions) {
                newQuestions = questions.map((question, index) => {
                    let testAnswer = testAnswers.filter(answer => {
                        if (question.id == answer.question_id) {
                            return answer
                        }
                    })
                    testAnswer = testAnswer[0] ?? {}
                    let correctOption;
                    const options = question?.options?.data
                    if (options) {
                        let cOpt = options.filter(option => {
                            if (option.is_correct) {
                                return option
                            }
                        })
                        if (cOpt[0])
                            correctOption = cOpt[0].id ?? null
                    }
                    totalTimeSpent += question?.time_spent ?? 0
                    const chapterId = question.chapter_id
                    existingChapters[chapterId] = question?.chapter_name ?? question.chapter_id;
                    const ans = {
                        id: question.id,
                        correct_option_id: correctOption,
                        chapter_id: question.chapter_id,
                        difficulty_level: question?.difficulty_level ?? "easy",
                        answer_id: testAnswer?.answer_id ?? null,
                        was_skipped: 0,
                        time_taken: question?.time_taken ?? 0,
                        time_spent: question?.time_spent ?? 0,
                        was_correct: testAnswer?.was_correct ?? 0
                    };
                    if (testSummary[chapterId] == undefined) {
                        testSummary[chapterId] = []
                        testSummary[chapterId]['num_skipped'] = 0;
                        testSummary[chapterId]['total_time_skipped'] = 0;
                        testSummary[chapterId]['num_correct'] = 0;
                        testSummary[chapterId]['total_time_correct'] = 0;
                        testSummary[chapterId]['num_incorrect'] = 0;
                        testSummary[chapterId]['total_time_incorrect'] = 0;
                    }
                    if (!ans.answer_id) {
                        testSummary[chapterId]['num_skipped']++;
                        testSummary[chapterId]['total_time_skipped'] += question?.time_taken ?? 0;
                    } else if (ans.was_correct) {
                        testSummary[chapterId]['num_correct']++;
                        testSummary[chapterId]['total_time_correct'] += question?.time_taken ?? 0;
                    } else {
                        testSummary[chapterId]['num_incorrect']++;
                        testSummary[chapterId]['total_time_incorrect'] += question?.time_taken ?? 0;
                    }
                    newTestAnswers.push(ans)

                    newQuestionState.push({
                        id: question.id,
                        sr_no: index,
                        status: ans.answer_id == null ? 'not_answered' : (ans.was_correct ? 'correct' : 'incorrect'),
                        difficulty_level: question.difficulty_level
                    })
                    return {sr_no: index, ...question}
                })
            }
            reactLocalStorage.setObject('practiceChapters', existingChapters)
            for (let i = 0; i < testSummary.length; i++) {
                if (testSummary[i] != undefined) {
                    let chapterWiseCorrectness = {}
                    chapterWiseCorrectness.chapter_id = i;
                    chapterWiseCorrectness.correct = testSummary[i]['num_correct'];
                    chapterWiseCorrectness.incorrect = testSummary[i]['num_incorrect'];
                    chapterWiseCorrectness.skipped = testSummary[i]['num_skipped'];
                    analyticsChapterWiseCorrectness.push(chapterWiseCorrectness)
                }
            }
            const testId = responseData.id
            reactLocalStorage.remove('questionAnswerSelected');
            reactLocalStorage.set('practiceTestId', testId);
            reactLocalStorage.setObject('analyticsPracticeTestQuestions', newQuestions);
            reactLocalStorage.setObject('analyticsQuestionAnswerSelected', newTestAnswers);
            reactLocalStorage.setObject('analyticsQuestionStates', newQuestionState);
            setLoaderIsActive(false)
        }
        const testAnalyticsURL = API_URL + DEFAULT_API_URL_PREFIX + 'practice/test/' + testId + '/reports/breakdown'
        const response = await authAxios.get(testAnalyticsURL + toQueryString({device: 'web'}))
        if (response.status == StatusCodes.OK) {
            const data = response?.data?.data
            if (data) {
                const totalQuestions = (data.num_correct ?? 0) + (data.num_incorrect ?? 0) + (data.num_skipped ?? 0)
                const testId = data.practice_test_id
                let totalMarks = (data.num_correct ?? 0) + (data.num_incorrect ?? 0) + (data.num_skipped ?? 0)
                let totalScore = (data.num_correct ?? 0)
                let accuracy = ((data.num_correct ?? 0) / totalQuestions) * 100
                let totalTime = (((hmsToSeconds(data.avg_time_correct) ?? 0) * (data.num_correct ?? 0)) + ((hmsToSeconds(data.avg_time_incorrect) ?? 0) * (data.num_incorrect ?? 0)) + ((hmsToSeconds(data.avg_time_skipped) ?? 0) * (data.num_skipped ?? 0)));

                let averageTimePerQuestion = (((totalTime / totalQuestions)) / 60).toFixed(2);
                let questionWiseBreakdown = {}
                questionWiseBreakdown.CORRECT = data.num_correct;
                questionWiseBreakdown.INCORRECT = data.num_incorrect;
                questionWiseBreakdown.SKIPPED = data.num_skipped;
                let questionCorrectnessWiseTime = {}
                questionCorrectnessWiseTime.CORRECT = (hmsToSeconds(data.avg_time_correct) ?? 0) * (data.num_correct ?? 0);
                questionCorrectnessWiseTime.INCORRECT = (hmsToSeconds(data.avg_time_incorrect) ?? 0) * (data.num_incorrect ?? 0);
                questionCorrectnessWiseTime.SKIPPED = (hmsToSeconds(data.avg_time_skipped) ?? 0) * (data.num_skipped ?? 0);
                let averageTimePerQuestionState = {}
                averageTimePerQuestionState.CORRECT = (hmsToSeconds(data.avg_time_correct) ?? 0);
                averageTimePerQuestionState.INCORRECT = (hmsToSeconds(data.avg_time_incorrect) ?? 0);
                averageTimePerQuestionState.SKIPPED = (hmsToSeconds(data.avg_time_skipped) ?? 0);

                const testAnalytics = {
                    testId: testId,
                    testName: testName,
                    totalMarks: totalMarks,
                    totalScore: totalScore,
                    analytics: {
                        accuracy: accuracy,
                        averageTimePerQuestion: averageTimePerQuestion,
                        questionWiseBreakdown: questionWiseBreakdown,
                        questionCorrectnessWiseTime: questionCorrectnessWiseTime,
                        averageTimePerQuestionState: averageTimePerQuestionState,
                        chapterWiseCorrectness: analyticsChapterWiseCorrectness
                    }
                }
                // console.log(testAnalytics)
                // return;
                dispatch(setTestId(testId));
                dispatch(setTestName(testName))
                dispatch(setTotalMarks(totalMarks))
                dispatch(setTotalScore(totalScore))
                dispatch(setAnalytics(testAnalytics.analytics))
                reactLocalStorage.setObject('practiceTestAnalytics', testAnalytics)
                navigate('/practice/test/' + testId + '/analytics')
            }
        }
    }

    const resumeTest = async (testId, subjectId) => {
        let savedQuestionIds = [];
        let testName = allPausedTest.filter((recent) => {
            if (recent.id == testId) {
                return recent
            }
        })
        setLoaderIsActive(true)
        const fetchSavedQuestionURL = `${API_URL}${DEFAULT_API_URL_PREFIX}practice/subject/${subjectId}/saved-questions`
        const fetchSavedQuestionResponse = await authAxios.get(fetchSavedQuestionURL)
        if (fetchSavedQuestionResponse?.data?.data) {
            const savedQuestions = fetchSavedQuestionResponse.data.data
            if (savedQuestions) {
                savedQuestionIds = savedQuestions.map(savedQuestion => {
                    return savedQuestion.id
                })
                reactLocalStorage.setObject('practiceSavedQuestions', savedQuestionIds);
            }
        }
        testName = (testName[0].subject ?? 'Test') + " - Practice Test";
        reactLocalStorage.set('practiceTestName', testName);
        reactLocalStorage.set('practice_test_subject_id', subjectId)
        const resumeTestURL = API_URL + DEFAULT_API_URL_PREFIX + 'practice/test/' + testId + '/resume-test'
        const response = await authAxios.get(resumeTestURL + toQueryString({device: 'web'}))
        const GET_QUOTES_URL = API_URL + DEFAULT_API_URL_PREFIX + 'practice/quotes'
        const quotes = await authAxios.get(GET_QUOTES_URL + toQueryString({device: 'web'}))
        reactLocalStorage.setObject('quotes', quotes?.data?.data)
        if (response?.status === StatusCodes.OK) {
            const responseData = response?.data?.data
            const questions = responseData?.testQuestions?.data
            const testAnswers = responseData?.testAnswers?.data
            let newQuestions = [];
            let newTestAnswers = [];
            let totalTimeSpent = 0;
            if (questions) {
                newQuestions = questions.map((question, index) => {
                    let testAnswer = testAnswers.filter(answer => {
                        if (question.id == answer.question_id) {
                            return answer
                        }
                    })
                    testAnswer = testAnswer[0] ?? {}

                    totalTimeSpent += question?.time_spent ?? 0
                    newTestAnswers.push({
                        id: question.id,
                        chapter_id: question.chapter_id,
                        difficulty_level: question?.difficulty_level ?? "easy",
                        is_saved: savedQuestionIds && Object.keys(savedQuestionIds).length > 0 && savedQuestionIds.includes(question.id),
                        answer_id: testAnswer?.answer_id ?? null,
                        was_skipped: 0,
                        time_taken: question?.time_taken ?? 0,
                        time_spent: question?.time_spent ?? 0,
                        was_correct: testAnswer?.was_correct ?? 0
                    })
                    return {sr_no: index, ...question}
                })
            }
            // if(testAnswers) {
            //     newTestAnswers = testAnswers.map(answer => {
            //         let question = newQuestions.filter(question => {
            //             if(question.id == answer.question_id) {
            //                 return question
            //             }
            //         })
            //         question = question[0] ?? {}
            //         return {
            //             id: answer.question_id,
            //             chapter_id: question.chapter_id,
            //             difficulty_level: question?.difficulty_level ?? "easy",
            //             answer_id: answer.answer_id,
            //             was_skipped: 0,
            //             time_spent : question?.time_taken ?? 0,
            //             after_answer_time_spent : 0,
            //             was_correct: answer.was_correct
            //         }
            //     })
            // }
            const testId = responseData.id
            setCookie('practice_subject_id', subjectId);
            reactLocalStorage.set('practiceTestId', testId);
            reactLocalStorage.setObject('practiceTestQuestions', newQuestions);
            reactLocalStorage.remove('questionAnswerSelected');
            reactLocalStorage.setObject('questionAnswerSelected', newTestAnswers)
            reactLocalStorage.remove('practice_test_start_time');
            reactLocalStorage.set('practice_test_previous_time_spent', totalTimeSpent)
            navigate('/practice/test/' + testId)
            setLoaderIsActive(false)
        } else {
            setLoaderIsActive(false)
            toast.error("Something went wrong");
        }
    }

    useEffect(() => {
        const newAllPausedTestListComponent = allPausedTest.map(recent => {
            const clickIcon = (
                <Play
                    className="cursor-pointer resume_test"
                    data-id={recent.id}
                    size={25}
                    fill={PRIMARY_COLOR}
                    color={PRIMARY_COLOR}
                ></Play>
            )
            return <Col key={recent.id} md="12" data-id={recent.id}>
                <SmallListCard
                    primaryId={recent.id}
                    secondaryId={recent.subject_id}
                    id={recent.id}
                    subjectId={recent.subject_id}
                    onClickIcon={clickIcon}
                    title={recent.subject}
                    subtitle={`${recent.answer_count} / ${recent.num_of_questions}`}
                    info={`${recent.paused_at}`}
                    onClickFunction={resumeTest}
                    functionName="resumeTest"
                />
            </Col>
        })
        setAllPausedTestListComponent(newAllPausedTestListComponent)
    }, [allPausedTest])

    useEffect(() => {
        const newAllRecentTestListComponent = allRecentTest.map(recent => {
            const clickIcon = (
                <BarChart2
                    className="cursor-pointer test_details"
                    data-id={recent.id}
                    size={25}
                    fill={PRIMARY_COLOR}
                    color={PRIMARY_COLOR}
                ></BarChart2>
            )
            return <Col key={recent.id} md="12" data-id={recent.id}>
                <SmallListCard
                    primaryId={recent.id}
                    secondaryId={recent.subject_id}
                    id={recent.id}
                    subjectId={recent.subject_id}
                    onClickFunction={getTestDetails}
                    onClickIcon={clickIcon}
                    functionName="getTestDetails"
                    title={recent.subject}
                    subtitle={`${recent.num_correct} / ${recent.num_of_questions}`}
                    info={`No. of Chapters: ${recent.num_chapters}`}
                />
            </Col>
        })
        setAllRecentTestListComponent(newAllRecentTestListComponent)
    }, [allRecentTest])

    return (
        <div>
            <LoadingOverlay
                active={loaderIsActive}
                spinner
                text='Please wait while we get your test details'
            >
                <h2>Practice Test</h2>
                <Row className="mt-10">
                    <Col md="8" sm="12">
                        <Row className="mt-1">
                            <Col md="6" sm="12">
                                <span><h4>Subjects</h4></span>
                            </Col>
                            {/* <Col className="col-md-6">
                                <Button className="float-end" color="primary">View Analytics</Button>
                            </Col> */}
                        </Row>
                        <Row className="mt-1">
                            {subjectListComponent.length == 0 && subjectListSkeleton}
                            {subjectListComponent}
                        </Row>
                    </Col>
                    <Col md="4" sm="12">
                        <div className="paused_test_list">
                            <Row className="mt-1">
                                <Col md="12">
                                    <span><h4>Paused Test</h4></span>
                                </Col>
                            </Row>
                            <Row className="mt-1 test_list mini_scroll_bar">
                                {!fetchedAllPausedTest && pausedTestSkeleton}
                                {allPausedTestListComponent}
                                {(allPausedTestListComponent.length == 0 && fetchedAllPausedTest) &&
                                    <p>No recent tests found.</p>
                                }
                            </Row>
                        </div>
                        <div className="recent_test_list">
                            <Row className="mt-1">
                                <Col className="col-md-12">
                                    <span><h4>Recently Attempted Test</h4></span>
                                </Col>
                            </Row>
                            <Row className="mt-1 test_list mini_scroll_bar">
                                {!fetchedAllRecentTests && pausedTestSkeleton}
                                {allRecentTestListComponent}
                                {(allRecentTestListComponent.length == 0 && fetchedAllRecentTests) &&
                                    <p>No recent tests found.</p>
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-10">
                    <PracticeAnalytics
                        overallProgress={overallProgress}
                        questionStats={questionStats}
                        questionWiseBreakdown={questionWiseBreakdown}
                        averageTimePerQuestion={averageTimePerQuestion}
                        overAllTime={overAllTime}
                    ></PracticeAnalytics>
                </Row>
            </LoadingOverlay>
        </div>
    )
}

export default PracticeTest;