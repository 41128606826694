// ** React Imports
import {Fragment, lazy, useEffect, useState} from "react"
import {Navigate} from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

const utils = require("src/utility/Utils");
// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"
import PrivateRoute from "@components/routes/PrivateRoute"
// ** Utils
import {isObjEmpty} from "@utils"
import PracticeTest from "@src/app/practice-test/components/PracticeTest";
import Topics from "@src/app/practice-test/components/Topics"
import UnderMaintenance from "src/views/UnderMaintenance"
import DownloadMobileApp from "src/views/DownloadMobileApp"
import {useSelector} from "react-redux"
import VerifyPassword from "../../views/VerifyPassword";
import CustomizedTestHome from "@src/app/customized-test/components/CustomizedTestHome"
import CustomizedTestPage from "../../app/customized-test/components/User-test/CustomizedTestPage";
import CustomizedPastTest from "../../app/customized-test/components/User-test/CustomizedPastTest";

const getLayout = {
    blank: <BlankLayout/>,
    vertical: <VerticalLayout/>,
    horizontal: <HorizontalLayout/>
}

// ** Document title
const TemplateTitle = "%s - Quill Plus"

// ** Default Route
const DefaultRoute = "/home"

const Home = lazy(() => import("../../views/Home"))
const LandingPage = lazy(() => import("../../views/LandingPage"))
const Notifications = lazy(() => import("@src/app/notifications/Notifications"))
const Notification = lazy(() => import("@src/app/notifications/Notification"))
const Login = lazy(() => import("../../views/Login"))
const ApplyBatchCode = lazy(() => import("@src/views/ApplyBatchCode"))
const VerifyOTP = lazy(() => import("@src/views/VerifyOTP"))
const Syllabus = lazy(() => import("src/app/practice-test/components/Syllabus"))
const Register = lazy(() => import("../../views/Register"))
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import("../../views/Error"))
const PracticeTestPage = lazy(() => import("@src/app/practice-test/components/user-test/PracticeTestPage"))
const PracticeTestAnalytics = lazy(() => import("@src/app/practice-test/components/analytics/PracticeTestAnalytics"))
const ScheduleTest = lazy(() => import("@src/app/schedule-test/components/ScheduleTest"))
const ScheduleTestSubject = lazy(() => import("@src/app/schedule-test/components/subjects/components/ScheduleTestSubject"))
const ScheduleTestPage = lazy(() => import("@src/app/schedule-test/components/ScheduleTestPage"))
const ScheduleTestAnalytics = lazy(() => import("@src/app/schedule-test/components/ScheduleTestAnalytics"))
const AboutUs = lazy(() => import("@src/app/misc/components/AboutUs"))
const PrivacyPolicy = lazy(() => import("@src/app/misc/components/PrivacyPolicy"))
const TermsAndConditions = lazy(() => import("@src/app/misc/components/TermsAndConditions"))
const Profile = lazy(() => import("@src/views/Profile"))
const PasswordLogin = lazy(() => import("@src/views/VerifyPassword"))
// ** Merge Routes
const isMobileView = false;
// const isMobileView = (navigator.userAgent.match(/Android/i) ||
//     navigator.userAgent.match(/iPhone/i))
const getComponent = (Component) => {
    // if (navigator.userAgent.match(/Android/i) ||
    //     navigator.userAgent.match(/iPhone/i)) {
    //     return <DownloadMobileApp/>
    // }
    return <Component/>
}
const Routes = [
    {
        path: "/",
        index: true,
        meta: {
            layout: "blank"
        },
        // element: <LandingPage/>
        element: <Navigate replace to={DefaultRoute}/>
    },
    {
        path: "/home",
        meta: {
            is_private: true
        },
        element: <Home/>
    },
    {
        path: "/customized-tests",
        meta: {
            is_private: true,
            requirePerm: 'isCustomizedAllowed'
        },
        element: <CustomizedTestHome/>
    },
    {
        path: "/customized-tests/:testId",
        meta: {
            is_private: true,
            requirePerm: 'isCustomizedAllowed',
            layout: "blank"
        },
        element: <CustomizedTestPage/>
    },
    {
        path: "/customized-tests/:testId/result",
        meta: {
            is_private: true,
            requirePerm: 'isCustomizedAllowed',
            layout: "blank"
        },
        element: <CustomizedPastTest/>
    },
    {
        path: "/practice/:subject/books/:book/topics",
        meta: {
            is_private: true,
            requirePerm: 'isPracticeAllowed'
        },
        element: <Topics/>
    },
    {
        path: "/practice/:subject/syllabus",
        meta: {
            is_private: true,
            requirePerm: 'isPracticeAllowed'
        },
        element: <Syllabus/>
    },
    {
        path: "/practice/test/:testId",
        meta: {
            is_private: true,
            requirePerm: 'isPracticeAllowed',
            layout: "blank"
        },
        element: <PracticeTestPage/>
    },
    {
        path: "/practice/test/:testId/analytics",
        meta: {
            is_private: true,
            requirePerm: 'isPracticeAllowed',
            layout: "blank"
        },
        element: <PracticeTestAnalytics/>
    },
    {
        path: "/practice",
        meta: {
            is_private: true,
            requirePerm: 'isPracticeAllowed'
        },
        element: <PracticeTest/>
    },
    {
        path: "/schedule",
        meta: {
            is_private: true,
            requirePerm: 'isScheduledAllowed',
        },
        element: <ScheduleTest/>
    },
    {
        path: "/schedule/subject/:subjectId",
        meta: {
            is_private: true,
            requirePerm: 'isScheduledAllowed',
        },
        element: <ScheduleTestSubject/>
    },
    {
        path: "/schedule/test/:testId",
        meta: {
            layout: "blank",
            is_private: true,
            requirePerm: 'isScheduledAllowed',
        },
        element: <ScheduleTestPage/>
    },
    {
        path: "/schedule/test/:testId/analytics",
        meta: {
            layout: "blank",
            is_private: true,
            requirePerm: 'isScheduledAllowed',
        },
        element: <ScheduleTestAnalytics/>
    },
    {
        path: "profile",
        meta: {
            is_private: true,
        },
        element: <Profile/>
    },
    {
        path: "notification",
        meta: {
            is_private: true,
        },
        element: <Notifications/>
    },
    {
        path: "notification/:id",
        meta: {
            is_private: true,
        },
        element: <Notification/>
    },
    {
        path: "/login",
        element: <Login/>,
        title: 'Login',
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/register",
        element: <Register/>,
        title: 'Register',
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/verify-otp",
        element: <VerifyOTP/>,
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/apply-batch-code",
        element: <ApplyBatchCode/>,
        meta: {
            layout: "blank",
            is_private: true
        }
    },
    {
        path: "/verify-password",
        element: <VerifyPassword/>,
        title: 'Login - Password',
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>,
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/under-maintenance",
        element: <UnderMaintenance/>,
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/error",
        element: <Error/>,
        meta: {
            layout: "blank"
        }
    },
    {
        path: "/download-mobile-app",
        element: <DownloadMobileApp/>,
        meta: {
            layout: "blank"
        }
    },
    {
        path: "about-us",
        element: <AboutUs/>,
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicy/>,
        meta: {
            layout: "blank"
        }
    },
    // {
    //   path: "terms-and-conditions",
    //   element: <TermsAndConditions /,
    // },
    {
        path: "*",
        element: <Error/>,
        meta: {
            layout: "blank"
        }
    },
]

const getRouteMeta = (route) => {
    if (isObjEmpty(route.element.props)) {
        if (route.meta) {
            return {routeMeta: route.meta}
        } else {
            return {}
        }
    }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
    const store = useSelector((state) => state)
    const userStore = store.user
    const [LayoutRoutes, setLayoutRoutes] = useState([]);
    useEffect(() => {
        const newLayoutRoutes = [];
        if (Routes) {
            Routes.filter((route) => {
                let isBlank = false
                // ** Checks if Route layout or Default layout matches current layout
                if (
                    (route.meta && route.meta.layout && route.meta.layout === layout) ||
                    ((route.meta === undefined || route.meta.layout === undefined) &&
                        defaultLayout === layout) && ((!route?.meta?.requirePerm || (route.meta.requirePerm && userStore[route.meta.requirePerm])))
                ) {
                    let RouteTag = null
                    if (route?.meta?.is_private) {
                        RouteTag = PrivateRoute
                    } else {
                        RouteTag = PublicRoute
                    }
                    // ** Check for public or private route
                    if (route.meta) {
                        route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
                    }
                    if (route.element) {
                        const Wrapper =
                            // eslint-disable-next-line multiline-ternary
                            isObjEmpty(route.element.props) && isBlank === false
                                ? // eslint-disable-next-line multiline-ternary
                                LayoutWrapper
                                : Fragment

                        route.element = (
                            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                                <RouteTag route={route}>{route.element}</RouteTag>
                            </Wrapper>
                        )
                    }

                    // Push route to LayoutRoutes
                    newLayoutRoutes.push(route)
                }
            })
            setLayoutRoutes(newLayoutRoutes);
        }
    }, [userStore]);
    return LayoutRoutes
}

const getRoutes = (layout) => {
    const defaultLayout = layout || "vertical"
    const layouts = ["vertical", "horizontal", "blank"]

    const AllRoutes = []

    layouts.forEach((layoutItem) => {
        const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

        AllRoutes.push({
            path: "/",
            element: getLayout[layoutItem] || getLayout[defaultLayout],
            children: LayoutRoutes
        })
    })
    return AllRoutes
}
export {DefaultRoute, TemplateTitle, Routes, getRoutes}
