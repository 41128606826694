import { Col, Row } from "reactstrap";

const Topics = () => {
    return (
        <div>
            <h2>Biology - Human Body (Ed.2022) - Topics</h2>
            <Row className="mt-10">
                <Col className="col-md-8"></Col>
            </Row>
        </div>
    )
}

export default Topics;