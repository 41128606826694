import {Card, CardBody} from "reactstrap";

const InformationCard = ({
                             title,
                             subTitle,
                             info,
                             icon,
                             iconBackgroundColor
                         }) => {
    return (
        <Card>
            <CardBody className="list-card">
                <div className="transaction-item">
                    <div className="d-flex bd-highlight">
                        <div className="bd-highlight w-100">
                            <div className="d-inline-flex w-100">
                                {icon &&
                                    <div className="p-1 mx-1 d-flex rounded"
                                         style={{backgroundColor: iconBackgroundColor}}>
                                        {icon}
                                    </div>}
                                <div className="p-1 w-100">
                                    <h2 className="transaction-title">{title}</h2>
                                    <h4 className="card-sub-title" id={"info_card_sub_title"}>
                                        {subTitle}
                                        <span className="float-end card-sub-title" id="info_card_text">{info}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default InformationCard;