import Chart from "react-apexcharts";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap"

const ApexChartCard = ({title, options, series, type, height}) => {
    return (
        <Card>
            {title && <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>}
            <CardBody style={{padding: '0.5rem'}}>
                <Chart
                    options={options}
                    series={series}
                    type={type}
                    height={height ?? "350"}
                />
            </CardBody>
        </Card>
    )
}

export default ApexChartCard