import {MathJax, MathJaxContext} from "better-react-mathjax";
import ReactHtmlParser from "react-html-parser";
import {useEffect, useState} from "react";
import {delay} from "../../../../utility/Utils";
import {Spinner} from "reactstrap";
import {PRIMARY_COLOR} from "../../constants/baseConstants";

const CustomMathjax = ({content}) => {
    const config = {
        loader: { load: ["[tex]/html"] },
        dynamic: true,
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"],
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ],
        }
    };
    const [statement, setStatement] = useState(<></>)

    const wrap = () => {
        setStatement(<div className={"text-center w-100"}><Spinner></Spinner></div>);
        (async () => {
            await delay(200)
            setStatement(<MathJaxContext version={3} config={config}><MathJax>
                {ReactHtmlParser(content)}
            </MathJax></MathJaxContext>)
        })();
    }

    useEffect(() => {
        wrap()
    }, [content])

    return statement
}

export default CustomMathjax