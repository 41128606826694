import axios from "axios";
import {API_URL} from "@src/app/common/constants/baseConstants";
import {Navigate, useNavigate} from "react-router-dom"
import {StatusCodes} from "http-status-codes";
import toast from 'react-hot-toast';
import {deleteCookie, getCookie} from "src/app/common/helpers/CookieHelper"

const TOKEN_EXPIRED_STATUS_CODE = 498;
const UNKNOWN_STATUS_CODE = 0;

const authAxios = axios.create({
    baseURL: API_URL,
    headers: {
        "Access-Control-Allow-Origin": 'https://quillplus.in'
    }
});

authAxios.interceptors.request.use((config) => {
    const token = getCookie('token');
    if (config.data === undefined) {
        config.data = {}
    }
    config.headers.device = 'web'
    if (token) {
        config.headers.Authorization = token;
    }
    return config
})

authAxios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    /** To handle rest of the commong server errors */
    switch (error?.response?.status) {
        case StatusCodes.INTERNAL_SERVER_ERROR:
            toast.error('Oops, something went wrong. Please try again later.')
            break;

        case StatusCodes.GATEWAY_TIMEOUT:
            toast.error('Oops, Looks like you will have to try again.')
            break;

        case StatusCodes.FORBIDDEN:
            toast.error(error?.response?.data?.message ?? 'Unauthorized Access.')
            break;

        case StatusCodes.UNAUTHORIZED:
            toast.error('Unauthorized Access.')
            break;

        case StatusCodes.SERVICE_UNAVAILABLE:
            return <Navigate to="/under-maintenance"/>

        case UNKNOWN_STATUS_CODE:
            toast.error('Oops, Looks like Something went wrong.')
            break;

        case TOKEN_EXPIRED_STATUS_CODE:
            deleteCookie('token')
            return <Navigate to="/login"/>

        default:
            return error.response;
    }
    return error.response
});

export default authAxios;


// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xOjg4OTkvYXBpL3YxL3VzZXJzL2dlbi10b2tlbi1mb3ItdXNlciIsImlhdCI6MTY2NjA4NDI1NSwiZXhwIjoxNjY2MDg3ODU1LCJuYmYiOjE2NjYwODQyNTUsImp0aSI6InNyNmdzbFNlUnNCQU5VUzEiLCJzdWIiOiIyMSIsInBydiI6ImZhMWJiZWI0ZjkwNjQ2M2ZjNjEyY2U0NzE5N2JmNzdlNjJkYjU0ZmYiLCJyb2xlIjpudWxsLCJpZCI6MjF9.RGyWduhhnB_7-qZuBulL4HWkM8sePvT_ZnLqNvb9TQE