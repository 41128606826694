// ** Icons Import
import {Heart} from "react-feather"
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <p className="clearfix mb-0">
            <span className="float-md-start d-block d-md-inline-block mt-25">
                COPYRIGHT © {new Date().getFullYear()}{" "}
                <a
                    href="https://lazybone.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Lazybone Innovations
                </a>
                <span className="d-none d-sm-inline-block">, All rights Reserved</span>
            </span>
            <span className="float-md-end d-none d-md-block">
                <NavLink target={"_blank"} to={"/privacy-policy"} className={"mx-1"}>
                    Privacy Policy
                </NavLink>
                {/*  <NavLink to={"/terms-and-conditions"} className={"mx-1"}>*/}
                {/*    Terms & Conditions*/}
                {/*</NavLink>*/}
            </span>
        </p>
    )
}

export default Footer
