import {Fragment} from "react";
import {Col, Row} from "reactstrap";
import CustomizedTestQuestionFilters from "./CustomizedTestQuestionFilters";
import CustomizedTestQuestionPillList from "./CustomizedTestQuestionPillList";

const CustomizedTestQuestionPanel = (props) => {
    let questions = props.testQuestions;
    if(props.isReadOnlyMode) {
        questions = questions.map((value, index) => {
            return {...value, sequence: (index + 1)}
        })
    }

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <CustomizedTestQuestionFilters
                        questionStates = {props.questionStates}
                        manageQuestionStateWiseFilter ={props.manageQuestionStateWiseFilter}
                        filterQuestionByStates = {props.filterQuestionByStates}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <CustomizedTestQuestionPillList
                        isReadOnlyMode = {props.isReadOnlyMode}
                        handleQuestionPillClick = {props.handleQuestionPillClick}
                        testQuestions ={questions}
                        testQuestionsTime = {props.testQuestionsTime}
                        filterQuestionByStates = {props.filterQuestionByStates}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

export default  CustomizedTestQuestionPanel