import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { NORMAL_END } from "../constants/ScheduleTestEndTypeConstants";

const ConfirmTestSubmissionModal = (props) => {

    const handleOnSubmitTest = () => {
        props.handleOnSubmitTest(NORMAL_END);
    }

    return (
        <Modal
            isOpen={props.showSubmitTestModal}
            className='modal-dialog-centered'
            modalClassName="modal-primary"
        >
            <ModalHeader>
                Submit Test
            </ModalHeader>
            <ModalBody>
                Are you sure want to submit test.?
            </ModalBody>
            <ModalFooter>
                <Button color={"secondary"} id="cancel_submit" onClick={props.handleCloseSubmitTestModal}>Cancel</Button>
                <Button color={"primary"} id="confirm_submit" onClick={handleOnSubmitTest}>Submit</Button>
            </ModalFooter>
        </Modal>
    )
}
export default ConfirmTestSubmissionModal