import {useEffect, useState} from "react";
import authAxios from "../../../../configs/authAxios";
import {
    DEFAULT_LOADER_MESSAGE, GET_PAST_TEST_ANALYTICS,
    GET_PAST_TEST_DETAILS,
    GET_PAST_TEST_INFO, GET_TEST_LEADERBOARD,
    PAST_TEST_FILTER_STATES,
    TEST_QUESTION_STATE_SKIPPED,
    V1_CUSTOMIZED_TEST_ENDPOINT
} from "../../constants/CustomizedTestConstants";
import {API_URL} from "../../../common/constants/baseConstants";
import {useNavigate, useParams} from "react-router-dom";
import {StatusCodes} from "http-status-codes";
import CustomizedTestBody from "./CustomizedTestBody";
import LoadingOverlay from "react-loading-overlay-ts";
import {reactLocalStorage} from "reactjs-localstorage";
import {CustomizedPastTestHeader} from "./CustomizedPastTestHeader";
import {CustomizedPastTestResultBody} from "./CustomizedPastTestResultBody";
import {Button, CardText, Col, Row, TabContent, TabPane} from "reactstrap";
import CustomizedPastTestAnalytics from "./CustomizedPastTestAnalytics";
import NoDataCard from "../../../practice-test/components/NoDataCard";
import SecondRankIcon from "../../../common/icons/rank/SecondRankIcon";
import ThirdRankIcon from "../../../common/icons/rank/ThirdRankIcon";
import FirstRankIcon from "../../../common/icons/rank/FirstRankIcon";
import FourthRankIcon from "../../../common/icons/rank/FourthRankIcon";
import FifthRankIcon from "../../../common/icons/rank/FifthRankIcon";
import {User, UserX} from "react-feather";

const CustomizedPastTest = () => {

    const [testDetails, setTestDetails] = useState({})
    const [testAnalytics, setTestAnalytics] = useState({})
    const [testLeaderboard, setTestLeaderboard] = useState({})
    const [isTestAnalyticsReady, setIsTestAnalyticsReady] = useState(false)
    const [isLeaderboardReady, setIsLeaderboardReady] = useState(false)
    const [leaderboardComponent, setLeaderboardComponent] = useState([])
    const [leaderboardReadyMessage, setLeaderboardReadyMessage] = useState("")
    const {testId} = useParams();
    const navigate = useNavigate();
    const isReadOnlyMode = true;
    const scheduleTestQuestionWiseStates = PAST_TEST_FILTER_STATES;
    const [questionFilteredStates, setQuestionFilteredStates] = useState([]);
    const [isTestLoaderActive, setIsTestLoaderActive] = useState(false)
    const [testLoaderMessage, setTestLoaderMessage] = useState(DEFAULT_LOADER_MESSAGE);
    const testDuration = testDetails.duration ?? 0
    const numQuestions = testDetails.num_questions
    const [testQuestions, setTestQuestions] = useState([]);
    const [activeQuestionSequence, setActiveQuestionSequence] = useState(0)
    const [activeQuestion, setActiveQuestion] = useState(testQuestions[activeQuestionSequence])
    const [perPageQuestion, setPerPageQuestion] = useState(reactLocalStorage.get('CUSTOMIZED_TEST_'+testId+'_PER_PAGE_QUESTION') ?? 5);
    const [testQuestionsTime, setTestQuestionsTime] = useState([]);
    const [allTestQuestionState, setAllTestQuestionState] = useState([]);
    const [isFetchingQuestions, setIsFetchingQuestions] = useState(false);
    const [activeTab, setActiveTab] = useState("answers")
    // Analytics State
    const [questionWiseAnalytics, setQuestionWiseAnalytics] = useState(null);
    const [timeWiseAnalytics, setTimeWiseAnalytics] = useState(null);
    const [averageTimePerQuestionAnalytics, setAverageTimePerQuestionAnalytics] = useState(null);
    const [chapterWiseAnalytics, setChapterWiseAnalytics] = useState(null);
    const [subjectWiseAnalytics, setSubjectWiseAnalytics] = useState(null);
    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }
    const getTestDetails = async() => {
        setIsTestLoaderActive(true);
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_PAST_TEST_INFO
        url = url.replace('#testId', testId)
        const response = await authAxios.get(url);
        if(response.status === StatusCodes.OK) {
            const newTestDetails = response?.data?.data
            setTestDetails(newTestDetails)
        } else if(response.status === StatusCodes.UNAUTHORIZED) {
            navigate('/customized-tests')
        } else {
            navigate('/customized-tests')
        }
        setIsTestLoaderActive(false);
    }

    const getTestAnalytics = async() => {
        setIsTestLoaderActive(true);
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_PAST_TEST_ANALYTICS
        url = url.replace('#testId', testId)
        const response = await authAxios.get(url);
        if(response.status === StatusCodes.OK) {
            const testAnalytics = response?.data?.data
            setTestAnalytics(testAnalytics)
            setIsTestAnalyticsReady(true)
        } else {
            setIsTestAnalyticsReady(false)
        }
        setIsTestLoaderActive(false);
    }

    const getTestLeaderboard = async() => {
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_TEST_LEADERBOARD
        url = url.replace('#testId', testId)
        const response = await authAxios.get(url);
        if(response.status === StatusCodes.OK) {
            const testLeaderboard = response?.data?.data
            setTestLeaderboard(testLeaderboard)
        } else if(response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            setLeaderboardReadyMessage(response?.data?.data)
        }
        setIsLeaderboardReady(true)
    }

    const getRankIcon = (rank) => {
        switch (rank) {
            case 1:
                return <FirstRankIcon />
                break;

            case 2:
                return <SecondRankIcon />
                break;

            case 3:
                return <ThirdRankIcon />
                break;

            default:
                return <span class={"user_rank"}>#{rank}</span>
                break;
        }
    }

    useEffect(() => {
        if(activeTab === 'analytics' && !isTestAnalyticsReady) {
            getTestAnalytics();
        } else if(activeTab === 'answers' && Object.keys(testDetails).length === 0) {
            getTestDetails();
            getTestLeaderboard();
        }
    }, [activeTab])

    useEffect(() => {
        let newTestQuestions = [];
        for(let i = 1; i <= testDetails.num_questions; i++) {
            newTestQuestions[i] = {
                sequence: i,
                state: TEST_QUESTION_STATE_SKIPPED,
                time_taken: 0
            }
        }
        setTestQuestions(newTestQuestions)
    }, [testDetails])

    const MISSED_COMPONENT = (<span className={'missed_test_indicator'}>M</span>)

    useEffect(() => {
        if(testLeaderboard !== null && testLeaderboard !== undefined && Object.keys(testLeaderboard).length > 0) {
            let newLeaderboardComponent = []
            let ranks = []
            const current = testLeaderboard?.currentRank?.data;

            for(let i = 0; i < Object.keys(testLeaderboard?.leaderboard?.data).length; i++) {
                const user = testLeaderboard.leaderboard.data[i];
                ranks.push(user.rank)
                newLeaderboardComponent.push(
                    <Col key={user.rank} md={12} className={`border border-1 test_leaderboard_row ${user.rank === current.rank ? 'bg-light-primary' : ''}`}>
                        <CardText className={"d-flex justify-content-between align-items-center"}>
                            <span>{getRankIcon(user.rank)} {user.name}</span>
                            <span>{user.percentile}</span>
                        </CardText>
                    </Col>
                )
            }

            if(current && !ranks.includes(current.rank)) {
                newLeaderboardComponent.push(
                    <Col key={current.rank} md={12} className={`border border-1 test_leaderboard_row bg-light-primary`}>
                        <CardText className={"d-flex justify-content-between align-items-center fw-bold"} style={{lineHeight: "2rem"}}>
                            <span>{current.was_missed ? MISSED_COMPONENT : getRankIcon(current.rank)} {current.name}</span>
                            <span>{current.was_missed ? "MISSED" : current.percentile}</span>
                        </CardText>
                    </Col>
                )
            }
            setLeaderboardComponent(newLeaderboardComponent);
        }
    }, [testLeaderboard])

    const processQuestionWiseAnalytics = (testAnalytics) => {
        let newQuestionWiseAnalytics = {};
        newQuestionWiseAnalytics.Correct = testAnalytics.num_correct
        newQuestionWiseAnalytics.Incorrect = testAnalytics.num_incorrect
        newQuestionWiseAnalytics.Skipped = testAnalytics.num_skipped
        setQuestionWiseAnalytics(newQuestionWiseAnalytics)
    }

    const processTimeWiseAnalytics = (testAnalytics) => {
        let newTimeWiseAnalytics = {};
        newTimeWiseAnalytics.Correct = (testAnalytics.avg_time_correct * testAnalytics.num_correct)
        newTimeWiseAnalytics.Incorrect = (testAnalytics.avg_time_incorrect * testAnalytics.num_incorrect)
        newTimeWiseAnalytics.Skipped = (testAnalytics.avg_time_skipped * testAnalytics.num_skipped)
        setTimeWiseAnalytics(newTimeWiseAnalytics)
    }

    const processAverageTimeWiseAnalytics = (testAnalytics) => {
        let newTimeWiseAnalytics = {};
        newTimeWiseAnalytics.Correct = (testAnalytics.avg_time_correct)
        newTimeWiseAnalytics.Incorrect = (testAnalytics.avg_time_incorrect)
        newTimeWiseAnalytics.Skipped = (testAnalytics.avg_time_skipped)
        setAverageTimePerQuestionAnalytics(newTimeWiseAnalytics)
    }

    const processSubjectWiseAnalysis = (subjectData) => {
        let newSubjectWiseAnalytics = {
            categories: [],
            data: {
                Correct: [],
                Incorrect: [],
                Skipped: []
            }
        };
        for(let i = 0; i < subjectData.length; i++) {
            const subject = subjectData[i];
            newSubjectWiseAnalytics.categories.push(subject.subject_name)
            newSubjectWiseAnalytics.data.Correct.push(subject.num_correct)
            newSubjectWiseAnalytics.data.Incorrect.push(subject.num_incorrect)
            newSubjectWiseAnalytics.data.Skipped.push(subject.num_skipped)
        }
        setSubjectWiseAnalytics(newSubjectWiseAnalytics)
    }

    const processChapterWiseAnalysis = (chapterData) => {
        let newChapterWiseAnalytics = {
            categories: [],
            data: {
                Correct: [],
                Incorrect: [],
                Skipped: []
            }
        };
        for(let i = 0; i < chapterData.length; i++) {
            const chapter = chapterData[i];
            newChapterWiseAnalytics.categories.push(chapter.chapter_name)
            newChapterWiseAnalytics.data.Correct.push(chapter.num_correct)
            newChapterWiseAnalytics.data.Incorrect.push(chapter.num_incorrect)
            newChapterWiseAnalytics.data.Skipped.push(chapter.num_skipped)
        }
        setChapterWiseAnalytics(newChapterWiseAnalytics)
    }

    useEffect(() => {
        if(Object.keys(testAnalytics).length > 0) {
            processQuestionWiseAnalytics(testAnalytics)
            processTimeWiseAnalytics(testAnalytics)
            processAverageTimeWiseAnalytics(testAnalytics)
            if(testAnalytics?.subjectInfo?.data) {
                processSubjectWiseAnalysis(testAnalytics.subjectInfo.data)
            }
            if(testAnalytics?.chapterInfo?.data) {
                processChapterWiseAnalysis(testAnalytics.chapterInfo.data)
            }
        }
    }, [testAnalytics])

    const manageQuestionStateWiseFilter = (state, operation) => {
        let newStateWiseFilter = questionFilteredStates.slice();
        if (operation > 0) {
            newStateWiseFilter.push(state);
        } else {
            const index = newStateWiseFilter.indexOf(state);

            if (index > -1) {
                newStateWiseFilter.splice(index, 1);
            }
        }
        setQuestionFilteredStates(newStateWiseFilter)
    }

    const updateQuestions = async (questions) => {
        if(questions !== null && questions !== undefined) {
            let indexedNewQuestions = [];
            questions.map(question => {
                indexedNewQuestions[question.sequence] = question;
            })
            let newQuestion = [];
            for(let i = 1; i <= numQuestions; i++) {
                newQuestion[i] = indexedNewQuestions[i] ? {...indexedNewQuestions[i]} : testQuestions[i];
            }
            setTestQuestions(newQuestion)
        }
    }

    const fetchQuestions =  async (pageNumber = 1) => {
        setIsFetchingQuestions(true);
        let url = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_PAST_TEST_DETAILS;
        url = url.replace('#testId', testId);
        const questionResponse = await authAxios.get(url + '?page='+pageNumber);
        if(questionResponse.status === StatusCodes.OK) {
            setPerPageQuestion(questionResponse?.data?.data?.per_page)
            setAllTestQuestionState(questionResponse?.data?.data?.testInfo?.data?.all_answers_info)
            return questionResponse?.data?.data?.testInfo?.data?.questions?.data
        }
        setIsFetchingQuestions(false);
    }

    const handleOnSequenceChange = async (sequence) => {
        if (sequence > 0) {
            let pageNum = Math.ceil(sequence / perPageQuestion)
            const newQuestions = await fetchQuestions(pageNum);
            await updateQuestions(newQuestions)
        }
    }

    const navigateToNextQuestion = async () => {
        if(activeQuestionSequence !== numQuestions) {
            const prevSequence = activeQuestionSequence
            setActiveQuestionSequence(parseInt(activeQuestionSequence) + 1)
        }
    }

    const navigateToPreviousQuestion = async () => {
        if(activeQuestionSequence > 1) {
            const prevSequence = activeQuestionSequence
            setActiveQuestionSequence(parseInt(activeQuestionSequence) - 1)
        }
    }

    const handleQuestionPillClick = async (sequence) => {
        const prevSequence = activeQuestionSequence
        setActiveQuestionSequence(sequence)
    }

    // useEffect hooks
    useEffect(() => {
        setActiveQuestionSequence(1);
    }, [])

    useEffect(() => {
        setActiveQuestion(testQuestions[activeQuestionSequence])
    }, [activeQuestionSequence])

    useEffect(() => {
        (async() => {
            if(activeQuestion === null || (activeQuestion !== undefined && (activeQuestion.id === null || activeQuestion.id === undefined))) {
                await handleOnSequenceChange(activeQuestionSequence);
            }
        })();
    }, [activeQuestion])

    useEffect(() => {
        setActiveQuestion(testQuestions[activeQuestionSequence])
        reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testId+'_QUESTIONS', testQuestions)
    }, [testQuestions]);

    useEffect(() => {
        reactLocalStorage.setObject('CUSTOMIZED_TEST_'+testId+'_QUESTIONS_TIME', testQuestionsTime)
    }, [testQuestionsTime])

    return (
        <LoadingOverlay
            active={isTestLoaderActive}
            spinner
            text={testLoaderMessage}
        >
            <CustomizedPastTestHeader
                testName = {testDetails.name}
            />
            <Row className="pt-md-2 pt-sm-0 pt-xs-0 px-md-5 px-1">
                <Col md={2}>
                    <div className="mt-md-5 mt-1">
                        <Row className="mb-2">
                            <Col md={12} sm={6} xs={6} className="my-1 text-center">
                                <Button id="show_answers_btn"
                                        onClick={() => {
                                            toggle('answers')
                                        }}
                                        color={activeTab === 'answers' ? 'primary' : 'secondary'}>Answers</Button>
                            </Col>
                            <Col md={12} sm={6} xs={6} className="my-1 text-center">
                                <Button id="show_analytics_btn"
                                    onClick={() => {
                                        toggle('analytics')
                                    }}
                                    color={activeTab === 'analytics' ? 'primary' : 'secondary'}>Analytics</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={10}>
                    <CustomizedPastTestResultBody
                        testDetails = {testDetails}
                        leaderboardComponent = {leaderboardComponent}
                        isLeaderboardReady = {isLeaderboardReady}
                        leaderboardReadyMessage = {leaderboardReadyMessage}
                    />
                    <TabContent className='py-50' activeTab={activeTab}>
                        <TabPane tabId='answers'>
                            <CustomizedTestBody
                                allTestQuestionState = {allTestQuestionState}
                                handleQuestionPillClick = {handleQuestionPillClick}
                                activeQuestion = {activeQuestion}
                                testQuestions = {testQuestions}
                                testQuestionsTime = {testQuestionsTime}
                                fetchQuestions = {fetchQuestions}
                                testDuration={testDuration}
                                totalQuestionsLength = {numQuestions}
                                handleNavigateToNextQuestion = {navigateToNextQuestion}
                                handleNavigateToPreviousQuestion = {navigateToPreviousQuestion}
                                isReadOnlyMode={isReadOnlyMode}
                                questionStates = {scheduleTestQuestionWiseStates}
                                questionFilteredStates = {questionFilteredStates}
                                manageQuestionStateWiseFilter = {manageQuestionStateWiseFilter}
                                isFetchingQuestions ={isFetchingQuestions}
                            />
                        </TabPane>
                        <TabPane tabId='analytics'>
                            {
                                isTestAnalyticsReady ?
                                    <CustomizedPastTestAnalytics
                                        testDetails = {testDetails}
                                        questionWiseAnalytics = {questionWiseAnalytics}
                                        timeWiseAnalytics = {timeWiseAnalytics}
                                        averageTimePerQuestionAnalytics = {averageTimePerQuestionAnalytics}
                                        subjectWiseAnalytics = {subjectWiseAnalytics}
                                        chapterWiseAnalytics = {chapterWiseAnalytics}
                                    />
                                    :
                                    <Row className={"p-1"}>
                                        <Col md={12}>
                                            <NoDataCard
                                                title ={"Analytics not ready yet."}
                                            />
                                        </Col>
                                    </Row>
                            }
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </LoadingOverlay>
    )
}

export default CustomizedPastTest