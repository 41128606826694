import {useEffect, useState} from "react";
import authAxios from "../../../configs/authAxios";
import {API_URL} from "../../common/constants/baseConstants";
import {GET_PAST_TEST, V1_CUSTOMIZED_TEST_ENDPOINT} from "../constants/CustomizedTestConstants";
import {StatusCodes} from "http-status-codes";
import {Button, Col, Row} from "reactstrap";
import CustomizedPastTestCard from "./CustomizedPastTestCard";
import {useNavigate} from "react-router-dom";
import {UseMixPanel} from "../../../UseMixPanel";
import {VIEWED_MISSED_CUSTOMIZED_TEST} from "../../common/constants/mixPanelEvents";

const CustomizedMissedTest = (props) => {
    const [currentStandardMetadataId, setCurrentStandardMetadataId] = useState(props.currentStandardMetaDataId)
    const [missedTestPageNumber, setMissedTestPageNumber] = useState(1);
    const [missedTestList, setMissedTestList] = useState([]);
    const [missedTests, setMissedTests] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState("");
    const [fetchingMissedTests, setFetchingMissedTests] = useState(false);
    const updateMissedTest = (tests) => {
        setMissedTests(missedTests.concat(tests));
    }

    const navigate = useNavigate()
    const useMixpanel = UseMixPanel();
    const navigateToPastTest = (testId) => {
        useMixpanel.track(VIEWED_MISSED_CUSTOMIZED_TEST, {"testId" : testId})
        navigate(`/customized-tests/${testId}/result`)
    }

    const getMissedTest = async (url) => {
        if(currentStandardMetadataId) {
            setFetchingMissedTests(true);
            const missedTestResponse = await authAxios.get(url)
            if(missedTestResponse.status === StatusCodes.OK) {
                updateMissedTest(missedTestResponse?.data?.data?.tests?.data)
                setNextPageUrl(missedTestResponse?.data?.data?.next_page)
            }
            setFetchingMissedTests(false)
        }
    }

    const handleOnClickLoadMore = async () => {
        if(nextPageUrl) {
            await getMissedTest(nextPageUrl+'&state=4'+'&standard_metadata_id='+currentStandardMetadataId);
        }
    }

    useEffect(() => {
        let newMissedTestList = [];
        if(missedTests !== null && missedTests !== undefined && Object.keys(missedTests).length > 0) {
            for(let i = 0; i < Object.keys(missedTests).length; i++) {
                const test = missedTests[i];
                newMissedTestList.push(
                    <Col md={12} key={test.id}>
                        <CustomizedPastTestCard
                            key={test.id}
                            id={test.id}
                            name={test.name}
                            subjects={test.subjects}
                            total_marks={test.total_marks}
                            marks_obtained={test.marks_obtained}
                            onClickFunctionHandler={navigateToPastTest}
                            showResults={test.show_results}
                            reason={test.reason}
                        />
                    </Col>
                )
            }
        }
        setMissedTestList(newMissedTestList)
    }, [missedTests])

    useEffect(() => {
        (async () => {
            const getMissedTestUrl = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_PAST_TEST + '?state=4&page=' + missedTestPageNumber + '&standard_metadata_id='+currentStandardMetadataId;
            await getMissedTest(getMissedTestUrl);
        })();
    }, [])

    return (
        <>
            <Row className={"mt-2"}>
                <Col md={12}>
                    <h4>Missed tests</h4>
                </Col>
            </Row>
            <Row className={"mt-1 customized_test_list mini_scroll_bar"}>
                {missedTestList}
                {missedTestList.length === 0 &&
                    <Col md={12}>
                        <p>Great You haven't missed a test 😃</p>
                    </Col>
                }
                {nextPageUrl &&
                    <Col md={12} className={"text-center"}>
                        <Button onClick={handleOnClickLoadMore} disabled={fetchingMissedTests} color={"primary"}>
                            { fetchingMissedTests ?
                                "Loading..."
                                :
                                "Load More"
                            }
                        </Button>
                    </Col>
                }
            </Row>
        </>
    )
}

export default  CustomizedMissedTest