import {Button, Card, CardBody, CardHeader, Progress} from "reactstrap";
import {useEffect, useState} from "react";
import {dateDiffInSeconds, secondsToHHMMSS} from "../../../../utility/Utils";
import {Clock} from "react-feather";
import {TIMEDOUT_END} from "../../constants/CustomizedTestConstants";

const CustomizedTestNav = ({
    testName,
    handleOnSubmitTest,
    testStartTime,
    testDuration,
    submitTest
}) => {
    const [time, setTime] = useState(0)
    const TIME_SPENT_INTERVAL = 1000
    let endTime = new Date(testStartTime);
    endTime = new Date(endTime.setSeconds(endTime.getSeconds() + testDuration))

    const [timeElapsedSinceTestStart, setTimeElapsedSinceTestStart] = useState(dateDiffInSeconds(endTime, new Date(testStartTime)));
    const [timeRemaining, setTimeRemaining] = useState(0);

    useEffect(() => {
        if (timeRemaining < 0) {
            submitTest(TIMEDOUT_END)
        }
    }, [timeRemaining])

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((time) => time + TIME_SPENT_INTERVAL)
        }, TIME_SPENT_INTERVAL)
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        let endTime = new Date(testStartTime);
        endTime = new Date(endTime.setSeconds(endTime.getSeconds() + testDuration))
        setTimeElapsedSinceTestStart(dateDiffInSeconds(new Date(), endTime));
        const timeRemaining = endTime - new Date()
        setTimeRemaining(timeRemaining);
    }, [time])


    return (
        <>
            <Card className="mb-0">
                <CardHeader className="card-developer-meetup bg-dark no-border">
                    <div className="meetup-header d-flex align-items-center mb-0">
                        <div className="my-auto">
                            <h4 className="mb-25 text-white">{testName}</h4>
                        </div>
                    </div>
                    <div className="meetup-header d-flex align-items-center mb-0">
                        <div className="mx-1">
                            <Button className="btn btn-whtie" color='light' id="submit_test" onClick={handleOnSubmitTest}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </CardHeader>
            </Card>
            <Card className={"mx-1"}>
                <CardBody>
                    <span className="">
                        <div className="mx-3 d-flex justify-content-center align-items-center">
                            <Progress className="flex-grow-1 mx-1" max={testDuration * 1000}
                                      value={timeRemaining}></Progress>
                            <div>
                                { timeElapsedSinceTestStart > 0 &&
                                    <><Clock></Clock> {secondsToHHMMSS(timeElapsedSinceTestStart)} </>}
                            </div>
                        </div>
                    </span>
                </CardBody>
            </Card>
        </>
    )
}

export default CustomizedTestNav