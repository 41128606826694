import CustomMathjax from "../../common/components/mathjax/CustomMathjax";

const CustomizedMCQOption = (props) => {
    const option = props.option
    const optionChangeHandler = () => {
        props.onClickHandler(option.id)
    }

    const getOptionBorderClass = () => {
        if (props.isSelected && props.isCorrect !== undefined) {
            if (props.isCorrect === 1) {
                return "correct-answer"
            } else {
                return "incorrect-answer"
            }
        } else if(props.isSelected) {
            return "selected-answer"
        }
        if (props.isCorrect === 1) {
            return "correct-answer"
        }

        return ""
    }

    const renderOptionStatus = () => {
        if (props.isSelected && props.isCorrect !== null) {
            if (props.isCorrect === 0) {
                return <span className="float-right text-danger">Incorrect</span>
            }
        }
        if (props.isCorrect === 1) {
            return <span className="float-right text-success">Correct</span>
        }
        return <></>
    }

    return (
        <div className="d-flex w-100">
            <div className="custom-options-checkable mb-2 w-100">
                <input type="radio" data-id={option?.id} id={`option${option?.id}`} name="authType" className="custom-option-item-check"
                       onChange={optionChangeHandler}
                       checked={props.isSelected}
                />
                <label htmlFor={`option${option?.id}`}
                       className={`custom-question-option d-flex align-items-center flex-column flex-sm-row px-1 py-2 ${getOptionBorderClass()}`}>
                    <span className="mathjax_component mcq-option d-block m-0 h5 w-100">
                        <CustomMathjax className="custom_mathjax"
                                       content={option?.value}
                        ></CustomMathjax>
                    </span>
                    {renderOptionStatus()}
                </label>
            </div>
        </div>
    );
}

export default CustomizedMCQOption;