import { createSlice } from '@reduxjs/toolkit'
import { getCookie } from '@src/app/common/helpers/CookieHelper';

const initialState = {
    subject_id: getCookie('schedule_subject_id') ?? null,
    subject_name: getCookie('schedule_subject_name') ?? null,
}

export const scheduleTestSlice = createSlice({
    name: 'scheduleTest',
    initialState,
    reducers: {
		setSubjectId: (state, action) => {
            state.subject_id = action.payload
        },
        setSubjectName: (state, action) => {
            state.subject_name = action.payload
        },
    }
});

export const { 
    setSubjectId,
    setSubjectName
} = scheduleTestSlice.actions

export default scheduleTestSlice.reducer;