import { createSlice } from '@reduxjs/toolkit'
import { reactLocalStorage } from 'reactjs-localstorage';

const initialState = reactLocalStorage.getObject('practiceTestAnalytics') ?? {
    testId : null,
    testName : null,
    totalMarks: 0,
    totalScore: 0,
    analytics : {
        accuracy: 0,
        averageTimePerQuestion: 0,
        questionWiseBreakdown : {
            CORRECT: 0,
            INCORRECT: 0,
            SKIPPED: 0,
        },
        questionCorrectnessWiseTime : {
            CORRECT: 0,
            INCORRECT: 0,
            SKIPPED: 0,
        },
        averageTimePerQuestionState : {
            CORRECT: 0,
            INCORRECT: 0,
            SKIPPED: 0,
        },
        chapterWiseCorrectness : []
    }
}

export const practiceTestAnalyticsSlice = createSlice({
    name: 'practiceTestAnalytics',
    initialState,
    reducers: {
        setTestId: (state, action) => {
            state.testId = action.payload
        },
        setTestName: (state, action) => {
            state.testName = action.payload
        },
        setTotalMarks: (state, action) => {
            state.totalMarks = action.payload
        },
        setTotalScore: (state, action) => {
            state.totalScore = action.payload
        },
        setAnalytics: (state, action) => {
            state.analytics = action.payload
        },
    }
});

export const { 
    setTestId,
    setTestName,
    setTotalMarks,
    setTotalScore,
    setAnalytics
} = practiceTestAnalyticsSlice.actions

export default practiceTestAnalyticsSlice.reducer;