import CustomizedQuestion from "./CustomizedQuestion";
import {Col, Row} from "reactstrap";
import CustomizedQuestionNavigator from "./CustomizedQuestionNavigator";
import LoadingOverlay from "react-loading-overlay-ts";

const CustomizedQuestionBody = (props) => {
    if(props.question !== null && props.question !== undefined && props.question.id !== undefined && props.question.id !== null) {
        return (
            <>
                <Row>
                    <Col md={12}>
                        <CustomizedQuestion
                            question = {props.question}
                            handleOnUpdateQuestionMCQAnswer = {props.handleOnUpdateQuestionMCQAnswer}
                            isReadOnlyMode = {props.isReadOnlyMode}
                            updateQuestionReportedState = {props.updateQuestionReportedState}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <CustomizedQuestionNavigator
                            isReadOnlyMode = {props.isReadOnlyMode}
                            activeQuestion = {props.question}
                            totalQuestionsLength = {props.totalQuestionsLength}
                            handleNavigateToNextQuestion = {props.handleNavigateToNextQuestion}
                            handleNavigateToPreviousQuestion = {props.handleNavigateToPreviousQuestion}
                            handleMarkQuestionForReview = {props.handleMarkQuestionForReview}
                            handleClearQuestionAnswer = {props.handleClearQuestionAnswer}
                            handleOnSubmitTest = {props.handleOnSubmitTest}
                            isReviewed={props.question.was_reviewed}
                        />
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <LoadingOverlay
                active={props.isFetchingQuestions}
                spinner
                text="Please wait while we fetch Question"
            />
        )
    }
}

export default  CustomizedQuestionBody