import { createSlice } from '@reduxjs/toolkit'
import { getCookie } from '@src/app/common/helpers/CookieHelper';

const initialState = {
    subject_id: getCookie('practice_subject_id') ?? null,
    subject_name: getCookie('practice_subject_name') ?? null,
}

export const practiceTestSlice = createSlice({
    name: 'practiceTest',
    initialState,
    reducers: {
        setSubjectId: (state, action) => {
            state.subject_id = action.payload
        },
        setSubjectName: (state, action) => {
            state.subject_name = action.payload
        },
    }
});

export const {
    setSubjectId,
    setSubjectName
} = practiceTestSlice.actions

export default practiceTestSlice.reducer;