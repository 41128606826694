import {Card, CardBody, Col, Row} from "reactstrap";
import {Calendar, Clock, Target} from "react-feather";

const CustomizedPastTestCard = ({
    id,
    name,
    chapters,
    subjects,
    total_marks,
    marks_obtained,
    onClickFunctionHandler,
    showResults,
    reason
}) => {

    const handleOnClick = () => {
        if(showResults) {
           onClickFunctionHandler(id)
        }
    }

    return(
        <Card onClick={handleOnClick}>
            <CardBody className="list-card cursor-pointer">
                <div className="transaction-item">
                    <div className="d-flex bd-highlight">
                        <h4 className="text-primary transaction-title">{name}</h4>
                    </div>
                    <Row>
                        <Col md={12}>
                            <h5>Subjects: {subjects}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h6 className="text-dark">
                                {showResults &&
                                    <span>
                                        <Target></Target> {marks_obtained} / {total_marks}
                                    </span>
                                }
                                {reason &&
                                    <span>
                                        <Clock></Clock> {reason}
                                    </span>
                                }
                            </h6>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
}

export default  CustomizedPastTestCard