import {Card, CardBody, CardTitle, Collapse} from "reactstrap";
import {ChevronDown, ChevronUp} from "react-feather";
import {useEffect, useState} from "react";
import {getCustomizedPastTestQuestionClass, getCustomizedQuestionClass} from "../../../../utility/Utils";

const CustomizedTestQuestionPillList = (props) => {
    const questions = props.testQuestions
    const testQuestionsTime = props.testQuestionsTime
    const [isOpen, setIsOpen] = useState(true)
    const filterQuestionByStates = props.filterQuestionByStates
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const getQuestionColor = (question, time) => {
        if(props.isReadOnlyMode) {
            return getCustomizedPastTestQuestionClass(question.state)
        } else {
            return getCustomizedQuestionClass(question, time)
        }
    }

    const questionNavigatorClickHandler = (evt) => {
        props.handleQuestionPillClick(evt.target.getAttribute('data-sequence'))
    }

    let QuestionButtonComponents = []

    if (questions !== null && questions !== undefined && Object.keys(questions).length > 0) {
        QuestionButtonComponents = questions.map((question) => {

            if(question !== null && question !== undefined)
            {
                const colorClass = getQuestionColor(question, testQuestionsTime[question.sequence]);
                if(question !== undefined && question !== null && ( filterQuestionByStates.length === 0 || (filterQuestionByStates.length > 0 && filterQuestionByStates.includes(question?.state?.toString())))) {
                    return (
                        <span style={{maxWidth: "2.5rem"}} data-id={question?.id} key={question.sequence} className="btn-icon number-btn align-items-center my-1">
                            <div
                                data-id={question?.id}
                                className={`switcher-number ${colorClass}`}
                                onClick={questionNavigatorClickHandler}
                                data-sequence={question.sequence}
                            >
                                {question?.sequence}
                            </div>
                        </span>
                    )
                }
            }
        })
    }

    return (
        <Card>
            <CardTitle className={"px-2 my-1 mb-1"} tag="h4">Question Number
                <span onClick={toggle}
                      className="toggle-collapse cursor-pointer d-md-none float-end rounded background-bg">
                    {
                        !isOpen &&
                        <ChevronDown></ChevronDown>
                    }
                    {
                        isOpen &&
                        <ChevronUp></ChevronUp>
                    }
                </span>
            </CardTitle>
            <Collapse isOpen={isOpen}>
                <CardBody className={"p-0"}>
                    <div className={"question_navigator mini_scroll_bar"}>
                        <div className="question_pill_container">
                            {QuestionButtonComponents}
                        </div>
                    </div>
                </CardBody>
            </Collapse>

        </Card>
    )
}

export default  CustomizedTestQuestionPillList