import {Button, Card, CardBody, Col, Input, Label, Row} from "reactstrap";
import {ChevronLeft, ChevronRight, Save} from "react-feather";
import {useState} from "react";

const CustomizedQuestionNavigator = (props) => {
    const question = props.activeQuestion
    const totalQuestionsLength = props.totalQuestionsLength
    const showMarkForReview = !!props.handleMarkQuestionForReview;
    const showClearAnswer = !!props.handleClearQuestionAnswer;
    const [reviewValue, setReviewValue] = useState(1)
    // const [isMarkedForReview, setIsMarkedForReview] = useState(question.was_reviewed)
    const isMarkedForReview = props.isReviewed;
    const onForReviewChangeHandler = () => {
        if(showMarkForReview) {
            props.handleMarkQuestionForReview(question.id, (!!!question.was_reviewed))
        }
    }

    const clearQuestionResponseHandler = () => {
        if(showClearAnswer) {
            props.handleClearQuestionAnswer(question.id)
        }
    }

    const nextQuestionHandler = () => {
        props.handleNavigateToNextQuestion()
    }

    const prevQuestionHandler = () => {
        props.handleNavigateToPreviousQuestion()
    }

    return (
        <Row>
            <Col lg="12" sm="12">
                <Card>
                    <CardBody className="">
                        <Row>
                            <Col md={6} sm={12} xs={12} className={"d-flex"}>
                                { showMarkForReview &&
                                    <Label className="mx-1 my-1 btn bg-light-primary" color='gradient-primary'>
                                        <div className='form-check form-check-primary'>
                                            <Input type='checkbox' id='primary-checkbox'
                                                   checked={isMarkedForReview}
                                                   value={reviewValue}
                                                   onChange={onForReviewChangeHandler}
                                            />
                                            <Label className='form-check-label' for='primary-checkbox'
                                                   style={{verticalAlign: 'sub'}}>
                                                Review Later
                                            </Label>
                                        </div>
                                    </Label>
                                }
                                {showClearAnswer &&
                                    <Button className="btn mx-1 my-1 bg-light-primary" color='gradient-primary'
                                            onClick={clearQuestionResponseHandler}>
                                        Clear Response
                                    </Button>
                                }
                            </Col>
                            <Col md={6} sm={12} xs={12}
                                 className={"d-flex justify-content-between justify-content-md-end"}>
                                {question.sequence > 1 &&
                                    <Button id="nav_previous" onClick={prevQuestionHandler} className="btn mx-1 my-1"
                                            color='primary'><ChevronLeft></ChevronLeft> Prev</Button>}

                                {question.sequence < (totalQuestionsLength) &&
                                    <Button id="nav_next" onClick={nextQuestionHandler} className="btn mx-1 my-1"
                                            color='primary'>Next <ChevronRight></ChevronRight></Button>}

                                {(question.sequence === totalQuestionsLength && !props.isReadOnlyMode) &&
                                    <Button id="submit_test" onClick={props.handleOnSubmitTest} className="btn mx-1 my-1"
                                            color='primary'>Submit <Save></Save></Button>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default  CustomizedQuestionNavigator