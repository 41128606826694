import {Link} from "react-router-dom";
import {useSkin} from "src/utility/hooks/useSkin";
import {DARK_THEME, DEFAULT_DARK_LOGO, DEFAULT_LIGHT_LOGO} from "@src/app/common/constants/baseConstants";
import {useSelector} from "react-redux";
import {getCookie} from "../../helpers/CookieHelper";

const BrandLogo = (props) => {
    const {skin} = useSkin()
    const lightThemeLogoSource = (skin === DARK_THEME || props.FORCE_THEME === DARK_THEME) ? DEFAULT_DARK_LOGO : DEFAULT_LIGHT_LOGO;
    const whiteLabelLogo = getCookie('whitelabel_logo');
    const className = `navbar-brand brand logo ${props.className}`
    const isReloadNeeded = props.reload
    const logoComponent = (
        <span className="brand-logo">
            <img src={whiteLabelLogo ?? lightThemeLogoSource} className="whitelabel_logo mx-auto"></img>
        </span>
    )
    return (
        isReloadNeeded ?
            <a href={"/"}>
                {logoComponent}
            </a>
            :
            <Link className={className} to="/home">
                {logoComponent}
            </Link>
    )
}

export default BrandLogo;