import {Play, PlusSquare} from "react-feather";
import {Card, CardBody} from "reactstrap";
import {PRIMARY_COLOR} from "../constants/baseConstants";

const SmallListCard = ({
                           primaryId,
                           secondaryId,
                           icon,
                           iconBackgroundColor,
                           title,
                           subtitle,
                           info,
                           onClickFunction,
                           onClickIcon,
                           functionName
                       }) => {

    const navigateToScheduleSubject = () => {
        onClickFunction(primaryId);
    }

    const getTestDetails = () => {
        onClickFunction(primaryId, secondaryId, (title + " - " + subtitle))
    }

    const getPastTestDetails = () => {
        onClickFunction(primaryId, secondaryId, title)
    }

    const resumeTest = () => {
        onClickFunction(primaryId, secondaryId)
    }

    const handleOnClick = (evt) => {
        switch (functionName) {
            case "resumeTest":
                resumeTest()
                break;
            case "getTestDetails":
                getTestDetails()
                break;
            case "navigateToScheduleSubject":
                navigateToScheduleSubject()
                break;
            case "getPastTestDetail":
                getPastTestDetails()
                break;
            default:
                break;
        }
    }

    return (
        <Card>
            <CardBody className="list-card cursor-pointer" onClick={handleOnClick} data-primary-id={primaryId}
                      data-secondary-id={secondaryId}>
                <div className="transaction-item">
                    <div className="d-flex bd-highlight">
                        <div className="w-75 bd-highlight">
                            <div className="d-inline-flex">
                                {icon &&
                                    <div className="p-1 rounded list-icon-bg"
                                         style={{backgroundColor: iconBackgroundColor}}>
                                    <span className="">
                                        <img className="filterit" height="100%" width="32px" src={icon}></img>
                                    </span>
                                    </div>}
                                <div className="p-1">
                                    <h6 className="transaction-title">{title}</h6>
                                    <small className="card-sub-title">{subtitle}</small>
                                </div>
                            </div>

                        </div>
                        <div className="flex-shrink-1 bd-highlight mt-1">
                            <div className="align-items-center">
                                <small className="card-sub-title">{info}</small>
                                {onClickIcon &&
                                    <p>
                                        {onClickIcon}
                                    </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default SmallListCard;