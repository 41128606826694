import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showApplyBatchCodeModal: false,
}

export const applyBatchCodeSlice = createSlice({
    name: 'applyBatchCode',
    initialState,
    reducers: {
        setShowApplyBatchCodeModal: (state, action) => {
            state.showApplyBatchCodeModal = action.payload
        },
    }
});

export const {
    setShowApplyBatchCodeModal
} = applyBatchCodeSlice.actions

export default applyBatchCodeSlice.reducer;