import {Card, CardBody, Col, Row} from "reactstrap";
import {Calendar, Clock} from "react-feather";
import {getReadableDate, getReadableMinutesFromSeconds} from "../../../utility/Utils";
import {PRIMARY_COLOR} from "../../common/constants/baseConstants";

const CustomizedUpcomingTestCard = ({
    id,
    name,
    chapters,
    subjects,
    scheduled_at,
    allowed_till,
    duration_in_seconds,
    onClickFunctionHandler
}) => {
    const handleOnClick = () => {
        onClickFunctionHandler(id)
    }

    const formattedChaptersName = chapters.split(',').length > 1 ? 'Multiple Topics' : chapters
    return(
        <Card onClick={handleOnClick}>
            <CardBody className="list-card cursor-pointer">
                <div className="transaction-item">
                    <Row className="mb-1">
                        <Col md={10}>
                            <h6 className={"text-primary"}>Chapters: {formattedChaptersName}</h6>
                        </Col>
                        <Col md={2}>
                            <Clock size={16}></Clock> {getReadableMinutesFromSeconds(duration_in_seconds)}
                        </Col>
                    </Row>
                    <div className="bd-highlight">
                        <Row>
                            <Col md={12}>
                                <h4 className="transaction-title w-100">
                                    {name}
                                </h4>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={12}>
                            <h5>Subjects: {subjects}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h6 className="text-dark">
                                <span>
                                    <Calendar></Calendar> {getReadableDate(scheduled_at)} - {getReadableDate(allowed_till)}
                                </span>
                            </h6>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
}

export default  CustomizedUpcomingTestCard