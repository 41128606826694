export const API_URL = process.env.REACT_APP_URL
// https://quillplus.in
// https://staging.quillplus.in
// http://127.0.0.1:8899
// https://institutedemo.quillplus.in

export const DEFAULT_API_URL_PREFIX = '/api/v1/'

export const V2_API_URL_PREFIX = '/api/v2/'

const GET_USER_INFO_ENDPOINT = 'users/user'

export const WEB_DEVICE_TYPE = 'web'

export const GET_USER_INFO_URL = `${API_URL}${DEFAULT_API_URL_PREFIX}${GET_USER_INFO_ENDPOINT}`

export const DEFAULT_DARK_LOGO = require(`@src/assets/images/logo/quill-plus.png`).default

export const DEFAULT_LIGHT_LOGO = require(`@src/assets/images/logo/quill-plus.png`).default

export const DARK_THEME = 'dark'

export const LIGHT_THEME = 'light'

export const PRIMARY_COLOR = "#E54D38"

export const USER_LOGGED_IN_ACTION = 1

export const USER_LOGGED_OUT_ACTION = 2

export const DEFAULT_SUBJECT_ICON = 'https://staging.quillplus.in/assets/media/web-app/book.gif'

export const DEFAULT_QUESTION_ICON = 'https://staging.quillplus.in/assets/media/web-app/questions.gif'

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN

//PERSONAL
// export const FIREBASE_VAPIKEY = "BCL7LJJRIGAgVsSQKkSAV5ZLT1kZ4ly74on4dUWNscoKakdl5KqzDFyLcbS7saHlgfiG3i7g3PYmJ7mY0UovBvo"

//QUILL PLUS
export const FIREBASE_VAPIKEY = "BAVFvBAdrIGM-QybMrtgu2QEHooAlyNkr31_HrJeCsLTQ9wbTx_1YyQMbs988tV9QPboTrWjCKROmi6GzJG395w"

export const TOKEN_EXPIRED_STATUS_CODE = 498

export const MCQ_QUESTION_TYPE = 1
export const NVT_QUESTION_TYPE = 2