// ** React Imports
import { Suspense } from "react"
import { Navigate } from "react-router-dom"
import BlankLayout from "@layouts/BlankLayout"

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils"
import { useState } from "react"
import DownloadMobileApp from "src/views/DownloadMobileApp"
import { useSelector } from "react-redux"

const PublicRoute = ({ children, route }) => {
  if (route) {
    const user = getUserData()
    const store = useSelector((state) => state)
    const userStore = store.user
    const restrictedRoute = route.meta && route.meta.restricted

    if (user && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser(user.role)} />
    }
  }
  return <Suspense fallback={null}>{children}</Suspense>
}

export default PublicRoute
