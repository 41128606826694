// ** Dropdowns Imports
import {GET_USER_INFO_URL} from "@src/app/common/constants/baseConstants"
import {
    setId,
    setEmail,
    setName,
    setPhone,
    setIsRegistered,
    setRole,
    setAcademicInfo,
    setCurrentWhiteLabel,
    setImage,
    setIsPracticeAllowed,
    setIsScheduledAllowed,
    setIsCustomizedAllowed,
    setFcmToken,
} from "@src/app/auth/reducers/states/userSlice";
import {useState} from "react";
import {useEffect} from "react";
import authAxios from "src/configs/authAxios"
import UserDropdown from "./UserDropdown"
import {useDispatch, useSelector} from "react-redux";
import UserAcademicInfoDropDown from "./UserAcademicInfoDropDown";
import {getCookie} from "@src/app/common/helpers/CookieHelper";
import {toQueryString} from "@src/utility/Utils";
import {Bell} from "react-feather";
import {
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
    UncontrolledButtonDropdown
} from "reactstrap";
import {
    API_URL,
    DEFAULT_API_URL_PREFIX, FIREBASE_VAPIKEY
} from "../../../../app/common/constants/baseConstants";
import NotificationDropDownItem from "./notifications/NotificationDropDownItem";
import {useNavigate} from "react-router-dom";
import {StatusCodes} from "http-status-codes";
import toast from "react-hot-toast";
import {reactLocalStorage} from "reactjs-localstorage";
import {AddMinutesToDate} from "../../../../utility/Utils";
import {getMessaging, getToken} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "../../../../app/firebase/firebase";

const NavbarUser = () => {
    const store = useSelector((state) => state)
    const userStore = store.user
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    const [userData, setUserData] = useState({});
    const token = getCookie('token');
    const navigate = useNavigate()
    const userLogURL = API_URL + DEFAULT_API_URL_PREFIX + 'users/store-user-log'
    const sendUserLog = async (action) => {
        const postObj = {
            status: action
        }
        const response = await authAxios.post(userLogURL, postObj)

        if (response?.status == StatusCodes.CREATED) {
            const newSendLogAt = AddMinutesToDate(new Date(), 2);
            reactLocalStorage.set('set_send_log_at', newSendLogAt)
        }
    }

    useEffect(() => {
        if (reactLocalStorage.get('notification_needed')) {
            (async () => {
                const notificationResp = await getUserNotifications()
                const userNotifications = notificationResp?.data?.data;
                updateUserNotifications(userNotifications)
            })();
            reactLocalStorage.remove('notification_needed')
        }
    })

    useEffect(() => {
        const interval = setInterval(async () => {
            const sendLogAt = reactLocalStorage.get('set_send_log_at')
            let action = 1
            if (sendLogAt != undefined) {
                if (new Date(sendLogAt) - new Date() < (-29999)) {
                    return await sendUserLog(action)
                } else if (new Date(sendLogAt) < new Date()) {
                    action = 2
                    return await sendUserLog(action)
                }
            } else {
                return await sendUserLog(action)
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [])

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }
    const dispatch = useDispatch()
    const [notificationList, setNotificationList] = useState([])
    const [unreadNotifications, setUnreadNotifications] = useState(0)
    const getUserInfo = async () => {
        const response = await authAxios.get(GET_USER_INFO_URL + toQueryString({device: 'web'}))
        if (!response.status || response.status != 200) {
            toast.error('Token expired, please login again.');
            navigate('login');
        }
        return response
    }

    const USER_NOTIFICATION_URL = API_URL + DEFAULT_API_URL_PREFIX + 'users/notifications/card'
    const getUserNotifications = async () => {
        const notificationResponse = await authAxios.get(USER_NOTIFICATION_URL)
        reactLocalStorage.remove('notification_needed')
        return notificationResponse
    }

    const updateHasPractice = (value) => {
        dispatch(setIsPracticeAllowed(value))
    }

    const updateHasScheduled = (value) => {
        dispatch(setIsScheduledAllowed(value))
    }

    const updateHasCustomized = (value) => {
        dispatch(setIsCustomizedAllowed(value))
    }

    const closeDropdown = async () => {
        await setDropdownOpen(false)
    }

    const navigateToNotification = async () => {
        await closeDropdown()
        navigate('/notification')
    }

    const updateUserNotifications = (userNotifications) => {
        if (userNotifications) {
            let newUnreadNotification = 0
            const newNotificationList = userNotifications.map(notification => {
                if (!notification?.extra_args?.read_at) {
                    newUnreadNotification++
                }
                return <NotificationDropDownItem
                    key={notification.id}
                    id={notification.id}
                    title={notification?.card_data?.title}
                    subTitle={notification?.card_data?.description}
                    icon={notification?.card_data?.icon}
                    sentAt={notification?.extra_args?.sent_at}
                    isRead={notification?.extra_args?.read_at}
                    iconColor={notification?.card_styles?.color}
                    handleOnClick={closeDropdown}
                />
            })
            setUnreadNotifications(newUnreadNotification)
            setNotificationList(newNotificationList)
        }
    }

    const updateUserFCMTokenURL = API_URL + DEFAULT_API_URL_PREFIX + 'users/update-web-token'
    const updateUserFCMToken = (token) => {
        if (token) {
            dispatch(setFcmToken(token))
            const postObj = {
                device: 'web',
                web_fcm_token: token
            }
            authAxios.put(updateUserFCMTokenURL, postObj);
        }
    }

    useEffect(() => {
        getToken(messaging, {vapidKey: FIREBASE_VAPIKEY}).then((currentToken) => {
            if (currentToken) {
                const fcmToken = getCookie('fcm_token');
                if (fcmToken != currentToken) {
                    updateUserFCMToken(currentToken)
                }
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
        if (token) {
            (async () => {
                const response = await getUserInfo()
                if (response?.data?.data != undefined) {
                    setUserData(response?.data?.data)
                }
                const notificationResp = await getUserNotifications()
                const userNotifications = notificationResp?.data?.data;
                updateUserNotifications(userNotifications)
            })();
        }
    }, []);

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            dispatch(setId(userData.id))
            dispatch(setEmail(userData.email))
            dispatch(setName(userData.name))
            dispatch(setPhone(userData.phone))
            dispatch(setIsRegistered(userData.is_registered))
            dispatch(setRole(userData.role))
            dispatch(setAcademicInfo(userData.academicInfo))
            dispatch(setImage(userData.image))
            dispatch(setCurrentWhiteLabel(userData.currentWhiteLabel))
            if (!userData.is_registered) {
                toast.error("Please complete the Registration process.")
                navigate('/register')
            }
        }
    }, [userData])

    return (
        <ul className="nav navbar-nav align-items-center ms-auto">
            <NavItem className="dropdown-notification dropdown">
				<span aria-haspopup="true" className="nav-link" aria-expanded="false">
					<ButtonDropdown tag={"span"} isOpen={dropdownOpen} toggle={toggleDropdown}>
						<DropdownToggle color='flat'>
							<Bell></Bell>
                            {
                                unreadNotifications > 0 &&
                                <span className="badge-up badge bg-primary rounded-pill"
                                      style={{marginTop: "0.9rem", marginRight: "1.1rem", paddingTop: "0.5rem"}}
                                >{unreadNotifications}</span>
                            }
						</DropdownToggle>
						<DropdownMenu end tag={"ul"}
                                      className={"dropdown-menu-media mt-0 mini_scroll_bar"}
                                      style={{maxHeight: "25rem", maxWidth: "25rem", overflowY: "scroll"}}>
							<li className="dropdown-menu-header">
								<DropdownItem className="d-flex" tag="div" header>
                                    <h4 className="notification-title mb-0 me-auto">Notifications</h4>
								</DropdownItem>
							</li>
                            {notificationList.length > 0 ?
                                notificationList :
                                <li className="my-2">
                                    <label className={"mx-2"}>No Notifications</label>
                                </li>
                            }
                            {notificationList.length > 0 &&
                                <li className="dropdown-menu-footer">
                                    <NavLink onClick={navigateToNotification}>
                                        <button className="btn btn-primary d-block w-100">View all notifications
                                        </button>
                                    </NavLink>
                                </li>
                            }
						</DropdownMenu>
					</ButtonDropdown>
				</span>
            </NavItem>
            <UserAcademicInfoDropDown
                academicInfo={userData?.academicInfo?.data}
                handleUpdateHasPractice={updateHasPractice}
                handleUpdateHasScheduled={updateHasScheduled}
                handleUpdateHasCustomized={updateHasCustomized}
            />
            <NavItem tag={"span"}>
                <UserDropdown userName={userData?.name}/>
            </NavItem>
        </ul>
    )
}
export default NavbarUser
