import CustomizedMCQOptionList from "./CustomizedMCQOptionList";
import {
    Badge, Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input, Modal,
    ModalBody,
    ModalFooter,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import CustomMathjax from "../../../common/components/mathjax/CustomMathjax";
import {Flag} from "react-feather";
import Solution from "../../../practice-test/components/Solution";
import {API_URL, PRIMARY_COLOR} from "../../../common/constants/baseConstants";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import {
    FLAG_QUESTION_OTHER_REASON,
    FLAG_QUESTION_REASONS, FLAG_QUESTION_URL,
    V1_CUSTOMIZED_TEST_ENDPOINT
} from "../../constants/CustomizedTestConstants";
import authAxios from "../../../../configs/authAxios";
import {StatusCodes} from "http-status-codes";

const CustomizedQuestion = (props) => {
    const question = props.question

    const reasons = FLAG_QUESTION_REASONS;
    const OTHER_REASON_TEXT = FLAG_QUESTION_OTHER_REASON;
    const [reasonText, setReasonText] = useState("");
    const [isFlag, setIsFlag] = useState(question.was_reported)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [showFlagQuestionModal, setShowFlagQuestionModal] = useState(false)
    const [flagQuestionReason, setFlagQuestionReason] = useState(question?.report_reason ?? "");

    const handleOnReasonChange = (evt) => {
        const selectedReason = evt.target.getAttribute('data-value');
        setReasonText("");
        setFlagQuestionReason(selectedReason)
    }

    const flagQuestionReasonList = reasons.map((reason, index) => {
        const isSelected = (reason.text === flagQuestionReason)
        return (
            <div className="d-flex w-100" key={index}>
                <div className="custom-options-checkable mb-2 w-100">
                    <input type="radio" data-id={index} data-value={reason.text}
                           checked={isSelected} id={`option${index}`} name="authType"
                           className="custom-option-item-check"
                           onChange={handleOnReasonChange}
                    />

                    <label htmlFor={`option${index}`}
                           className={`cursor-pointer d-flex align-items-center rounded flex-column flex-sm-row px-1 py-1 ${!isSelected ? 'border-primary' : 'bg-primary'}`}
                           data-value={reason.text} onClick={handleOnReasonChange}
                    >
                        <span
                            className={`mcq-option d-block m-0 h5 w-100 ${!isSelected ? '' : 'text-white'}`}>{reason.text}</span>
                    </label>
                </div>
            </div>
        )
    })

    useEffect(() => {
        setIsFlag(question.was_reported)
        setFlagQuestionReason(question?.report_reason ?? "")
    }, [question])

    const handleOnClickFlagQuestion = () => {
        if (isFlag) {
            Swal.fire(
                {
                    icon: 'error',
                    title: 'CustomizedQuestion already Reported',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                }
            );
            return;
        }
        setShowFlagQuestionModal(true);
    }

    const handleFlagQuestion = async () => {
        const reason = flagQuestionReason === OTHER_REASON_TEXT ? reasonText : flagQuestionReason
        const postObj = {
            customized_question_id : question.id,
            reason: reason
        }

        const response = await authAxios.post(API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + FLAG_QUESTION_URL, postObj);

        if(response?.status === StatusCodes.OK) {
            Swal.fire(
                {
                    icon: 'success',
                    title: 'CustomizedQuestion Reported',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                }
            );
            setIsFlag(1);
            if(props.updateQuestionReportedState) {
                props.updateQuestionReportedState(question.id, reason)
            }
        } else if(response?.status === StatusCodes.BAD_REQUEST) {
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Question already reported.',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                }
            );
        } else {
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Something went wrong',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                }
            );
        }

    }

    const hideFlagQuestionModal = () => {
        setFlagQuestionReason("")
        setReasonText("");
        setShowFlagQuestionModal(false)
    }

    const handleFlagReasonOnInput = (evt) => {
        const text = evt.target.value
        setReasonText(text);
    }

    const handleOnSubmitFlagQuestion = async () => {
        if (flagQuestionReason === '' || (flagQuestionReason === OTHER_REASON_TEXT && reasonText === '')) {
            return false
        }
        handleFlagQuestion()
        setShowFlagQuestionModal(false);
        setFlagQuestionReason("")
        setReasonText("");
    }

    return(
        <>
            <Modal
                isOpen={showFlagQuestionModal}
                className='modal-dialog-centered'
                modalClassName="modal-primary"
            >
                <ModalBody>
                    <Row>
                        <Col className="card-img-container" md={12}>
                            <h4>Report the Question</h4>
                        </Col>
                    </Row>
                    <Row>
                        {flagQuestionReasonList}
                    </Row>
                    {flagQuestionReason == OTHER_REASON_TEXT && <Row>
                        <Col md={12}>
                            <FormGroup text>
                                <label>Report</label>
                                <Input autoFocus type='text' placeholder='Reason' id="reason_text"
                                       onInput={handleFlagReasonOnInput} value={reasonText}></Input>
                            </FormGroup>
                        </Col>
                    </Row>}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" outline onClick={hideFlagQuestionModal}>Cancel</Button>
                    <Button color="primary" disabled={isFormSubmitted}
                            onClick={handleOnSubmitFlagQuestion}>Submit</Button>
                </ModalFooter>
            </Modal>

            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <Row className='px-1 mb-1'>
                                <Col md={2} className="d-flex">
                                    <h3 className="items-align-start">Q{question.sequence}.</h3>
                                </Col>
                                <Col md={4}>
                                </Col>
                                <Col md={6}>
                                    <div className='float-end'>
                                        <Badge className='mx-2 difficulty-pill text-uppercase' pill>
                                            <span id="difficulty_pill">{question.difficulty_level}</span>
                                        </Badge>
                                        {!props.isReadOnlyMode &&
                                            <span>
                                                <UncontrolledTooltip placement='top' target='report_question'>
                                                    Report Question
                                                </UncontrolledTooltip>
                                                <Flag id={"report_question"}
                                                      className='cursor-pointer'
                                                      fill={isFlag ? PRIMARY_COLOR : 'WHITE'}
                                                      onClick={handleOnClickFlagQuestion} size={20} color={PRIMARY_COLOR}></Flag>
                                            </span>
                                        }
                                    </div>
                                    <div className='float-end fs-5'>
                                        <span style={{verticalAlign: 'sub'}}>Marks: {question.positive_mark}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='px-1 mb-1'>
                                <Col md={12}>
                                    <h3 className='mathjax_component' id={`question_${question?.id}`}>
                                        <CustomMathjax className="custom_mathjax" content={question?.value}></CustomMathjax>
                                    </h3>
                                </Col>
                            </Row>
                            <CustomizedMCQOptionList
                                handleOnUpdateQuestionMCQAnswer = {props.handleOnUpdateQuestionMCQAnswer}
                                options = {question?.options?.data}
                                question={question}
                                answer = {props.answer}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {question.solution &&
                        <Solution solution={question.solution}></Solution>}
                </Col>
            </Row>
        </>
    )
}

export default  CustomizedQuestion