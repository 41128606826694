import CustomMathjax from "../../common/components/mathjax/CustomMathjax";

const Option = (props) => {
    const id = props.id
    const statement = props.statement
    const isQuestionAnswered = props.isQuestionAnswered
    const optionChangeHandler = (event) => {
        if (!isQuestionAnswered) {
            props.onUpdateQuestionAnswersHandler(parseInt(event.target.getAttribute('data-id')))
        }
    }
    // console.log(props.isCorrect)
    const renderOptionStatus = () => {
        if (props.isSelected && props.isCorrect != null) {
            if (props.isCorrect == 1) {
                return <span className="float-right text-success">Correct</span>
            } else if (props.isCorrect == 0) {
                return <span className="float-right text-danger">Incorrect</span>
            }
        }
        return <></>
    }

    const getOptionBorderClass = () => {
        if (props.isSelected) {
            if (props.isCorrect == 0) {
                return "incorrect-answer"
            } else if (props.isCorrect == 1) {
                return "correct-answer"
            } else {
                return "selected-answer"
            }
        }
        return ""
    }

    return (
        <div className="d-flex w-100">
            <div className="custom-options-checkable mb-2 w-100">
                <input type="radio" data-id={id} id={`option${id}`} name="authType" className="custom-option-item-check"
                       onChange={optionChangeHandler}
                       checked={props.isSelected}
                       disabled={isQuestionAnswered}
                />
                <label htmlFor={`option${id}`}
                       className={`custom-question-option d-flex align-items-center flex-column flex-sm-row px-1 py-2 ${getOptionBorderClass()}`}>
                    <span className="mathjax_component mcq-option d-block m-0 h5 w-100">
                        {/*<MathJax msDelayDisplay={0} math={statement}></MathJax>*/}
                        <CustomMathjax className="custom_mathjax"
                                       content={statement}
                        ></CustomMathjax>
                    </span>
                    {renderOptionStatus()}
                </label>
            </div>
        </div>
    )
}

export default Option