import {useEffect, useState} from "react";
import authAxios from "../../../configs/authAxios";
import {API_URL} from "../../common/constants/baseConstants";
import {GET_PAST_TEST, V1_CUSTOMIZED_TEST_ENDPOINT} from "../constants/CustomizedTestConstants";
import {StatusCodes} from "http-status-codes";
import CustomizedPastTestCard from "./CustomizedPastTestCard";
import {Button, Col, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {UseMixPanel} from "../../../UseMixPanel";
import {VIEWED_PAST_CUSTOMIZED_TEST} from "../../common/constants/mixPanelEvents";

const CustomizedPastTest = (props) => {
    const [currentStandardMetadataId, setCurrentStandardMetadataId] = useState(props.currentStandardMetaDataId)
    const [pastTestPageNumber, setPastTestPageNumber] = useState(1);
    const [pastTestList, setPastTestList] = useState([]);
    const [pastTests, setPastTests] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState("");
    const [fetchingPastTests, setFetchingPastTests] = useState(false);

    const updatePastTest = (tests) => {
        setPastTests(pastTests.concat(tests));
    }

    const navigate = useNavigate()
    const useMixPanel = UseMixPanel()

    const navigateToPastTest = (testId) => {
        useMixPanel.track(VIEWED_PAST_CUSTOMIZED_TEST, {'testId' : testId})
        navigate(`/customized-tests/${testId}/result`)
    }

    const getPastTest = async (url) => {
        if(currentStandardMetadataId) {
            setFetchingPastTests(true);
            const pastTestResponse = await authAxios.get(url)
            if(pastTestResponse.status === StatusCodes.OK) {
                updatePastTest(pastTestResponse?.data?.data?.tests?.data)
                setNextPageUrl(pastTestResponse?.data?.data?.next_page)
            }
            setFetchingPastTests(false);
        }
    }

    const handleOnClickLoadMore = async () => {
        if(nextPageUrl) {
            await getPastTest(nextPageUrl + '&state=3&standard_metadata_id='+currentStandardMetadataId);
        }
    }

    useEffect(() => {
        let newPastTestList = [];
        if(pastTests !== null && pastTests !== undefined && Object.keys(pastTests).length > 0) {
            for(let i = 0; i < Object.keys(pastTests).length; i++) {
                const test = pastTests[i];
                newPastTestList.push(
                    <Col md={12} key={test.id}>
                        <CustomizedPastTestCard
                            key={test.id}
                            id={test.id}
                            name={test.name}
                            subjects={test.subjects}
                            total_marks={test.total_marks}
                            marks_obtained={test.marks_obtained}
                            onClickFunctionHandler={navigateToPastTest}
                            showResults={test.show_results}
                            reason={test.reason}
                        />
                    </Col>
                )
            }
        }
        setPastTestList(newPastTestList)
    }, [pastTests])

    useEffect(() => {
        (async () => {
            const getPastTestUrl = API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + GET_PAST_TEST + '?state=3&page=' + pastTestPageNumber+'&standard_metadata_id='+currentStandardMetadataId;
            await getPastTest(getPastTestUrl);
        })();
    }, [])

    return (
        <>
            <Row className={"mt-2"}>
                <Col md={12}>
                    <h4>Past tests</h4>
                </Col>
            </Row>
            <Row className={"mt-1 customized_test_list mini_scroll_bar"}>
                {pastTestList}
                {pastTestList.length === 0 &&
                    <Col md={12}>
                        <p>No Past tests found.</p>
                    </Col>
                }
                {nextPageUrl &&
                    <Col md={12} className={"text-center"}>
                        <Button onClick={handleOnClickLoadMore} disabled={fetchingPastTests} color={"primary"}>
                            { fetchingPastTests ?
                                "Loading..."
                                :
                                "Load More"
                            }
                        </Button>
                    </Col>
                }
            </Row>
        </>
    )
}

export default CustomizedPastTest