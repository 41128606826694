import {useState} from "react";
import {useEffect} from "react";
import {
    User
} from "react-feather"
// ** Reactstrap Imports
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledButtonDropdown
} from "reactstrap"
import {setCurrentStandardMetaDataId} from "@src/app/auth/reducers/states/userSlice";
import {useDispatch} from "react-redux";
import {getCookie, setCookie} from "src/app/common/helpers/CookieHelper";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

const UserAcademicInfoDropDown = (props) => {
    const dispatch = useDispatch();
    const academicInfo = props.academicInfo
    const [academicInfoDropDownItems, setAcademicInfoDropDownItems] = useState([]);
    const [currentAcademicInfo, setCurrentAcademicInfo] = useState(null);
    const [currentAcademicInfoId, setCurrentAcademicInfoId] = useState(getCookie('current_academic_info'))
    const navigate = useNavigate()
    const handleAcademicInfoSelected = (event) => {
        const standardMetaDataId = event.target.getAttribute('data-id')
        for (let i = 0; i < academicInfo.length; i++) {
            if (academicInfo[i].id == standardMetaDataId) {
                setCookie('current_academic_info', academicInfo[i].id)
                setCurrentAcademicInfoSelected(academicInfo[i])
                navigate('home');
                break;
            }
        }
    }

    const setCurrentAcademicInfoSelected = (info) => {
        setCurrentAcademicInfo(info);
        setCurrentAcademicInfoId(info.id)
        setCookie('current_academic_info', info.id)
        dispatch(setCurrentStandardMetaDataId(info.id))
        props.handleUpdateHasPractice(info?.has_practice)
        props.handleUpdateHasScheduled(info?.has_scheduled_olt)
        props.handleUpdateHasCustomized(info?.has_customize)
    }

    useEffect(() => {
        if (academicInfo != null) {
            let count = 0;
            const newAcademicInfoDropDownItems = academicInfo.map(info => {
                if (currentAcademicInfoId == info.id || (count == 0 && (currentAcademicInfo == null || currentAcademicInfo == undefined))) {
                    setCurrentAcademicInfoSelected(info)
                }
                count++;
                return <DropdownItem onClick={handleAcademicInfoSelected} data-id={info.id}
                                     key={info.id}>{info.name}</DropdownItem>
            });
            if (newAcademicInfoDropDownItems.length == 0) {
                toast.error("Please apply a batch code")
                navigate('/apply-batch-code')
            }
            setAcademicInfoDropDownItems(newAcademicInfoDropDownItems)
        }
    }, [academicInfo]);

    return (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item nav-item me-1">
            <div className="user-nav">
                <UncontrolledButtonDropdown>
                    <DropdownToggle outline color='secondary' caret>
                        {
                            (currentAcademicInfo != null && currentAcademicInfo.id != null) &&
                            <span className="user-name fw-bold">{currentAcademicInfo.name}</span>
                        }
                    </DropdownToggle>
                    <DropdownMenu>
                        {academicInfoDropDownItems}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </div>
        </UncontrolledDropdown>
    )
}

export default UserAcademicInfoDropDown
