export const VIEW_RECENT_PRACTICE_TEST_ANALYTICS = 'VIEWED RECENT PRACTICE TEST ANALYTICS'

export const VIEW_RECENT_SCHEDULE_TEST_ANALYTICS = 'VIEWED RECENT SCHEDULE TEST ANALYTICS'

export const USER_REGISTERED = 'USER REGISTERED'

export const APPLIED_BATCH_CODE = 'APPLIED BATCH CODE'

export const FLAG_QUESTION = 'FLAG QUESTION'

export const SAVE_QUESTION = 'SAVE QUESTION'

export const UNSAVE_QUESTION = 'UNSAVE QUESTION'

export const START_PRACTICE_TEST = 'START PRACTICE TEST'

export const SUBMIT_PRACTICE_TEST = 'SUBMIT PRACTICE TEST'

export const START_SCHEDULE_TEST = 'START SCHEDULE TEST'

export const SUBMIT_SCHEDULE_TEST = 'SUBMIT SCHEDULE TEST'

//Customized Test Event
export const STARTED_CUSTOMIZED_TEST = 'STARTED_CUSTOMIZED_TEST'

export const SUBMITTED_CUSTOMIZED_TEST = 'SUBMITTED_CUSTOMIZED_TEST'

export const VIEWED_PAST_CUSTOMIZED_TEST = 'VIEWED_PAST_CUSTOMIZED_TEST'

export const VIEWED_MISSED_CUSTOMIZED_TEST = 'VIEWED_MISSED_CUSTOMIZED_TEST'

export const VIEWED_UPCOMING_CUSTOMIZED_TEST_INFO = 'VIEWED_UPCOMING_CUSTOMIZED_TEST_INFO'

export const EXCEPTION_TEST_ENDED_AT_SERVER = 'EXCEPTION_TEST_ENDED_AT_SERVER'