// ** Vertical Menu Components
import VerticalNavMenuLink from "./VerticalNavMenuLink"
import VerticalNavMenuGroup from "./VerticalNavMenuGroup"
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader"
// ** Utils
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@layouts/utils"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"

const VerticalMenuNavItems = (props) => {
  const store = useSelector((state) => state)
  const userStore = store.user
  const [userState, setUserState] = useState([])

  useEffect(() => {
    if(userStore.id) {
      setUserState(userStore)
    }
  }, [userStore]);
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }
  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    if(item.isPublic || (userState && item.requirePerm && userState[item.requirePerm])) {
      const TagName = Components[resolveNavItemComponent(item)]
      if (item.children) {
        return (
          canViewMenuGroup(item) && (
            <TagName item={item} index={index} key={item.id} {...props} />
          )
        )
      }
      return <TagName key={item.id || item.header} item={item} {...props} />
    } 
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
