import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Progress,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import InformationCard from "../../../common/components/InformationCard";
import {Award, Check, Clock, HelpCircle, User, X} from "react-feather";
import {PRIMARY_COLOR} from "../../../common/constants/baseConstants";
import FirstRankIcon from "../../../common/icons/rank/FirstRankIcon";
import SecondRankIcon from "../../../common/icons/rank/SecondRankIcon";
import ThirdRankIcon from "../../../common/icons/rank/ThirdRankIcon";
import FourthRankIcon from "../../../common/icons/rank/FourthRankIcon";
import FifthRankIcon from "../../../common/icons/rank/FifthRankIcon";

export const CustomizedPastTestResultBody = ({testDetails, leaderboardComponent, isLeaderboardReady, leaderboardReadyMessage}) => {
    const medalIcon = <Award className={"m-auto"} color={PRIMARY_COLOR} size={40}></Award>;
    const correctIcon = <Check className={"m-auto"} color={PRIMARY_COLOR} size={40}></Check>;
    const incorrectIcon = <X className={"m-auto"} color={PRIMARY_COLOR} size={40}></X>;
    const skipIcon = <Clock className={"m-auto"} color={PRIMARY_COLOR} size={40}></Clock>;

    const accuracy = testDetails.num_attempted_questions > 0 ? (testDetails.num_correct_questions / testDetails.num_attempted_questions) * 100 : 0
    const averageTimePerQuestion = testDetails.total_time_taken > 0 ? ((testDetails.total_time_taken / testDetails.num_attempted_questions) / 60).toFixed(2) : 0 ;
    return (
        <Row className="m-0 mt-2 d-flex align-items-center justify-content-center">
            <Col md="8">
                <Row>
                    <Col md={12}>
                        <InformationCard
                            title="Congratulations"
                            subTitle="You scored"
                            icon={medalIcon}
                            info={`${testDetails?.marks_obtained} / ${testDetails?.total_marks}`}
                            iconBackgroundColor="rgba(47, 85, 141, 0.1)"
                        ></InformationCard>
                    </Col>
                    <Col md={12}>
                        <Card>
                            <CardBody className="mt-1">
                                <Row className="">
                                    <Col md="3">
                                        <h5>
                                            Accuracy
                                        </h5>
                                    </Col>
                                    <Col md="7"><Progress color="primary"
                                                          value={accuracy.toFixed(0)}></Progress></Col>
                                    <Col
                                        md="2"><small><strong>{accuracy.toFixed(0)}%</strong></small></Col>
                                </Row>
                                <Row>
                                    <Col md="3"><h5>Speed</h5></Col>
                                    <Col md="9">
                                        <h6>{averageTimePerQuestion} minute
                                            Per Question</h6></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col md="4">
                <Card>
                    <CardHeader className={"py-1"}>
                        <CardTitle>Test Leaderboard</CardTitle>
                    </CardHeader>
                    <CardBody className={"pb-1"}>
                        <Row className={"px-1"}>
                            {
                                isLeaderboardReady && Object.keys(leaderboardComponent).length > 0
                                ?
                                leaderboardComponent
                                :
                                <Col md={12} style={{minHeight: '13.5rem'}} className={"d-flex align-items-center"}>
                                    <CardText className={"w-100 text-center"}>
                                        {isLeaderboardReady ? leaderboardReadyMessage : "Fetching Leaderboard..."}
                                    </CardText>
                                </Col>
                            }
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}