import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Book, Check, CheckCircle, Clock, Crosshair, File, Star, X} from "react-feather";
import {getReadableMinutesFromSeconds} from "../../../utility/Utils";
import {PRIMARY_COLOR} from "../../common/constants/baseConstants";
import CustomizedTestInstructionSubjectDetails from "./CustomizedTestInstructionSubjectDetails";

const CustomizedStartTestInstructionsModal = ({
        isVisible,
        startTest,
        closeModal,
        testDetails,
        isTestAllowedAtThisTime = true
}) => {

    let subjectList = [];
    if(testDetails?.questionDistribution?.data !== null && testDetails?.questionDistribution?.data !== undefined && Object.keys(testDetails?.questionDistribution?.data).length > 0) {
        for(let i = 0; i < Object.keys(testDetails.questionDistribution.data).length; i++) {
            const subject = testDetails.questionDistribution.data[i];
            subjectList.push(
                <CustomizedTestInstructionSubjectDetails
                    key={i}
                    subjectName = {subject.subejct_name}
                    positive_mark = {subject.positive_mark}
                    negative_mark = {subject.negative_mark}
                    chapters = {subject.chapters.replace(' ', "").split(",")}
                />
            )
        }
    }

    return (
        <Modal
            isOpen={isVisible}
            className='modal-dialog-centered'
            modalClassName="modal-primary"
        >
            <ModalHeader>
                {testDetails.name}
            </ModalHeader>
            <ModalBody>
                <Row className={"mb-1"}>
                    <Col md={4} sm={4} xs={4}>
                        <p className={"m-0 text-center"}><Clock color={PRIMARY_COLOR}></Clock></p>
                        <p className={"m-0 text-center"}>Duration</p>
                        <p className={"m-0 text-center"}>{getReadableMinutesFromSeconds(testDetails.duration)}</p>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <p className={"m-0 text-center"}><Star color={PRIMARY_COLOR}></Star></p>
                        <p className={"m-0 text-center"}>Marks</p>
                        <p className={"m-0 text-center"}>{testDetails.total_marks}</p>
                    </Col>
                    <Col md={4} sm={4} xs={4}>
                        <p className={"m-0 text-center"}><File color={PRIMARY_COLOR}></File></p>
                        <p className={"m-0 text-center"}>Questions</p>
                        <p className={"m-0 text-center"}>{testDetails.num_questions}</p>
                    </Col>
                </Row>
                {subjectList}
                <Row className={"mt-2"}>
                    <Col md={12}>
                        <h6>Instructions</h6>
                        <ol>
                            <li><strong>Each test can only be given once. </strong></li>
                            <li>This is a multiple choice question test and each question will have 4 options.</li>
                            <li>Procedure for answering a multiple choice type question:
                                <ol style={{paddingLeft: "2rem"}}>
                                    <li>To select you answer, click on of one of the options.</li>
                                    <li>To deselect your chosen answer, click on the&nbsp;<strong>Clear
                                        Response</strong> button at the bottom.
                                    </li>
                                    <li>To change your chosen answer, click on the Clear Response button and select the
                                        option.
                                    </li>
                                </ol>
                            </li>
                            <li>You may use the &lsquo;&lt; Prev&rsquo; and &lsquo;Next &gt;&rsquo; buttons on the
                                bottom of the screen for navigation
                            </li>
                            <li>You can skip any question and revisit it later</li>
                            <li>You can click on the &lsquo;Review Later&rsquo; button below the question if you want to
                                revisit any particular question later.
                            </li>
                            <li>You can revisit all &lsquo;Review Later&rsquo; and &lsquo;Skipped&rsquo; questions by
                                clicking on the &lsquo;Filter&rsquo; icons given on the top left of the screen
                            </li>
                            <li>The countdown timer on the top of the screen will display the remaining time available
                                for you to complete the examination. When the timer reaches zero, the examination will
                                end by itself. You will not be required to end or submit your examination.
                            </li>
                            <li>You can end the test anytime by clicking on &lsquo;Submit&rsquo; button on the top right
                                of the screen
                            </li>
                        </ol>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {
                    testDetails.is_started
                        ?
                        <Button color="primary w-100" onClick={startTest} disabled={testDetails.is_started === 0}>Begin Test</Button>
                        :
                        <Button color="primary w-100" disabled>Test yet to begin</Button>
                }
                <Button color="secondary w-100" onClick={closeModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default  CustomizedStartTestInstructionsModal