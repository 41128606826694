// ** React Imports
import {Link} from "react-router-dom"

// ** Reactstrap Imports
import {Button, Col, Row} from "reactstrap"

// ** Custom Hooks
import {useSkin} from "@hooks/useSkin"

// ** Styles
import "@styles/base/pages/page-misc.scss"
import BrandLogo from "src/app/common/components/theme/BrandLogo"
import {ReactSVG} from "react-svg";

const DownloadMobileApp = () => {
    // ** Hooks
    const {skin} = useSkin()

    const source = require(`@src/assets/images/pages/hero-product.png`).default
    return (
        <div className="misc-wrapper">
            <div className="p-2 p-sm-3">
                <div className="w-100 text-center">
                    <BrandLogo reload className="misc-page-logo"></BrandLogo>
                    <Row>
                        <Col md="12">
                            <h4 className="mb-1">Download Quill Plus app to access tests, practice, and other
                                content</h4>
                            <a href="https://play.google.com/store/apps/details?id=com.quillplus">
                                <Button
                                    color="primary"
                                    className="btn-sm-block mb-1"
                                >
                                    <img src={"assets/icons/google.svg"} height="20" className={"mx-1"}/>
                                    Get it on Google Play
                                </Button>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <a href="https://apps.apple.com/us/app/quillplus/id165927584">
                                <Button
                                    color="primary"
                                    className="btn-sm-block mb-1"
                                >
                                    <img src={"assets/icons/apple.svg"} height="20" className={"mx-1"}/>
                                    Get it on App Store
                                </Button>
                            </a>
                        </Col>
                    </Row>
                    <img className="img-fluid" src={source} alt="Not authorized page"/>
                </div>
            </div>
        </div>
    )
}
export default DownloadMobileApp
