import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	phone: null,
	otp: null,
	shortLivedToken: null,
    deviceId: null,
    isPasswordRequired: false,
    verifyPasswordToken: null
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
		setPhone: (state, action) => {
            state.phone = action.payload
        },
		setOTP: (state, action) => {
            state.otp = action.payload
        },
		setShortLivedToken: (state, action) => {
            state.shortLivedToken = action.payload
        },
        setDeviceId: (state, action) => {
            state.deviceId = action.payload
        },
        setIsPasswordRequired: (state, action) => {
            state.isPasswordRequired = action.payload
        },
        setVerifyPasswordToken: (state, action) => {
            state.verifyPasswordToken = action.payload
        }
    }
});

export const { 
    setPhone,
    setOTP,
    setShortLivedToken,
    setDeviceId,
    setIsPasswordRequired,
    setVerifyPasswordToken
} = loginSlice.actions

export default loginSlice.reducer;