import {setTitle} from "../utility/Utils";
import {useSkin} from "../utility/hooks/useSkin";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    CardText,
    CardTitle,
    Col,
    Form,
    Input,
    InputGroup,
    Label, Modal, ModalBody, ModalFooter, NavLink,
    Row,
    Spinner
} from "reactstrap";
import BrandLogo from "../app/common/components/theme/BrandLogo";
import {
    API_URL,
    DEFAULT_API_URL_PREFIX,
    DEFAULT_LIGHT_LOGO,
    WEB_DEVICE_TYPE
} from "../app/common/constants/baseConstants";
import {setCookie} from "@src/app/common/helpers/CookieHelper"
import {Eye, EyeOff} from "react-feather";
import Swal from "sweetalert2";
import baseAxios from "../configs/baseAxios";
import {StatusCodes} from "http-status-codes";

const VerifyPassword = () => {
    setTitle('Password')
    const {skin} = useSkin()
    const [errorMessage, setErrorMessage] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] = useState(false);
    const store = useSelector((state) => state)
    const loginStore = store.login

    const [whitelabels, setWhitelabels] = useState([]);
    const [selectedWhitelabelId, setSelectedWhitelabelId] = useState(null);
    const [showFlagQuestionModal, setShowFlagQuestionModal] = useState(false)
    const [whiteLabelList, setWhiteLabelList] = useState([]);
    const verifyUserURL = `${API_URL}${DEFAULT_API_URL_PREFIX}users/verify-password`
    const genTokenForUserURL = `${API_URL}${DEFAULT_API_URL_PREFIX}users/gen-token-for-user`


    useEffect(() => {
        if (errorMessage) {
            Swal.fire({
                icon: "error",
                title: "Oops",
                text: errorMessage,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
            })
        }
        setErrorMessage(null)
    }, [errorMessage])

    const validatePassword = () => {
        if (password === undefined || password === null || password.length < 8) {
            return false
        } else {
            return true
        }
    }

    const verifyPassword = async (e) => {
        e.preventDefault()
        let isPasswordValid = await validatePassword()
        if (isPasswordValid) {
            setIsFormSubmittedSuccessfully(true)

            const postObj = {
                phone: loginStore.phone,
                device: WEB_DEVICE_TYPE,
                password: password,
                token: loginStore.verifyPasswordToken
            }

            let url = verifyUserURL
            if (selectedWhitelabelId) {
                postObj.whitelabel_id = selectedWhitelabelId;
                url = genTokenForUserURL
            }
            let response = await baseAxios.post(url, postObj)
            let status = response?.status;
            
            if (status === StatusCodes.OK) {
                const data = response.data.data
                if (data.is_registered === 0) {
                    navigate("/register")
                } else if (data.is_registered === 1 && data.token == null && data.whitelabels) {
                    setIsFormSubmittedSuccessfully(false);
                    setWhitelabels(data.whitelabels.data)
                    return;
                } else {
                    navigate("/home")
                }
                if (data.token) {
                    setCookie('token', data.token, 100)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops",
                        text: "Unable to login at the moment",
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                    })
                }
            } else {
                setIsFormSubmittedSuccessfully(false);
                const errorData = response?.data?.data ?? []
                let message = ""
                if (errorData) {
                    for (const index in errorData) {
                        message += " " + errorData[index]
                    }
                } else {
                    message = "Something went wrong. Please try again after some time."
                }
                setErrorMessage(message)
            }
        } else {
            setIsFormSubmittedSuccessfully(false)
            setErrorMessage("Your password is too short, it should be at least 8 characters long.")
        }
    }

    const illustration = skin === "dark" ? "dark-login.png" : "light-login.png",
        source = require(`@src/assets/images/illustrations/${illustration}`).default


    const handleWhiteLabelSelected = (evt) => {
        const whiteLabelId = evt.target.getAttribute('data-value');
        setSelectedWhitelabelId(whiteLabelId)
        const newWhiteLabels = whitelabels.map(whitelabel => {
            if (whitelabel.id == whiteLabelId) {
                whitelabel.is_selected = 1
                return whitelabel
            } else {
                whitelabel.is_selected = 0
                return whitelabel
            }
        })
        setWhitelabels(newWhiteLabels)
        evt.preventDefault()
    }

    useEffect(() => {
        const newWhiteLabelList = whitelabels.map(whitelabel => {
            const isSelected = whitelabel.is_selected
            return (
                <div className="d-flex w-100" key={whitelabel.id} data-value={whitelabel.id}>
                    <div className="custom-options-checkable mb-2 w-100" data-value={whitelabel.id}>
                        <input type="radio" data-id={whitelabel.id} data-value={whitelabel.id}
                               onChange={handleWhiteLabelSelected} checked={isSelected} id={`option${whitelabel.id}`}
                               name="authType" className="custom-option-item-check"/>
                        <label htmlFor={`option${whitelabel.id}`}
                               className={`cursor-pointer d-flex align-items-center rounded flex-column flex-sm-row px-1 py-1 ${!isSelected ? 'border-primary' : 'bg-primary'}`}
                               data-value={whitelabel.id} onClick={handleWhiteLabelSelected}
                        >
                            <span data-value={whitelabel.id}
                                  className={`mcq-option d-block m-0 h5 w-100 ${!isSelected ? '' : 'text-white'}`}>{whitelabel.name}</span>
                        </label>
                    </div>
                </div>
            )
        })
        if (whitelabels.length > 0) {
            setShowFlagQuestionModal(true)
        } else {
            setShowFlagQuestionModal(false)
        }
        setWhiteLabelList(newWhiteLabelList)
    }, [whitelabels])

    return (
        <div className="auth-wrapper auth-cover">
            <Modal
                isOpen={showFlagQuestionModal}
                className='modal-dialog-centered'
                modalClassName="modal-primary"
            >
                <ModalBody>
                    <Row>
                        <Col className="card-img-container" md={12}>
                            <h4>Select an Institute to continue with</h4>
                        </Col>
                    </Row>
                    <Row>
                        {whiteLabelList}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline className={"w-100"} onClick={verifyPassword}>
                        {
                            !isFormSubmittedSuccessfully &&
                            <span className="mx-1">Submit</span>
                        }
                        {
                            isFormSubmittedSuccessfully &&
                            <span className="mx-1">Please wait...</span>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
            <Row className="auth-inner m-0">
                <Col
                    className="d-flex align-items-center auth-bg px-2 p-lg-5"
                    lg="4"
                    sm="12"
                >
                    <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
                        <BrandLogo
                            lightThemeLogoSource={DEFAULT_LIGHT_LOGO}
                        ></BrandLogo>
                        <CardTitle tag="h2" className="fw-bolder mb-1 mt-4">
                            Enter Password
                        </CardTitle>
                        <CardText className="mb-2">
                            Please enter your password to proceed further🔒
                        </CardText>
                        <Form
                            className="auth-login-form mt-2"
                            onSubmit={verifyPassword}
                        >
                            <div className="mb-1">
                                <Label className="form-label" for="password">
                                    Password
                                </Label>
                                <InputGroup className='mb-2'>
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        autoFocus
                                        placeholder='Password'
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                    <Button
                                        size='sm'
                                        color='primary'
                                        outline
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ?
                                            <Eye/> :
                                            <EyeOff/>
                                        }
                                    </Button>
                                </InputGroup>
                            </div>
                            <Button color="primary" className="me-1 mb-1 btn-spinner" block>
                                {
                                    !isFormSubmittedSuccessfully &&
                                    <span>Proceed</span>
                                }
                                {
                                    isFormSubmittedSuccessfully &&
                                    <Spinner color='light'/>
                                }
                                {
                                    isFormSubmittedSuccessfully &&
                                    <span className="mx-1">Please wait...</span>
                                }
                            </Button>
                        </Form>
                        <NavLink href={"mailto:info@quillplus.in"} className={"p-0"}>
                            <Button className="mt-2" color="primary" outline block>
                                Trouble signing in?
                            </Button>
                        </NavLink>
                    </Col>
                </Col>
                <Col className="d-none d-lg-flex align-items-center p-5" lg="8" sm="12">
                    <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
                        <img className="img-fluid" src={source} alt="Login Cover"/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default VerifyPassword