import Chart from "react-apexcharts";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap"
import {secondsToDhms} from "../../../../utility/Utils";

const DonutChart = ({data, title, height, isDataInSeconds, showTotal}) => {
    const stats = {
        series: Object.values(data) ?? [0, 0, 0],
        options: {
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 0,
                    customScale: 1,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10
                    },
                    donut: {
                        size: '65%',
                        background: 'transparent',
                        labels: {
                            show: true,
                            total: {
                                show: showTotal,
                                showAlways: true,
                                label: 'Total',
                                fontSize: '18px',
                                formatter: function (w) {
                                    let sum = 0;
                                    sum = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        },
                                        0)
                                    if(isDataInSeconds) {
                                        sum = secondsToDhms(sum)
                                    }
                                    return sum;
                                }
                            },
                        }
                    },
                },
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value) => isDataInSeconds ? secondsToDhms(value) : value,
                },
            },
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 400
                    },
                }
            }],
            labels : Object.keys(data) ?? ['Correct', 'Incorrect', 'Skipped'],
            legend: {
                position: 'bottom',
                itemMargin: {
                    vertical: 10,
                    horizontal: 10
                }
            },
            colors: ['#45A664', '#E54D38', '#767779']
        },
    };

    return (
        <Card>
            {title && <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>}
            <CardBody style={{padding: '0.5rem'}}>
                <Chart
                    options={stats.options}
                    series={stats.series}
                    type="donut"
                    height={height ?? "350"}
                />
            </CardBody>
        </Card>
    )
}

export default DonutChart