import {useEffect, useState} from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Col, Row } from 'reactstrap';
import CustomizedUpcomingTest from "./CustomizedUpcomingTest";
import CustomizedPastTest from "./CustomizedPastTest";
import CustomizedMissedTest from "./CustomizedMissedTest";
import {
    DEFAULT_LOADER_MESSAGE,
    GET_TEST_DETAILS,
    V1_CUSTOMIZED_TEST_ENDPOINT
} from "../constants/CustomizedTestConstants";
import authAxios from "../../../configs/authAxios";
import {API_URL} from "../../common/constants/baseConstants";
import CustomizedStartTestInstructionsModal from "./CustomizedStartTestInstructionsModal";
import {StatusCodes} from "http-status-codes";
import {UseStartCustomizedTest} from "../hooks/UseStartCustomizedTest";
import {UseMixPanel} from "../../../UseMixPanel";
import {VIEWED_UPCOMING_CUSTOMIZED_TEST_INFO} from "../../common/constants/mixPanelEvents";
import {useSelector} from "react-redux";

const CustomizedTestHome = () => {
    const useMixPanel = UseMixPanel();
    const useStartCustomizedTest = UseStartCustomizedTest();
    const [showLoader, setShowLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState(DEFAULT_LOADER_MESSAGE);
    const [isTestInstructionsVisible, setIsTestInstructionsVisible] = useState(false);
    const [testDetails, setTestDetails] = useState({});
    const [isTestAllowedAtThisTime, setIsTestAllowedAtThisTime] = useState(false)
    const store = useSelector((state) => state)
    const userStore = store.user
    const [currentStandardMetaDataId, setCurrentMataDataId] = useState(userStore.currentStandardMetaDataId);

    const hideTestInstructionsModal = () => {
        setIsTestInstructionsVisible(false)
    }

    const handleShowLoader = (state, loaderMessage) => {
        setShowLoader(state)
        setLoaderMessage(loaderMessage ?? DEFAULT_LOADER_MESSAGE)
    }

    const startTest = () => {
        useStartCustomizedTest.generateTest(testDetails)
    }

    const getCustomizedTestDetails = async (testId, isTestAllowed = true) => {
        setIsTestAllowedAtThisTime(isTestAllowed)
        setShowLoader(true)
        let url = GET_TEST_DETAILS.replace('#testId', testId)
        const testDetailResponse = await authAxios.get(API_URL + V1_CUSTOMIZED_TEST_ENDPOINT + url)
        if (testDetailResponse.status === StatusCodes.OK) {
            setTestDetails(testDetailResponse?.data?.data)
        }
        setIsTestInstructionsVisible(true);
        setShowLoader(false)
        const mixpanelObj = {
            'id' : testId,
            'testName' : testDetailResponse?.data?.data?.name
        }
        useMixPanel.track(VIEWED_UPCOMING_CUSTOMIZED_TEST_INFO, mixpanelObj)
    }

    return (
        <LoadingOverlay
            active={showLoader}
            spinner
            text={loaderMessage}
        >
            <CustomizedStartTestInstructionsModal
                isVisible={isTestInstructionsVisible}
                closeModal={hideTestInstructionsModal}
                testDetails={testDetails}
                startTest={startTest}
                isTestAllowedAtThisTime = {isTestAllowedAtThisTime}
            />
            <Row>
                <Col md="12">
                    <h2>Customized Tests</h2>
                </Col>
            </Row>
            <Row>
                <Col lg="7" md="7" sm="12" xs="12">
                    <CustomizedUpcomingTest
                        handleShowLoader = {handleShowLoader}
                        handleCustomizedTestDetails = { getCustomizedTestDetails }
                        currentStandardMetaDataId = {currentStandardMetaDataId}
                    />
                </Col>
                <Col lg="5" md="5" sm="12" xs="12">
                    <CustomizedPastTest
                        currentStandardMetaDataId = {currentStandardMetaDataId}
                    />
                    <CustomizedMissedTest
                        currentStandardMetaDataId = {currentStandardMetaDataId}
                    />
                </Col>
            </Row>
        </LoadingOverlay>
    )
}

export default CustomizedTestHome