import Option from "../../../schedule-test/components/Option";
import CustomizedMCQOption from "../CustomizedMCQOption";

const CustomizedMCQOptionList = ({options, answer, question, handleOnUpdateQuestionMCQAnswer}) => {
    const handleOnClickOption = (optionId) => {
        if(handleOnUpdateQuestionMCQAnswer) {
            handleOnUpdateQuestionMCQAnswer(question.id, optionId)
        }
    }
    let optionsComponents = []
    if (options && Object.keys(options).length > 0) {
        optionsComponents = options.map(option => {
            return <CustomizedMCQOption
                isSelected={option.id === question.option_id || option.id === question?.selected_option_id}
                isCorrect={option?.is_answer}
                key={option.id}
                option={option}
                onClickHandler={handleOnClickOption}
            />
        })
    }
    return (
        <div>
            {optionsComponents}
        </div>
    )
}

export default  CustomizedMCQOptionList