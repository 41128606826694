import {Card, CardBody, CardHeader} from "reactstrap";
import CustomMathjax from "../../common/components/mathjax/CustomMathjax";

const Solution = ({solution}) => {
    
    return (
        <>
            {solution && <Card>
                <CardHeader><h4>Solution :</h4></CardHeader>
                <CardBody className="solution">
                    {/*<MathJax msDelayDisplay={0} math={solution}></MathJax>*/}
                    <CustomMathjax className="custom_mathjax"
                                   content={solution}
                    ></CustomMathjax>
                </CardBody>
            </Card>}
        </>
    )
}

export default Solution;