import {Link, useNavigate} from "react-router-dom"
import {Button, CardText, CardTitle, Col, Form, Input, Label, Row, Spinner} from "reactstrap"
import {StatusCodes} from "http-status-codes";
import "@styles/react/pages/page-authentication.scss"
import {useDispatch, useSelector} from "react-redux"
import {getCookie, setCookie} from "@src/app/common/helpers/CookieHelper"
import {API_URL, DEFAULT_API_URL_PREFIX, WEB_DEVICE_TYPE} from "@src/app/common/constants/baseConstants"
import {useEffect, useState} from "react"
import Swal from "sweetalert2"
import BrandLogo from "src/app/common/components/theme/BrandLogo"
import authAxios from "../configs/authAxios"
import {setShowApplyBatchCodeModal} from "../app/common/reducers/state/ApplyBatchCodeSlice";
import {setTitle} from "../utility/Utils";
import {deleteCookie} from "../app/common/helpers/CookieHelper";
import {APPLIED_BATCH_CODE} from "../app/common/constants/mixPanelEvents";
import {UseMixPanel} from "../UseMixPanel";
import toast from "react-hot-toast";

const ApplyBatchCode = ({showIllustration = true}) => {
    setTitle('Apply Batch Code')
    const useMixPanel = UseMixPanel()
    const source = require("@src/assets/images/illustrations/dark-verify-otp.png").default
    const advtSource = require("@src/assets/images/pages/advertisment.jpg").default
    const applyBatchCodeURL = `${API_URL}${DEFAULT_API_URL_PREFIX}users/apply-batch-code`
    const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const store = useSelector((state) => state)
    const userData = store.user
    const hideApplyBatchModal = () => {
        dispatch(setShowApplyBatchCodeModal(false))
    }

    useEffect(() => {
        if (userData.id != null && !userData.is_registered) {
            toast.error("Please complete the Registration process.")
            navigate('/register')
        }
    }, [])

    useEffect(() => {
        if (errorMessage) {
            Swal.fire({
                icon: "error",
                title: "Oops",
                text: errorMessage,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
            })
        }
        setErrorMessage(null)
    }, [errorMessage])

    const handleLogout = () => {
        deleteCookie('token');
        navigate('/login');
    }
    const applyBatchCode = async (event) => {
        setIsFormSubmittedSuccessfully(true);
        event.preventDefault();
        const batchCode = document.getElementById('batch_code').value
        const postObj = {
            key: batchCode,
            device: WEB_DEVICE_TYPE
        }
        const response = await authAxios.post(applyBatchCodeURL, postObj);
        const status = response?.status;
        if (status === StatusCodes.OK) {
            const data = response?.data?.data
            if (data) {
                useMixPanel.track(APPLIED_BATCH_CODE)
                dispatch(setShowApplyBatchCodeModal(false))
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    willClose: () => {
                        window.location.href = '/home';
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: data
                })
            }
        } else {
            setIsFormSubmittedSuccessfully(false);
            const errorData = response?.data?.data ?? []
            let message = ""
            if (errorData) {
                for (const index in errorData) {
                    message += errorData[index]
                }
            } else {
                message = "Something went wrong. Please try again after some time."
            }
            setErrorMessage(message)
        }
    }

    return (
        <div className={`${showIllustration ? 'auth-wrapper auth-cover' : ''}`}>
            <Row className={`${showIllustration ? 'auth-inner' : ''}`}>
                <Col
                    className="d-flex align-items-center auth-bg px-2 p-lg-5"
                    md={showIllustration ? 4 : 12}
                >
                    <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
                        {showIllustration &&
                            <BrandLogo></BrandLogo>
                        }
                        <CardTitle tag="h2" className={`fw-bolder mb-1 ${showIllustration ? 'mt-4' : 'mt-2'}`}>
                            Apply Batch Code
                        </CardTitle>
                        <CardText className="mb-2">
                            Enter your batch code
                        </CardText>
                        <Form
                            className="auth-login-form mt-2"
                            onSubmit={applyBatchCode}
                        >
                            <div className="mb-1">
                                <Label className="form-label" for="batch_code">
                                    Batch Code
                                </Label>
                                <Input
                                    type="text"
                                    id="batch_code"
                                    autoFocus
                                    placeholder='Ex: AD12BC'
                                />
                            </div>
                            <Button
                                color="primary"
                                block
                                className="me-1 mb-1 btn-spinner"
                                onClick={applyBatchCode}
                                disabled={isFormSubmittedSuccessfully}
                            >
                                {
                                    !isFormSubmittedSuccessfully &&
                                    <span>Apply</span>
                                }
                                {
                                    isFormSubmittedSuccessfully &&
                                    <Spinner color='light'/>
                                }
                                {
                                    isFormSubmittedSuccessfully &&
                                    <span className="mx-1">Please wait...</span>
                                }
                            </Button>
                            {
                                showIllustration &&
                                <p className={'text-end'} onClick={handleLogout}>
                                    <Link to="#">
                                        Logout
                                    </Link>
                                </p>
                            }
                            {
                                !showIllustration &&
                                <Button
                                    color="secondary"
                                    block
                                    className="me-1 mb-1 btn-spinner"
                                    onClick={hideApplyBatchModal}
                                >
                                    <span>Cancel</span>
                                </Button>
                            }
                        </Form>
                    </Col>
                </Col>
                {
                    showIllustration &&
                    <Col className="d-none d-lg-flex align-items-center p-5"
                         md={8}
                    >
                        <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
                            <img className="img-fluid" src={source} alt="Apply Batch Code"/>
                        </div>
                    </Col>
                }
                {/* <Col className="d-none d-lg-flex align-items-center p-5 bg-white" lg="5" sm="12">
                    <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
                        <img className="img-fluid" src={advtSource} alt="Advertisment" />
                    </div>
                </Col> */}
            </Row>
        </div>
    )
}

export default ApplyBatchCode;