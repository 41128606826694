import {Bell, Circle, User} from "react-feather";
import {Col, Row} from "reactstrap";
import {PRIMARY_COLOR} from "../../../../../app/common/constants/baseConstants";
import {reactLocalStorage} from "reactjs-localstorage";
import {useNavigate} from "react-router-dom";

const NotificationDropDownItem = ({
                                      id,
                                      title,
                                      subTitle,
                                      sentAt,
                                      isRead,
                                      icon,
                                      iconColor,
                                      handleOnClick
                                  }) => {
    const navigate = useNavigate()
    const handleOnClickOfItem = async () => {
        await handleOnClick()
        reactLocalStorage.set('notificationTitle', title)
        reactLocalStorage.set('notificationDescription', subTitle)
        reactLocalStorage.set('notificationIcon', icon)
        navigate('/notification/' + id);
    }

    return (
        <li onClick={handleOnClickOfItem}
            className="cursor-pointer scrollbar-container media-list scrollable-container ps ps--active-y">
            <span className="d-flex">
                <div className="list-item d-flex align-items-start">
                    <div className="me-1">
                        {icon ? <img src={icon} width={"32px"}/> : <Bell color={iconColor}></Bell>}
                    </div>
                    <div className="list-item-body flex-grow-1">
                        <Row>
                            <Col md={12}>
                                <p className="media-heading">
                                    <span className="fw-bolder">{title}</span>
                                    {!isRead &&
                                        <span className="float-end"><Circle size={10} fill={PRIMARY_COLOR}
                                                                            color={PRIMARY_COLOR}/></span>
                                    }
                                </p>
                            </Col>
                        </Row>
                        <small className={"text-dark"}>{subTitle}</small>
                        <small className={"float-end text-dark"}>{sentAt}</small>
                        {/*<Row>*/}
                        {/*    <Col md={9} ><span className="notification-text">{subTitle}</span></Col>*/}
                        {/*    <Col md={3} className={"float-end"}><span className="notification-text float-end">{sentAt}</span></Col>*/}
                        {/*</Row>*/}
                    </div>
                </div>
            </span>
        </li>
    )
}

export default NotificationDropDownItem;