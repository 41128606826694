import {Card, CardBody, Col, Progress, Row} from "reactstrap";
import CustomizedTestQuestionPanel from "./CustomizedTestQuestionPanel";
import CustomizedQuestionBody from "./CustomizedQuestionBody";
import {useEffect, useState} from "react";
import {Clock} from "react-feather";
import {dateDiffInSeconds, secondsToHHMMSS} from "../../../../utility/Utils";
import {TIMEDOUT_END} from "../../../common/constants/ScheduleTestEndTypeConstants";

const CustomizedTestBody = (props) => {

    return (
        <Row className={"p-1"}>
            <Col md={3}>
                <CustomizedTestQuestionPanel
                    testQuestionsTime = {props.testQuestionsTime}
                    handleQuestionPillClick = {props.handleQuestionPillClick}
                    testQuestions = {props.allTestQuestionState ?? props.testQuestions}
                    isReadOnlyMode = {props.isReadOnlyMode}
                    questionStates = {props.questionStates}
                    manageQuestionStateWiseFilter = {props.manageQuestionStateWiseFilter}
                    filterQuestionByStates = {props.questionFilteredStates}
                />
            </Col>
            <Col md={9}>
                <CustomizedQuestionBody
                    isReadOnlyMode = {props.isReadOnlyMode}
                    handleOnUpdateQuestionMCQAnswer = {props.handleOnUpdateQuestionMCQAnswer}
                    question = {props.activeQuestion}
                    totalQuestionsLength = {props.totalQuestionsLength}
                    handleNavigateToNextQuestion = {props.handleNavigateToNextQuestion}
                    handleNavigateToPreviousQuestion = {props.handleNavigateToPreviousQuestion}
                    handleMarkQuestionForReview = {props.handleMarkQuestionForReview}
                    handleClearQuestionAnswer = {props.handleClearQuestionAnswer}
                    updateQuestionReportedState = {props.updateQuestionReportedState}
                    handleOnSubmitTest = {props.handleOnSubmitTest}
                    isFetchingQuestions = {props.isFetchingQuestions}
                />
            </Col>
        </Row>
    )
}

export default CustomizedTestBody