import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ConfirmModal = ({
    title,
    message,
    onConfirm,
    onClose,
    isOpen
}) => {


    return (
        <Modal
            isOpen={isOpen}
            className='modal-dialog-centered'
            modalClassName="modal-primary"
        >
            <ModalHeader>
                {title ?? 'Confirm'}
            </ModalHeader>
            <ModalBody>
                {message}
            </ModalBody>
            <ModalFooter>
                {onClose &&
                    <Button color={"secondary"} onClick={onClose}>Cancel</Button>
                }
                {onConfirm &&
                    <Button color={"primary"} onClick={onConfirm}>Ok</Button>
                }
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmModal