import {Badge, Card, CardBody, CardHeader, CardTitle, Label} from "reactstrap";
import {Check} from "react-feather";

const CustomizedTestQuestionFilters = ({questionStates, filterQuestionByStates = [], manageQuestionStateWiseFilter}) => {
    const toggleStateWiseFilter = (evt) => {
        let value = "";
        if (evt.target.classList.contains('label-filter-pill')) {
            value = evt.target.getAttribute('data-value')
        } else {
            value = evt.target.closest('.label-filter-pill').getAttribute('data-value')
        }
        if (value) {
            if (filterQuestionByStates.includes(value)) {
                manageQuestionStateWiseFilter(value, -1)
            } else {
                manageQuestionStateWiseFilter(value, 1)
            }
        }
    }

    const filters = questionStates.map((state, index) => {
        return (<Label key={index} onClick={toggleStateWiseFilter} data-value={state.value}
                       className={"label-filter-pill position-relative"}>
            {filterQuestionByStates && filterQuestionByStates.includes(state.value.toString()) &&
                <Badge pill color='primary' className='badge-up'>
                    <Check></Check>
                </Badge>
            }
            <Badge className={`filter-pill switcher-${state.class}`} pill>
                {state.text}
            </Badge>
        </Label>)

    })
    return (
        <Card>
            <CardTitle className={"px-2 my-1 mb-1"} tag="h4">Filters</CardTitle>
            <CardBody>
                {filters}
            </CardBody>
        </Card>
    )
}

export default  CustomizedTestQuestionFilters