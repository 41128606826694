import {
    User
} from "react-feather"
// ** Reactstrap Imports
import {
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledButtonDropdown
} from "reactstrap"

// ** Default Avatar Image
import {deleteCookie} from "@src/app/common/helpers/CookieHelper"
import {useNavigate} from "react-router-dom"

const UserDropdown = (props) => {
    const userName = props.userName
    const navigate = useNavigate();

    const handleLogout = () => {
        deleteCookie('token');
        navigate('/login');
    }

    return (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item nav-item me-1">
            <div className="user-nav">
                <UncontrolledButtonDropdown>
                    <DropdownToggle outline color='secondary' caret>
                        <span className="user-name">
							<span className="fw-bold d-none d-md-inline">
								<User size={18}></User>{userName}
							</span>
							<span className="d-md-none d-inline"><User size={18}></User>
						</span>
						</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem href='/profile' tag='a'>Profile</DropdownItem>
                        <DropdownItem onClick={handleLogout}>
                            Logout
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </div>
        </UncontrolledDropdown>
    )
}

export default UserDropdown
